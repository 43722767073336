import React, { useEffect } from 'react';
import scriptLoader from 'react-async-script-loader';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { toastr } from '../_helpers_';
import { request } from 'src/_services_';

class PaypalButton extends React.Component {
  
  constructor(props) {
    super(props);
    window.React = React;
    window.ReactDOM = ReactDOM;
    this.state = {
      showButton: false,
      env: 'sandbox',
      client: {
        sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
        production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION
      },
      commit: true,
      amount: this.props.amount
    };
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.amount != nextProps.amount) {
      
    }
  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    if (!this.state.show) {
      if (isScriptLoaded && !this.props.isScriptLoaded) {
        if (isScriptLoadSucceed) {
          this.setState({ showButton: true });
        } else {
          console.log('Cannot load Paypal script!');
          this.props.onError();
        }
      }
    }
  }

  render() {
    const payment = () => {
      return new Promise((resolve) => {
        request.get('/api/v1/all_configs').then(res => {
          if (res.data.success) {
            const paypal_fee = res.data.data['paypal_fee_topup'] ? Number.parseFloat(res.data.data['paypal_fee_topup']) : 0
            const fee_per_transaction = res.data.data['paypal_fee_per_transaction'] ? Number.parseFloat(res.data.data['paypal_fee_per_transaction']) : 0
            const amount = this.props.amount + (this.props.amount * paypal_fee) / 100 + fee_per_transaction
            if (this.props.onDataProcess) {
              this.props.onDataProcess(amount)
            }
            resolve(paypal.rest.payment.create(this.state.env, this.state.client, {
              transactions: [{ 
                amount: { total: amount, currency: this.props.currency } 
              }],
            }))
          }
        })
      }) 
    }
 
    const onAuthorize = (data, actions) => actions.payment.execute().then(() => {
      const payment = Object.assign({}, this.props.payment);
      payment.paid = true;
      payment.cancelled = false;
      payment.payerID = data.payerID;
      payment.paymentID = data.paymentID;
      payment.paymentToken = data.paymentToken;
      payment.returnUrl = data.returnUrl;
      // window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/authorize_topup?paymentId=${data.paymentID}&paymentToken=${data.paymentToken}`
      this.props.onSuccess(payment);
    });
 
    let ppbtn = '';
    if (this.state.showButton) {
      ppbtn = (<paypal.Button.react
        env={this.state.env}
        client={this.state.client}
        payment={payment}
        fundingSource="paylater"
        commit
        style= {{
          shape: 'rect',
          maxbuttons: 1,
          tagline: false,
          size: 'responsive',
          color: 'gold',
          height: this.props.height || 40
        }}
        onAuthorize={onAuthorize}
        onCancel={this.props.onCancel}
      />);
    }
    return <div>{ppbtn}</div>;
  }

}


PaypalButton.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  // client: PropTypes.object.isRequired,
};
 
PaypalButton.defaultProps = {
  env: 'production',
  onSuccess: (payment) => {
    console.log('The payment was succeeded!', payment);
  },
  onCancel: (data) => {
    console.log('The payment was cancelled!', data);
  },
  onError: (err) => {
    console.log('Error loading Paypal script!', err);
  },
};
 
export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);
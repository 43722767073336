import React from 'react';
import { Box, TextField, Button, FormControlLabel, Radio, RadioGroup, Checkbox } from '@mui/material';
import { IconTrash } from '@tabler/icons';
import { CustomNumberFormat } from '../../../components';
import { withStyles } from '@mui/styles';
import AsyncSelect from 'react-select/async'
import { handleInputChange, loadOptions, disabledStyles, customStyles, makeId } from '../../../utils';

const useStyles = theme => ({
  input: {
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  },
})

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000000",
      backgroundColor: "#ECECEC"
    },
    "& .MuiInputBase-inputMultiline.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  }
})(TextField);

const disableButton = (field, values) => {
  const keys = field.keys.map(e => e.key)
  var sum_errors = 0
  keys.map(key => {
    if (!values[values.length - 1][key]) {
      sum_errors += 1
    }
  })
  if (sum_errors == 0) {
    return false
  }
  return true
}

const checkVisible = (field, values) => {
  if (field.has_condition == 1) {
    const conditions = field.condition
    const key = conditions[0]
    const value = conditions[1]
    const obj = values.find(e => e.key == key)
    if (obj.value == value) {
      return true
    }
    return false
  }
  return true
}

const ExtraFields = (props) => {
  const { handleChange, disabled, submitData, classes } = props;
  return (
    <div style={{ marginTop: 10 }}>
      <div className='mt-2'>
        {submitData.extra_fields?.map((field, index) => {
          if (field.type == 'text' || field.type == 'number' || field.type == 'textarea') {
            if (!checkVisible(field, submitData.extra_fields)) {
              return null
            }
            const inputProps = {
              classes: {
                notchedOutline: 'notchedOutline'
              }
            }
            if (disabled) {
              inputProps.classes['disabled'] = classes.input
            }
            if (field.type == 'number') {
              inputProps['inputComponent'] = CustomNumberFormat
            }
            return (
              <div className='mt-2' key={field.key}>
                <Box fontSize={15} fontWeight='bold'>
                  <span dangerouslySetInnerHTML={{ __html: field.label }} /> {!disabled && <span style={{ color: 'red' }}>*</span>}
                </Box>
                <CustomTextField
                  disabled={disabled}
                  fullWidth
                  margin='dense'
                  multiline={field.type == 'textarea' ? true : false}
                  maxRows={3}
                  name={field.key}
                  placeholder={field.placeholder}
                  onChange={(e) => {
                    const extra_field = Object.assign([], submitData.extra_field)
                    const obj = extra_field.find(e => e.key == field.key)
                    if (obj) {
                      obj['value'] = e.target.value
                    } else {
                      extra_field.push({...field, value: e.target.value})
                    }
                    const event = {
                      target: {
                        name: 'extra_field',
                        value: extra_field
                      }
                    }
                    handleChange(event)
                  }}
                  value={field.value}
                  variant="outlined"
                  InputProps={inputProps}
                />
              </div>
            )
          } else if (field.type == 'select') {
            if (!checkVisible(field, submitData.extra_fields)) {
              return null
            }
            return (
              <div className='mt-2' key={field.key}>
                <Box fontSize={15} fontWeight='bold'>
                  <span dangerouslySetInnerHTML={{ __html: field.label }} /> {!disabled && <span style={{ color: 'red' }}>*</span>}
                </Box>
                <AsyncSelect
                  isDisabled={disabled}
                  className="MuiFormControl-marginDense"
                  cacheOptions
                  isMulti={field.isMulti}
                  loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                    return new Promise(resolve => {
                      if (field.route) {
                        const query = field.query ? {...field.query, search: inputValue} : {search: inputValue}
                        request.get(field.route, query).then(res => {
                          if (res.data.success) {
                            resolve(res.data.data.items)
                          }
                        })
                      } else if (field.options) {
                        const result = field.options.filter(e => e.title.toLowerCase().includes(inputValue.toLowerCase()))
                        resolve(result)
                      } else {
                        resolve([])
                      }
                    })
                  })}
                  defaultOptions
                  onInputChange={handleInputChange}
                  isSearchable
                  name={field.key}
                  onChange={(value) => {
                    const extra_field = Object.assign([], submitData.extra_field)
                    const obj = extra_field.find(e => e.key == field.key)
                    if (obj) {
                      obj['value'] = value
                    } else {
                      extra_field.push({...field, value: value})
                    }
                    const event = {
                      target: {
                        name: 'extra_field',
                        value: extra_field
                      }
                    }
                    handleChange(event)
                  }}
                  menuPortalTarget={document.body}
                  getOptionLabel={({ title }) => title}
                  getOptionValue={({ id }) => id}
                  valueKey={"id"}
                  value={field.value || null}
                  styles={disabled ? disabledStyles : customStyles}
                />
              </div>
            )
          } else if (field.type == 'checkbox') {
            if (!checkVisible(field, submitData.extra_fields)) {
              return null
            }
            return (
              <div style={{ marginTop: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      disabled={disabled}
                      checked={field.value}
                      onChange={e => {
                        const extra_field = Object.assign([], submitData.extra_field)
                        const obj = extra_field.find(e => e.key == field.key)
                        if (obj) {
                          obj['value'] = e.target.checked
                        } else {
                          extra_field.push({...field, value: e.target.checked})
                        }
                        const event = {
                          target: {
                            name: 'extra_field',
                            value: extra_field
                          }
                        }
                        handleChange(event) 
                      }}
                    />
                  }
                  label={field.label}
                />
              </div>
            )
          } else if (field.type == 'radio') {
            if (!checkVisible(field, submitData.extra_fields)) {
              return null
            }
            return (
              <div className='mt-2'>
                <Box fontSize={15} fontWeight="bold">
                  <span dangerouslySetInnerHTML={{ __html: field.label }} /> {!disabled && <span style={{ color: 'red' }}>*</span>}
                </Box>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name={field.key}
                  value={field.value}
                  onChange={(e) => {
                    const extra_field = Object.assign([], submitData.extra_field)
                    const obj = extra_field.find(e => e.key == field.key)
                    if (obj) {
                      obj['value'] = e.target.value
                    } else {
                      extra_field.push({...field, value: e.target.value})
                    }
                    const event = {
                      target: {
                        name: 'extra_field',
                        value: extra_field
                      }
                    }
                    handleChange(event)
                  }}
                  row
                >
                  {field.options.map(option => (
                    <FormControlLabel disabled={disabled} value={option.key} key={option.key} control={<Radio />} label={option.label} />
                  ))}
                </RadioGroup>
              </div>
            )
          } else if (field.type == 'array') {
            if (!checkVisible(field, submitData.extra_fields)) {
              return null
            }
            return (
              <div className='mt-2'>
                <Box fontSize={15} fontWeight="bold">
                  <span dangerouslySetInnerHTML={{ __html: field.label }} /> {!disabled && <span style={{ color: 'red' }}>*</span>}
                </Box>
                {field.value?.map(item => (
                  <div className="d-flex" key={item.id}>
                    <div className="d-flex align-items-center" style={{ gap: 5, width: disabled ? '100%' : '90%' }}>
                      {field.keys.map((f, index) => {
                        if (f.type == 'text' || f.type == 'number' || f.type == 'textarea') {
                          const inputProps = {
                            classes: {
                              notchedOutline: 'notchedOutline'
                            }
                          }
                          if (disabled) {
                            inputProps.classes['disabled'] = classes.input
                          }
                          if (f.type == 'number') {
                            inputProps['inputComponent'] = CustomNumberFormat
                          }
                          return (
                            <div style={f.width ? { width: f.width } : {}} className={f.width ? '' : 'flex-1'} key={f.key}>
                              <CustomTextField
                                disabled={disabled}
                                fullWidth
                                margin='dense'
                                multiline={f.type == 'textarea' ? true : false}
                                maxRows={3}
                                name={f.key}
                                placeholder={f.label}
                                onChange={(e) => {
                                  const values = Object.assign([], submitData[field.key])
                                  const f_value = values.find(e => e.id == item.id)
                                  f_value[f.key] = e.target.value
                                  const event = {
                                    target: {
                                      name: field.key,
                                      value: values
                                    }
                                  }
                                  handleChange(event)
                                  const extra_field = Object.assign([], submitData.extra_field)
                                  const obj = extra_field.find(e => e.key == field.key)
                                  if (obj) {
                                    obj['value'] = values
                                  } else {
                                    extra_field.push({...field, value: values})
                                  }
                                  const event2 = {
                                    target: {
                                      name: 'extra_field',
                                      value: extra_field
                                    }
                                  }
                                  handleChange(event2)
                                }}
                                value={item[f.key] || ""}
                                variant="outlined"
                                InputProps={inputProps}
                              />
                            </div>
                          )
                        } else if (f.type == 'select') {
                          return (
                            <div style={f.width ? { width: f.width } : {}} className={f.width ? '' : 'flex-1'} key={f.key}>
                              <AsyncSelect
                                isDisabled={disabled}
                                className="MuiFormControl-marginDense"
                                cacheOptions
                                placeholder={f.label}
                                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                                  return new Promise(resolve => {
                                    if (f.route) {
                                      const query = f.query ? {...f.query, search: inputValue} : {search: inputValue}
                                      request.get(f.route, query).then(res => {
                                        if (res.data.success) {
                                          resolve(res.data.data.items)
                                        }
                                      })
                                    } else if (f.options) {
                                      const result = f.options.filter(e => e.title.toLowerCase().includes(inputValue.toLowerCase()))
                                      resolve(result)
                                    } else {
                                      resolve([])
                                    }
                                  })
                                })}
                                defaultOptions
                                onInputChange={handleInputChange}
                                isSearchable
                                name={f.key}
                                onChange={(value) => {
                                  const values = Object.assign([], submitData[field.key])
                                  const f_value = values.find(e => e.id == item.id)
                                  f_value[f.key] = value
                                  const event = {
                                    target: {
                                      name: field.key,
                                      value: values
                                    }
                                  }
                                  handleChange(event)
                                  const extra_field = Object.assign([], submitData.extra_field)
                                  const obj = extra_field.find(e => e.key == field.key)
                                  if (obj) {
                                    obj['value'] = values
                                  } else {
                                    extra_field.push({...field, value: values})
                                  }
                                  const event2 = {
                                    target: {
                                      name: 'extra_field',
                                      value: extra_field
                                    }
                                  }
                                  handleChange(event2)
                                }}
                                menuPortalTarget={document.body}
                                getOptionLabel={({ title }) => title}
                                getOptionValue={({ id }) => id}
                                valueKey={"id"}
                                value={item[f.key] || null}
                                styles={disabled ? disabledStyles : customStyles}
                              />
                            </div>
                          )
                        }
                      })}
                    </div>
                    {!disabled && 
                      <div className="d-flex align-items-center justify-content-center" style={{ width: '10%' }}>
                        <div 
                          className="d-flex align-items-center justify-content-center cursor-pointer" 
                          style={{ padding: 6, border: '1px solid red', borderRadius: 6 }}
                          onClick={() => {
                            const values = Object.assign([], submitData[field.key])
                            const data_values = values.filter(e => e.id !== item.id)
                            if (data_values.length == 0) {
                              data_values.push({id: makeId(12)})
                            }
                            const event = {
                              target: {
                                name: field.key,
                                value: data_values
                              }
                            }
                            handleChange(event)
                          }}
                        >
                          <IconTrash color="red" width={20} height={20} />
                        </div>
                      </div>
                    }
                  </div>
                ))}
                {!disabled && 
                  <Button 
                    onClick={() => {
                      const values = Object.assign([], submitData[field.key])
                      values.push({id: makeId(12)})
                      handleChange({ target: { name: field.key, value: values } })
                    }}
                    variant="outlined"
                    disabled={ 
                      submitData[field.key] && submitData[field.key].length > 0 && disableButton(field, submitData[field.key])
                    }
                  >
                    Add
                  </Button>
                }
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default withStyles(useStyles)(ExtraFields)
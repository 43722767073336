import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, IconButton } from '@mui/material';
import { ModalForm } from '../../../components';
import { toastr } from '../../../_helpers_';
import { withStyles } from '@mui/styles';
import AsyncSelect from 'react-select/async'
import { handleInputChange, loadOptions, disabledStyles, customStyles, errorStyles } from '../../../utils';
import { request } from 'src/_services_';
import DeleteIcon from '@mui/icons-material/Delete';
import validate from 'validate.js';

const useStyles = theme => ({
  input: {
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  },
})

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000000",
      backgroundColor: "#ECECEC"
    },
  }
})(TextField);

const TeamUser = (props) => {
  const [dataUser, setDataUser] = useState()
  const [groups, setGroups] = useState()
  const { classes, user } = props;
  useEffect(() => {
    fetchTeamUser()
    fetchGroup()
  },[])


  const fetchTeamUser = () => {
    request.get('/api/v1/team_users').then(res => {
      if (res.data.success) {
        setDataUser(res.data.data.items.map(e => ({email: e.email, role: e.groups[0].code, isValid: true})))
      } else {
      }
    }, err => {
    })
  }

  const fetchGroup = () => {
    request.get('/api/v1/groups', {}).then(res => {
      if (res.data.success) {
        setGroups(res.data.data.items.map(e => ({ value: e.code, label: e.name })))
      }
    })
  }

  const handleAddUser = () => {
    ModalForm.instance.current.openForm({
      title: "New User",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Email
            </Box>
            <CustomTextField
              fullWidth
              margin='dense'
              name="email"
              onChange={handleChange}
              value={submitData.values.email}
              variant="outlined"
              placeholder='Email'
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline',
                  disabled: classes.input
                }
              }}
              error={submitData.errors?.email}
              helperText={submitData.errors?.email ? submitData.errors.email[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Role
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  resolve(groups)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="role"
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'role',
                    value: value.value
                  }
                }
                handleChange(event)
              }}
              placeholder={"User role"}
              menuPortalTarget={document.body}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
              valueKey={"value"}
              value={groups?.find(e => e.value == submitData.values.role)}
              styles={submitData.errors?.role ? errorStyles : customStyles}
            />
            {submitData.errors?.role && <span style={{ color: 'red', fontSize: 11, marginLeft:10 }}>{submitData.errors.role[0]}</span>}
          </div>
        </div>
      ),
      action: {
        titleAction: 'active',
        schema: {
          email: {
            presence: { allowEmpty: false, message: '^Required' },
            email: {
              message: "^Invalid email"
            }
          },
          role: {
            presence: { allowEmpty: false, message: '^Required' }
          },
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            request.post(`/api/v1/handle_team_user`, submitData.values).then(res => {
              if (res.data.success) {
                toastr.success(`Create user successull. We sent an confirmation email to ${submitData.values.email}`)
                resolve(true)
                const users = Object.assign([], dataUser)
                users.push(submitData.values)
                setDataUser(users)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  const handleDeleteUser = (user) => {
    ModalForm.instance.current.openForm({
      title: "Delete User",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          Are you sure to delete this user?
        </div>
      ),
      action: {
        titleAction: 'active',
        onAction: () => {
          return new Promise((resolve) => {
            request.put(`/api/v1/handle_team_user`, user).then(res => {
              if (res.data.success) {
                toastr.success(`User deleted`)
                resolve(true)
                const users = dataUser.filter(e => e.email != user.email)
                setDataUser(users)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  return (
    <div className='p-2'>
      <div className='mt-2'>
      <div className='d-flex gap-1 align-items-center'>
        <div className='flex-2'>
          <Box fontSize={15} fontWeight='bold'>
            Email
          </Box>
        </div>
        <div className='flex-1'>
          <Box fontSize={15} fontWeight='bold'>
            Role
          </Box>
        </div>
      </div>
      {dataUser && dataUser?.length > 0 && dataUser.map((item, index) => (
        <div className='d-flex gap-1 align-items-center'>
          <div className='flex-2'>
            <CustomTextField
              disabled
              fullWidth
              margin='dense'
              name="email"
              onChange={(e) => {
                let users = Object.assign([], dataUser)
                users[index].email = e.target.value
                const errors = validate(users[index], usersSchema);
                item.isValid = errors ? false : true;
                setDataUser(users)
              }}
              value={item?.email}
              variant="outlined"
              placeholder='Email'
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline',
                  disabled: classes.input
                }
              }}
              error={!item.isValid}
            />
          </div>
          <div className='flex-1'>
            <AsyncSelect
              isDisabled
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  resolve(groups)
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="role"
              onChange={(value) => {
                let users = Object.assign([], dataUser)
                users[index].role = value.value
                setDataUser(users)
              }}
              placeholder={"User role"}
              menuPortalTarget={document.body}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
              valueKey={"value"}
              value={groups?.find(e => e.value == item.role)}
              styles={disabledStyles}
            />
          </div>
          {(user?.is_owner || user?.groups?.map(e => e.code).includes('teamlead')) &&
            <IconButton 
              color="error" 
              component="label" 
              onClick={() => handleDeleteUser(item)}
            >
              <DeleteIcon />
            </IconButton>
          }
        </div>
      ))}
      {(user?.is_owner || user?.groups?.map(e => e.code).includes('teamlead')) && 
        <Button 
          onClick={handleAddUser}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      }
      </div>
    </div>
  )
}

export default withStyles(useStyles)(TeamUser)
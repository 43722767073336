import React from 'react'
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Menu, MenuItem } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
// components
import { MHidden } from '../../components/@material-extend';
import Searchbar from './Searchbar';
import { userConstants } from '../../_constants_';
import { toastr } from '../../_helpers_';
import AccountPopover from './AccountPopover';
import { IconWallet, IconBrandMessenger } from '@tabler/icons';
import { request } from '../../_services_';
import { connect } from 'react-redux';
import NotificationsPopover from './NotificationsPopover';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    // minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  },
  borderBottom: '1px solid #E5E8EB'
}));

const DashboardNavbar = ({ onOpenSidebar, ...props }) => {
  const {user, dispatch, listTeam} = props
  const [anchorElList, setAnchorElList] = React.useState({anchorElApp: null, anchorElTeam: null});
  const handleClick = (event, key) => {
    const newList = {...anchorElList}
    newList[key] = event.currentTarget
    setAnchorElList(newList);
  };
  const handleClose = () => {
    setAnchorElList({anchorElApp: null, anchorElTeam: null});
  };

  const handleUpdateTeam = (id) => {
    setAnchorElList({anchorElApp: null, anchorElTeam: null});
    const data = {team_id: id}
    request.put(`/api/v1/user/${user.public_id}`, data).then(res => {
      if (res.data.success) {
        const team_id = res.data.data.team_id
        const user_drebest = Object.assign({}, JSON.parse(localStorage.getItem('user_drebest') || "{}"))
        user_drebest.team_id = team_id
        localStorage.setItem('user_drebest', JSON.stringify(user_drebest))
        dispatch({ type: userConstants.LOGIN_SUCCESS, user_drebest })
        window.location.reload()
      } else {
        toastr.error(res.data.msg)
      }
    })
  }

  React.useEffect(() => {
    if (user) {
      fetchData({company_id: user?.company_id})
    }
  },[user])

  const fetchData = (queryObject) => {
    request.get('/api/v1/teams', queryObject).then(res => {
      if (res.data.success) {
        if (res.data.success) {
          const dataTeam = res.data.data.items
          dispatch({ type: 'LOAD_LIST_TEAM', dataTeam })
        }
      }
    })
  }
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Box>
          <div style={{display:'flex', flexFlow: 'row'}}>
            <Button
              disabled={!user?.is_owner}
              id="button-team-id"
              aria-controls={Boolean(anchorElList.anchorElTeam) ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElList.anchorElTeam) ? 'true' : undefined}
              onClick={(event) => handleClick(event, 'anchorElTeam')}
              style={{ fontSize: 16, color: '#00AB55', padding: '6px 0px 6px 8px' }}
              startIcon={<AppsIcon />}
            >
              {`Team ${user?.team_id?.team_id || ''}`} {user?.is_owner && <ArrowDropDownIcon/>}
            </Button>
            {user?.team_id?.mess_group_link && 
              <a onClick={(e) => e.stopPropagation()}
                className='d-flex align-items-center'
                href={user?.team_id?.mess_group_link}
                target="_blank"
                rel="noreferrer"
              >
                <IconBrandMessenger size={20} stroke={2} />
              </a>
            } 
          </div>
          <Menu
            id="menu-team-id"
            anchorEl={anchorElList.anchorElTeam}
            open={Boolean(anchorElList.anchorElTeam)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'button-team-id',
              className: 'select-team'
            }}

          >
            {listTeam?.map((team,index) => (
              <MenuItem key={index} onClick={() => user?.is_owner && handleUpdateTeam(team.team_id)}>
                <div className='d-flex align-items-center justify-content-between w-100'>
                  <span>{`Team ${team.team_id}`}</span>
                </div>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          <IconButton href='http://spendads.com/wiki' target="_blank">
            <HelpOutlineIcon style={{color: '#637381'}} />
          </IconButton>
          <NotificationsPopover />
          {/* {window.location.pathname != '/my_wallet' &&
            <Button
              variant="outlined"
              startIcon={<IconWallet size={24} stroke={2} />}
              onClick={() => props.navigate('/my_wallet')}
            >
              Fund
            </Button>
          } */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

function mapStateToProps(state) {
  const { authentication, fetchTeam } = state;
  const { user } = authentication;
  const { listTeam } = fetchTeam;
  return {
      user,
      listTeam
  };
}

export default connect(mapStateToProps)(DashboardNavbar)
import NumberFormat from "react-number-format";

const color = (item) => {
  let color = 'unset'
  if (item.active) {
    color = 'blue'
  } else {
    color = 'black'
  }
  return color
}

export const fields = [
  {
    name: 'team_id',
    label: 'Team ID',
    // renderValue: (item) => item.user_id?.team_id? `Team_${item.user_id?.team_id?.team_id}`:'',
    renderValue: (item) => item.team_id ? `Team_${item.team_id}` : '' ,
    sort:true,
    width: '6rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'acc_code',
    label: 'Acc Code',
    renderValue: (item) => item?.acc_code,
    sort:true,
    width: '6rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'name',
    label: 'Acc Name',
    renderValue: (item) => item.acc_pool_id?.acc_name,
    sort:true,
    width: '18rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'acc_id',
    label: 'Acc ID',
    renderValue: (item) => item.acc_pool_id?.acc_id,
    sort:true,
    width: '10rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'using',
    label: 'Using',
    renderValue: (item) => item?.using,
    sort:true,
    width: '5rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'status',
    label: 'Status',
    renderValue: (item) => item?.status,
    sort:true,
    width: '6rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'delta_spending_limit',
    label: 'Spending Limit',
    renderValue: (item) => item.delta_spending_limit?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    sort:true,
    width: '9rem',
    renderColor: (item) => color(item),
    align: 'right',
  },
  {
    name: 'delta_spent',
    label: 'Spent',
    renderValue: (item) => item.delta_spent?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    sort:true,
    width: '8rem',
    align: 'right',
    renderColor: (item) => color(item)
  },
  {
    name: 'delta_remaining',
    label: 'Acc Remaining',
    renderValue: (item) => item.delta_remaining?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    sort:true,
    width: '8rem',
    align: 'right',
    renderColor: (item) => color(item)
  },
  {
    name: 'keep_deposit',
    label: 'Keep Deposit',
    renderValue: (item) => item.keep_deposit?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    sort:true,
    width: '8rem',
    align: 'right',
    renderColor: (item) => color(item)
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'team_seller_usename',
    label: 'Team_Seller Username',
    disabled: true
  },
  {
    type: 'text',
    name: 'acc_code',
    label: 'Acc Code',
    disabled: true
  },
  {
    type: 'text',
    name: 'acc_name',
    label: 'Acc Name',
    renderValue: (item) => item.acc_pool_id?.acc_name,
    disabled: true
  },
  {
    type: 'text',
    name: 'acc_id',
    label: 'ACC ID',
    renderValue: (item) => item.acc_pool_id?.acc_id,
    disabled: true
  },
  {
    type: 'number',
    name: 'delta_spending_limit',
    label: 'Spending Limit',
    renderValue: (item) => item.delta_spending_limit,
    disabled: true
  },
]
import React, { Component } from "react";
import { connect } from "react-redux";
import { 
  Button, Divider, CircularProgress,
  List, ListItem, ListItemText
} from "@mui/material";
import { withStyles } from "@mui/styles"
import { makeId } from '../utils/makeId'
import { authHeaders } from '../_helpers_'

import {
  IconArrowUp, IconTrash, IconCheck, IconX
} from '@tabler/icons'
import axios from 'axios'
import Dropzone from "react-dropzone";

const useStyles = (theme) => ({
  card: {
    borderRadius: 6,
    marginTop: 5,
    border: '1px solid #D8D8D8',
    backgroundColor: '#fff',
    // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
  },
});

class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.wrapperRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.dropzoneRef = React.createRef();
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleClick = (event) => {
    const { target } = event;
    if (!this.wrapperRef.current.contains(target)) {
    }
  };

  onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      return
    }
    var files = Object.assign([], this.props.submitData.values.files)
    const upload_files = accepted.map(e => ({id: makeId(20), filename: e.name, file: e, status: 'uploading'}))
    
    if (files) {
      upload_files.forEach(file => {
        files.push(file)
      })
    } else {
      files = upload_files
    }
    const event = {
      target: {
        name: 'files',
        value: files
      }
    }
    this.props.handleChange(event)
    if (this.props.uploadOnPicked) {
      upload_files.map(file => {
        var formData = new FormData();
        formData.append('file', file.file);
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/v1/upload_image`,
          headers: authHeaders(),
          data: formData
        }).then(res => {
          if (res.data.success) {
            const submitData = Object.assign({}, this.props.submitData)
            const files = submitData.values.files.map(e => e.id === file.id ? {...res.data.data, id: e.id, status: 'uploaded'} : e)
            const event = {
              target: {
                name: 'files',
                value: files
              }
            }
            this.props.handleChange(event)
            if (this.props.onUploadSuccess) this.props.onUploadSuccess(files)
          } else {
            const submitData = Object.assign({}, this.props.submitData)
            const files = submitData.values.files.map(e => e.id === file.id ? {...e, status: 'failed'} : e)
            const event = {
              target: {
                name: 'files',
                value: files
              }
            }
            this.props.handleChange(event)
          }
        }, err => {
          const submitData = Object.assign({}, this.props.submitData)
          const files = submitData.values.files.map(e => e.id === file.id ? {...e, status: 'failed'} : e)
          const event = {
            target: {
              name: 'files',
              value: files
            }
          }
          this.props.handleChange(event)
        })
      })
    }
  }

  render() {
    const { submitData } = this.props;
    return (
      <div className="mt-2">
        <Dropzone
          multiple
          onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
        >
          {({getRootProps, getInputProps, isDragAccept, isDragReject, acceptedFiles, rejectedFiles}) => (
            <section>
              <div 
                className={`p-3 d-flex align-items-center justify-content-center ${this.props.className || ''}`}
                style={{ border: '2px dashed #ccc', borderRadius: 6 }}
                {...getRootProps()} 
              >
                <input {...getInputProps()}  />
                {isDragReject && <div style={{ color: 'red' }}>Please upload a valid type</div>}
                {acceptedFiles.length === 0 && !isDragReject &&
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <div style={{ width: 40, height: 40, backgroundColor: '#000', borderRadius: '50%' }} className="d-flex align-items-center justify-content-center">
                      <IconArrowUp size={30} stroke={2} color="#fff" />
                    </div>
                    <Button className="br-6 mt-2 font-weight-bold" style={{ backgroundColor: '#fff', border: '1px solid #ccc' }}>
                      Add file
                    </Button>
                    <span className="mt-2">or drop files to upload</span>
                  </div>
                }
                {((acceptedFiles && acceptedFiles.length > 0) || this.props.showFiles) && 
                  <List style={{ minWidth: '60%' }}>
                    {submitData.values.files?.map(file => (
                      <>
                      <ListItem key={file.id}>
                        <ListItemText secondary={
                          <div className="d-flex align-items-center justify-content-between">
                            <a
                              target='_blank'
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation()
                                window.open(`${process.env.REACT_APP_API_URL}/${file.url}`, '_blank');
                              }}
                            >{file.filename}</a>
                            <div className="d-flex align-items-center justify-content-end">
                              {file.status === 'uploading' && <CircularProgress style={{ width: 10, height: 10 }} />}
                              {file.status === 'uploaded' && <IconCheck size={16} stroke={2} color="green" />}
                              {file.status === 'failed' && <IconX size={16} stroke={2} color="red" />}
                              <IconTrash 
                                className="ml-2" 
                                size={16} stroke={2} 
                                cursor={"pointer"} 
                                onClick={(e) => {
                                  e.stopPropagation()
                                  const submitData = Object.assign({}, this.props.submitData)
                                  const files = submitData.values.files.filter(e => e.id !== file.id)
                                  const event = {
                                    target: {
                                      name: 'files',
                                      value: files
                                    }
                                  }
                                  this.props.handleChange(event)
                                }}
                              />
                            </div>
                          </div>
                        } />
                      </ListItem>
                      <Divider />
                      </>
                    ))}
                  </List>
                }
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user: user,
  };
}
const connectedApp = connect(mapStateToProps)(withStyles(useStyles)(Uploader))
export { connectedApp as Uploader };

import React, { Component } from 'react';
import { withRouter } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import moment from 'moment';
import { Box, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ExtraField from '../RequestSupport/ExtraField';

const fields = [
  {
    type: 'text',
    name: 'create_date',
    label: 'Timestamp',
    disabled: true,
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD/MM/YYYY HH:mm:ss') : ''
  },
  {
    type: 'text',
    name: 'name',
    label: 'Service ID',
    disabled: true,
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => item.team_id?.team_search
  },
  {
    type: 'text',
    name: 'product_id',
    label: 'Service Name',
    disabled: true,
    renderValue: (item) => item.product_id?.title
  },
  {
    type: 'text',
    name: 'fee',
    label: 'Fee',
    disabled: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 })
  },
  {
    type: 'custom',
    name: 'option',
    label: 'Option',
    disabled: true,
    visible: (item) => item.product_id?.code == 'B' ? true : false,
    renderValue: (item) => 
      <div style={{ marginTop: 10 }}>
        <Box fontSize={15} fontWeight="bold">Option</Box>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="option"
          value={item.option}
          row
        >
          <FormControlLabel disabled value="upgrade_fud" control={<Radio />} label="Upgrade FUD" />
          <FormControlLabel disabled value="new_fud" control={<Radio />} label="New FUD" />
        </RadioGroup>
      </div>
  },
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    disabled: true,
    visible: (item) => item.product_id?.code == 'A' || item.product_id?.code == 'B' && item.option == 'upgrade_fud' ? true : false,
    renderValue: (item) => `FUD ${item.fud_id?.fud_id}`
  },
  {
    type: 'text',
    name: 'level',
    label: 'Level',
    disabled: true,
    visible: (item) => item.product_id?.code == 'B' ? true : false,
    renderValue: (item) => item.package_id?.name
  },
  {
    type: 'number',
    name: 'num_account',
    label: 'Number of accounts',
    disabled: true,
    visible: (item) => ['C', 'D'].includes(item.product_id?.code) ? true : false,
  },
  {
    type: 'custom',
    name: 'extra_fields',
    visible: (item) => ['A', 'A1'].includes(item.product_id?.code) ? true : false,
    // label: 'Extra fields',
    renderValue: (item, ctx) => <ExtraField submitData={item} handleChange={ctx.handleChange} disabled={ctx.props.mode == 'view'} justView={true} />
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note',
    disabled: true,
  },
]

class ServiceForm extends Component {

  render () {
    return (
      <ResourceForm 
        mode="view"
        fields={fields}
        route="service"
        onLoadData={(id) => {
          return new Promise((resolve) => {
            request.get(`/api/v1/service/${id}`).then(res => {
              if (res.data.success) {
                resolve(res.data.data)
              } else {
                resolve(false)
              }
            }, err => {
              resolve(false)
            })
          });
        }}
        title="Service Detail"
        actionBack={() => this.props.navigate('/services')}
        {...this.props}
      />
    )
  }
}

export default withRouter(ServiceForm);
import { useEffect } from 'react'
import { request } from '../../../_services_'

const ShortLink = (props) => {
  const { navigate } = props;
  useEffect(() => {
    const pathname = window.location.pathname
    request.get('/api/v1/shortlink', {pathname}).then(res => {
      if (res.data.success) {
        const data = res.data.data.result
        if (data.source_link) {
          const link = data.source_link.replace(process.env.REACT_APP_URL, '')
          navigate(link)
        } else {
          navigate('/404')
        }
      } else {
        navigate('/404')
      }
    }, err => {
      navigate('/404')
    })
  }, [])

  return null
  
}

export default ShortLink

export const timezones = [
    { id: 'GMT -11', name: 'GMT -11' },
    { id: 'GMT -10', name: 'GMT -10' },
    { id: 'GMT -9', name: 'GMT -9' },
    { id: 'GMT -8', name: 'GMT -8' },
    { id: 'GMT -7', name: 'GMT -7' },
    { id: 'GMT -6', name: 'GMT -6' },
    { id: 'GMT -5', name: 'GMT -5' },
    { id: 'GMT -4', name: 'GMT -4' },
    { id: 'GMT -3', name: 'GMT -3' },
    { id: 'GMT -2', name: 'GMT -2' },
    { id: 'GMT -1', name: 'GMT -1' },
    { id: 'UTC', name: 'UTC' },
    { id: 'GMT +1', name: 'GMT +1' },
    { id: 'GMT +2', name: 'GMT +2' },
    { id: 'GMT +3', name: 'GMT +3' },
    { id: 'GMT +4', name: 'GMT +4' },
    { id: 'GMT +5', name: 'GMT +5' },
    { id: 'GMT +6', name: 'GMT +6' },
    { id: 'GMT +7', name: 'GMT +7' },
    { id: 'GMT +8', name: 'GMT +8' },
    { id: 'GMT +9', name: 'GMT +9' },
    { id: 'GMT +10', name: 'GMT +10' },
    { id: 'GMT +11', name: 'GMT +11' },
    { id: 'GMT +12', name: 'GMT +12' },
]
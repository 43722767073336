import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import {
  TextField, Box, FormControlLabel, Checkbox,
  RadioGroup, Radio
} from '@mui/material'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'
import { fields, formFields } from './fields'
import { toastr } from '../../../_helpers_'
import { ModalForm, Uploader, ResourceList, DrawerForm } from '../../../components';
import { request } from '../../../_services_';
import moment from 'moment';


class RequestSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: null,
      open: false,
      mode: 'view',
      objectId: null,
    }
  }
  newRequest = () => {
    ModalForm.instance.current.openForm({
      title: "New Request",
      data: {
        create_date: moment().format('DD/MM/YYYY, HH:mm:ss')
      },
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Timestamp <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              disabled
              fullWidth
              margin='dense'
              name="create_date"
              value={submitData.values.create_date}
              variant="outlined"
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Request Type <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/request_types', { search: inputValue }).then(res => {
                    if (res.data.success) {
                      var request_types = Object.assign([], res.data.data.items)
                      resolve(request_types)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="request_type_id"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "request_type_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Request Type"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => `${name}`}
              getOptionValue={({ request_type_id }) => request_type_id}
              valueKey={"request_type_id"}
              value={submitData?.values.request_type_id || null}
              styles={submitData.errors?.request_type_id ? errorStyles : customStyles}
            />
            {submitData.errors?.request_type_id && <span style={{ color: 'red' }}>{submitData.errors?.request_type_id[0]}</span>}
          </div>
          {submitData.values?.account_id && <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Team_Seller Username <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              disabled
              fullWidth
              margin='dense'
              name="create_date"
              value={submitData.values?.account_id ? `Team_${submitData.values?.account_id?.team_id?.team_id} ${submitData.values?.account_id?.team_id?.owner_id?.shortname}` : ''}
              variant="outlined"
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>}
          {submitData.values.request_type_id && submitData.values.request_type_id.need_account_code &&
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Acc Code
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/accounts', { search: inputValue, status: 'Active' }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="team_id"
              onChange={(value) => {
                if (!submitData?.values?.account_id) {
                  ctx.setState({ reload: true }, () => {
                    ctx.setState({ reload: false })
                  })
                }
                const event = {
                  target: {
                    name: 'account_id',
                    value
                  }
                }
                handleChange(event)

              }}
              placeholder={"Acc Code"}
              menuPortalTarget={document.body}
              getOptionLabel={({ code }) => `${code}`}
              getOptionValue={({ account_id }) => account_id}
              valueKey={"account_id"}
              value={submitData.values.account_id}
              styles={submitData.errors?.account_id ? errorStyles : customStyles}
            />
            {submitData.errors?.account_id && <span style={{ color: 'red' }}>{submitData.errors.account_id[0]}</span>}
          </div>
          }
          {submitData.values?.account_id && <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Acc Name
            </Box>
            <TextField
              disabled
              fullWidth
              margin='dense'
              name="create_date"
              value={submitData.values?.account_id ? `${submitData.values?.account_id?.code} Team_${submitData.values?.account_id?.team_id?.team_id}` : ''}
              variant="outlined"
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>}
          {submitData?.values?.request_type_id?.code == 'C1' && <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Team content link Google Sheet <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="team_content_link"
              value={submitData.values.team_content_link || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}

            />
            {submitData.errors?.team_content_link && <span style={{ color: 'red' }}>{submitData.errors?.team_content_link[0]}</span>}
          </div>}
          {submitData?.values?.request_type_id?.code == 'R1' && <>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight="bold">
                {'Delete all campaigns? (Đã xoá toàn bộ campaign)'} <span style={{ color: 'red' }}>*</span>
              </Box>
              <RadioGroup
                onChange={handleChange}
                color="primary"
                name={'delete_all_campaigns'}
                value={submitData.values.delete_all_campaigns}
                row
              >
                <FormControlLabel value={"1"} control={<Radio />} label="Yes" />
                <FormControlLabel value={"0"} control={<Radio />} label="No" />
              </RadioGroup>
              {/* <FormControlLabel
                label={""}
                control={
                  <Checkbox
                    checked={submitData.values.delete_all_campaigns}
                    onChange={handleChange}
                    color="primary"
                    name={'delete_all_campaigns'}
                  />
                }
              /> */}
              {submitData.errors?.delete_all_campaigns && <span style={{ color: 'red' }}>{submitData.errors?.delete_all_campaigns[0]}</span>}

            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight="bold">
                {'Có tài khoản bị khoá và có Ad Post chạy dở?'} <span style={{ color: 'red' }}>*</span>
              </Box>
              <RadioGroup
                onChange={handleChange}
                color="primary"
                name={'have_account_block_or_ads_active'}
                value={submitData.values.have_account_block_or_ads_active}
                row
              >
                <FormControlLabel value={"1"} control={<Radio />} label="Yes" />
                <FormControlLabel value={"0"} control={<Radio />} label="No" />
              </RadioGroup>
              {/* <FormControlLabel
                label={""}
                control={
                  <Checkbox
                    checked={submitData.values.have_account_block_or_ads_active}
                    onChange={handleChange}
                    color="primary"
                    name={'have_account_block_or_ads_active'}
                  />
                }
              /> */}
              {submitData.errors?.have_account_block_or_ads_active && <span style={{ color: 'red' }}>{submitData.errors?.have_account_block_or_ads_active[0]}</span>}

            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Phương án với Ad Post chạy dở trên acc bị khoá <span style={{ color: 'red' }}>*</span>
              </Box>
              <Select
                className="MuiFormControl-marginDense"
                options={[{
                  value: 'Sẽ xóa Ad Post trên Fanpage/ Will delete Ad Post on Fanpage'
                },
                {
                  value: 'Sẽ giữ Ad Post trên Fanpage/ Will keep Ad Post on Fanpage'
                }
                ]}
                name="plan_ads_active"
                onChange={(value) => {
                  var e = {
                    target: {
                      name: "plan_ads_active",
                      value: value.value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"Payment method..."}
                menuPortalTarget={document.body}
                getOptionLabel={({ value }) => value}
                getOptionValue={({ value }) => value}
                valueKey={"value"}
                value={submitData?.values?.plan_ads_active ? { value: submitData?.values.plan_ads_active } : null}
                styles={submitData.errors?.plan_ads_active ? errorStyles : customStyles}
              />
              {submitData.errors?.plan_ads_active && <span style={{ color: 'red' }}>{submitData.errors?.plan_ads_active[0]}</span>}
            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Paypal Acc
              </Box>
              <TextField
                fullWidth
                margin='dense'
                type="text"
                name="paypal_acc"
                value={submitData.values.paypal_acc || ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                onChange={handleChange}
                error={submitData.errors?.paypal_acc}
                helperText={submitData.errors?.paypal_acc ? submitData.errors.paypal_acc[0] : ''}
              />
            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Payoneer Acc
              </Box>
              <TextField
                fullWidth
                margin='dense'
                type="text"
                name="payoneer_acc"
                value={submitData.values.payoneer_acc || ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                onChange={handleChange}
                error={submitData.errors?.payoneer_acc}
                helperText={submitData.errors?.payoneer_acc ? submitData.errors.payoneer_acc[0] : ''}
              />
            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Bank Vietnam
              </Box>
              <AsyncCreatableSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/banks', { search: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name="bank_id"
                onChange={(value) => {
                  var e = {
                    target: {
                      name: "bank_id",
                      value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"Bank"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ bank_id }) => bank_id}
                valueKey={"bank_id"}
                value={submitData?.values?.bank_id || null}
                styles={submitData?.errors?.bank_id ? errorStyles : customStyles}
                getNewOptionData={(inputValue, optionLabel) => ({
                  id: inputValue,
                  name: 'Create \"' + inputValue + '\"...',
                  __isNew__: true
                })}
                onCreateOption={(inputValue) => {
                  request.post('/api/v1/banks', { name: inputValue }).then(res => {
                    if (res.data.success) {
                      var e = {
                        target: {
                          name: "bank_id",
                          value: res.data.data
                        },
                      };
                      handleChange(e)
                    } else {
                      toastr.error(res.data.msg)
                    }
                  }, err => toastr.error(err))
                }}
              />
            </div>
            {submitData.values.bank_id &&
              <>
                <div className='mt-2'>
                  <Box fontSize={15} fontWeight='bold'>
                    Account Number
                  </Box>
                  <TextField
                    fullWidth
                    margin='dense'
                    type="text"
                    name="bank_account_number"
                    value={submitData.values.bank_account_number || ''}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        notchedOutline: 'notchedOutline'
                      }
                    }}
                    onChange={handleChange}
                    error={submitData.errors.bank_account_number ? true : false}
                    helperText={submitData.errors.bank_account_number ? submitData.errors.bank_account_number[0] : ""}
                  />
                </div>
                <div className='mt-2'>
                  <Box fontSize={15} fontWeight='bold'>
                    Account Owner
                  </Box>
                  <TextField
                    fullWidth
                    margin='dense'
                    type="text"
                    name="bank_account_owner"
                    value={submitData.values.bank_account_owner || ''}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        notchedOutline: 'notchedOutline'
                      }
                    }}
                    onChange={handleChange}
                    error={submitData.errors.bank_account_owner ? true : false}
                    helperText={submitData.errors.bank_account_owner ? submitData.errors.bank_account_owner[0] : ""}
                  />
                </div>
              </>
            }
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Nguyên nhân dừng hợp tác
              </Box>
              <TextField
                fullWidth
                margin='dense'
                type="text"
                name="reason_stop"
                value={submitData.values.reason_stop || ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                onChange={handleChange}

              />
            </div>
          </>}
          {submitData?.values?.request_type_id?.code == 'A1' &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Money Topup to Acc <span style={{ color: 'red' }}>*</span>
              </Box>
              <TextField
                fullWidth
                margin='dense'
                type="number"
                name="money_topup_to_acc"
                value={submitData.values.money_topup_to_acc || ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                onChange={handleChange}

              />
              {submitData.errors?.money_topup_to_acc && <span style={{ color: 'red' }}>{submitData.errors?.money_topup_to_acc[0]}</span>}

            </div>}
          {submitData?.values?.request_type_id?.code == 'B5' &&
            <>
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  BM ID <span style={{ color: 'red' }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  type="text"
                  name="bm_id"
                  value={submitData.values.bm_id || ''}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  onChange={handleChange}

                />
                {submitData.errors?.bm_id && <span style={{ color: 'red' }}>{submitData.errors?.bm_id[0]}</span>}
              </div>
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  BM Name <span style={{ color: 'red' }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  type="text"
                  name="bm_name"
                  value={submitData.values.bm_name || ''}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  onChange={handleChange}

                />
                {submitData.errors?.bm_name && <span style={{ color: 'red' }}>{submitData.errors?.bm_name[0]}</span>}

              </div>
            </>}
          {submitData?.values?.request_type_id?.code == 'P2' &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Domain Link <span style={{ color: 'red' }}>*</span>
              </Box>
              <TextField
                fullWidth
                margin='dense'
                type="text"
                name="domain_link"
                value={submitData.values.domain_link || ''}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
                onChange={handleChange}

              />
              {submitData.errors?.domain_link && <span style={{ color: 'red' }}>{submitData.errors?.domain_link[0]}</span>}
            </div>}
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Other note (Ghi chú khác)
            </Box>
            <TextField
              fullWidth
              margin='dense'
              type="text"
              name="other_note"
              value={submitData.values.other_note || ''}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              onChange={handleChange}

            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: (submitData) => {
          var schema = {
            request_type_id: { presence: { message: '^Required' } },
          }
          if (submitData?.values?.request_type_id?.code == 'P2') {
            schema['domain_link'] = { presence: { message: '^Required' } }
          } else if (submitData?.values?.request_type_id?.code == 'B5') {
            schema['bm_id'] = { presence: { message: '^Required' } }
            schema['bm_name'] = { presence: { message: '^Required' } }
          } else if (submitData?.values?.request_type_id?.code == 'A1') {
            schema['money_topup_to_acc'] = { presence: { message: '^Required' } }
          } else if (submitData?.values?.request_type_id?.code == 'R1' && submitData?.values?.request_type_id?.have_account_block_or_ads_active) {
            schema['plan_ads_active'] = { presence: { message: '^Required' } }
          } else if (submitData?.values?.request_type_id?.code == 'C1') {
            schema['team_content_link'] = { presence: { message: '^Required' } }
          }
          if (submitData?.values?.request_type_id?.code == 'R1') {
            schema['have_account_block_or_ads_active'] = {
              presence: { allowEmpty: false, message: '^Required' },
            }
            schema['delete_all_campaigns'] = {
              presence: { allowEmpty: false, message: '^Required' },
            }
          }
          if (submitData.values.bank_id) {
            schema['bank_account_owner'] = { presence: { allowEmpty: false, message: '^Required' } }
            schema['bank_account_number'] = {
              presence: { allowEmpty: false, message: '^Required' },
              format: {
                pattern: /^\d+$/,
                message: '^Account number is invalid'
              }
            }
          }
          schema['paypal_acc'] = {
            email: {
              message: "^Paypal account must be an email"
            }
          }
          schema['payoneer_acc'] = {
            email: {
              message: "^Payoneer account must be an email"
            }
          }
          return schema
        },
        onAction: (submitData, ctx) => {
          return new Promise((resolve) => {
            if (submitData.values.request_type_id.code == 'R1') {
              if (!submitData.values.paypal_acc && !submitData.values.payoneer_acc && !submitData.values.bank_id) {
                toastr.error("Please fill at least one of these fields (Paypal acc, Payoneer Acc, Bank VN)")
                resolve(false)
                return
              }
            }
            var keys = ['request_type_id', 'account_id', 'other_note']
            if (submitData?.values?.request_type_id?.code == 'P2') {
              keys.push('domain_link')
            } else if (submitData?.values?.request_type_id?.code == 'B5') {
              keys.push('bm_id')
              keys.push('bm_name')
            } else if (submitData?.values?.request_type_id?.code == 'A1') {
              keys.push('money_topup_to_acc')
            } else if (submitData?.values?.request_type_id?.code == 'R1') {
              keys = [...keys, ...['delete_all_campaigns', 'have_account_block_or_ads_active',
                'plan_ads_active', 'paypal_acc', 'payoneer_acc', 'bank_id', 'reason_stop', 'bank_account_number', 'bank_account_owner']]

            } else if (submitData?.values?.request_type_id?.code == 'C1') {
              keys.push('team_content_link')
            }
            var formSubmit = {}
            var valueData = submitData.values
            keys.map(key => {
              if (valueData[key] != null && valueData[key] != undefined) {
                if (valueData[key] instanceof Object || typeof valueData[key] == 'object') {
                  if (key == 'request_type_id') {
                    formSubmit[key] = submitData?.values?.request_type_id?.request_type_id
                  } else if (key == 'bank_id') {
                    formSubmit[key] = submitData?.values?.bank_id?.bank_id
                  } else if (key == 'account_id') {
                    formSubmit[key] = submitData?.values?.account_id?.account_id
                  } else {
                    formSubmit[key] = valueData[key]
                  }
                } else {
                  if (['have_account_block_or_ads_active', 'delete_all_campaigns'].includes(key)) {
                    formSubmit[key] = valueData[key] == "1" ? true : false
                  }
                  if (['money_topup_to_acc'].includes(key)) {
                    formSubmit[key] = parseInt(valueData[key] || 0)
                  } else {
                    formSubmit[key] = valueData[key]
                  }
                }
              }
            })
            request.post('/api/v1/requests', formSubmit).then(res => {
              if (res.data.success) {
                const items = Object.assign([], ctx.state.items)
                items.unshift(res.data.data)
                ctx.setState({ items, totals: ctx.state.totals + 1 })
                toastr.success("Add Request support successful")
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleCancelRequest = (ctx, formState) => {
    const requestId = formState.values.request_id
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Confirm cancel request',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Are you sure to cancel this request?
            </Box>
          </div>
        </div>
      ),
      action: {
        schema: {
        },
        titleAction: 'Confirm',
        onAction: (submitData) => {
          const query = {status: 'cancel', active: false}
          return new Promise((resolve) => {
            request.put(`/api/v1/request/${requestId}`, query).then(res => {
              if (res.data.success) {
                toastr.success("Cancel request successful")
                const form = Object.assign({}, formState)
                form.values['status'] = 'cancel'
                form.values['active'] = false
                ctx.setState({ formState: form })
                this.setState({ reload: true })
                resolve(true)
              } else {
                toastr.error(res.data.msg)
              }
            }, err => {
              toastr.error(err);
            })
          })
        }
      }
    })
  }

  render() {
    const { open, mode, objectId } = this.state;
    const { navigate } = this.props;
    return (
      <>
        <ResourceList
          rowActive={objectId}
          reload={this.state.reload}
          route="/api/v1/requests"
          titlePage="Drebest | Request Support"
          actions={[
            {
              text: 'New',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newRequest(ctx),
              visible: false
            }
          ]}
          title="Request Support"
          fields={fields}
          valueKey="request_id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.request_id, reload: false })}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/request/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Request Detail"
          actions={[
            {
              text: 'Cancel Request',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleCancelRequest(ctx, formState),
              visible: (item) => mode == 'view' && item.status == 'new',
            }
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(RequestSupport);
/* eslint-disable react/no-set-state */
/* eslint-disable no-redeclare */
import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { IconX, IconCalendarEvent } from '@tabler/icons';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeId } from '../utils';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const useStyles = (theme) => ({
  daterange_input: {
    width: 240,
    border: '1px solid #ccc',
    height: 44,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px',
    borderRadius: 8,
    cursor: 'pointer',
    justifyContent: 'space-between'
  },
});

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  componentDidMount() {
  }

  render() {
    const { classes, onChange } = this.props;
    const { value } = this.state;
    return (
      <DateRangePicker
        key={makeId(10)}
        initialSettings={{
          startDate: new Date(),
          minYear: 1901,
          autoApply: true,
          locale: {
            format: "DD/MM/YYYY"
          },
          ranges: {
            'Today': [new Date(), new Date()],
            'Yesterday': [new Date(moment().subtract(1, "days")), new Date(moment().subtract(1, "days"))],
            'Last 7 Days': [new Date(moment().subtract(6, "days")), new Date(moment())],
            'Last 30 Days': [new Date(moment().subtract(29, "days")), new Date(moment())],
            'This Month': [new Date(moment().startOf("month")), new Date(moment().endOf("month"))],
            'Last Month': [new Date(moment().subtract(1, "month").startOf("month")), new Date(moment().subtract(1, "month").endOf("month"))]
          },
          showCustomRangeLabel: true,
          alwaysShowCalendars: true,
          opens: 'left',
        }}
        locale={{ format: "DD/MM/YYYY" }}
        onApply={(event, picker) => {
          const startDate = new Date(picker.startDate)
          const endDate = new Date(picker.endDate)
          const date_search = [moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")]
          const value = [moment(startDate).format("DD/MM/YYYY"), moment(endDate).format("DD/MM/YYYY")]
          this.setState({ value })
          onChange(date_search)
        }}
      >
        <div className={classes.daterange_input}>
          <div className="d-flex" style={{ gap: 8 }}>
            <IconCalendarEvent size={20} color="#666666" />
            {value.length > 0 ? <span style={{ color: '#333333', fontSize: 14}}>{value[0]} ~ {value[1]}</span> : <span style={{ color: '#AAAAAA', fontSize: 14 }}>{this.props.placeholder}</span>}
          </div>
          {value.length > 0 && 
            <IconX 
              size={16} 
              color="#444444" 
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                this.setState({ value: [] })
                onChange([])
              }}
            />
          }
        </div>
      </DateRangePicker>
    );
  }
}


const connectedList = withStyles(useStyles)(DatePicker);
export { connectedList as DatePicker };
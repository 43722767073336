import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr, withRouter } from '../../../_helpers_';
import { Page, Scrollbar, CustomCircularProgress, PaypalButton, CustomNumberFormat, Label } from '../../../components';
import { IconArrowLeft } from '@tabler/icons';
import { request } from '../../../_services_';
import clsx from 'clsx';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { 
  Container, Stack, Typography, Box, Card, Grid, Button, Checkbox, TextField,
  Paper, TableContainer, Table, TableHead, TableCell, TableRow, TableBody,
  TablePagination, Dialog, DialogActions, DialogTitle, DialogContent, IconButton,
  Radio, RadioGroup, FormControlLabel, FormControl
} from '@mui/material';
import { styled, withStyles } from '@mui/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import validate from 'validate.js';
import { showLoading, hideLoading } from '../../../_actions_';
import { payoneer } from '../../../_constants_';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'
import card_USD from '../../../static/images/card_USD.png'
import card_GBP from '../../../static/images/card_GBP.png'
import card_EUR from '../../../static/images/card_EUR.png'
import mastercard from '../../../static/images/icon_mastercard.png';

const useStyles = (theme) => ({
  root: {
    width: '100%',
    borderRadius: 0,
    height: 'calc(100vh - 148px)'
  },
  title: {
    fontSize: 14,
    lineHeight: 1.5,
    marginTop: 10,
  },
  box: {
    border: '1px solid #ccc',
    borderRadius: 12,
    padding: "12px 16px"
  },
  card_balance: {
    borderRadius: 12,
    padding: 16,
    height: 120,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '&.EUR': {
      backgroundImage: `url(${card_EUR})`
    },
    '&.USD': {
      backgroundImage: `url(${card_USD})`
    },
    '&.GBP': {
      backgroundImage: `url(${card_GBP})`
    },
  }
})

const getEnv = (env) => {
  return payoneer[env]
}

class PayoneerIntegration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: localStorage.getItem('POACT') ? 'logged' : 'new',
      code: null,
      error: null,
      env: 'sandbox',
      data: null
    }
  }

  getData = () => {
    const { dispatch } = this.props;
    dispatch(showLoading())
    request.get('/api/v1/payoneer/account_balance', {}).then(res => {
      dispatch(hideLoading())
      if (res.data.success) {
        this.setState({ data: res.data.data })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      dispatch(hideLoading())
      console.log(err)
    })
  }

  componentDidMount() {
    const status = localStorage.getItem('POACT')
    if (status == 'success') {
      this.getData()
    }
  }

  createAccount = (e) => {
    e.preventDefault()
    const { dispatch } = this.props;
    dispatch(showLoading())
    request.post('/api/v1/payoneer/create', {}).then(res => {
      dispatch(hideLoading())
      if (res.data.success) {
        window.location.href = res.data.data
      }
    }, err => {
      toastr.error(err)
      dispatch(hideLoading())
    })
  }

  handleLogin = () => {
    if (!this.state.code) {
      this.setState({ error: 'Required' })
      return
    }
    const { dispatch } = this.props;
    dispatch(showLoading())
    request.post('/api/v1/payoneer/auth', { code: this.state.code }).then(res => {
      dispatch(hideLoading())
      if (res.data.success) {
        this.setState({ status: 'logged' })
        localStorage.setItem('POACT', res.data.data.status)
        toastr.success("Login successful")
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error(err)
      dispatch(hideLoading())
    })
  }

  formatId = (item) => {
    const res = `**** **** **** ${item.id.slice(-4)}`
    return res
  }

  disconnectPayoneer = () => {
    const { dispatch } = this.props;
    dispatch(showLoading())
    request.post('/api/v1/payoneer/disconnect', {}).then(res => {
      dispatch(hideLoading())
      if (res.data.success) {
        localStorage.removeItem('POACT')
        toastr.error("Disconnected")
        this.setState({ data: null, status: 'new'})
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      dispatch(hideLoading())
      toastr.error(err)
    })
  }

  render () {
    const { navigate, classes } = this.props;
    const { data } = this.state;

    return (
      <Page title="Payoneer Integration">
        <Container maxWidth="xl">
          <Grid container justifyContent="center">
            <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
              <Stack direction="row" alignItems="center" mt={4}>
                <Typography variant="h4">
                  Payoneer
                </Typography>
              </Stack>
              <Scrollbar>
                <Paper className={classes.root}>
                  <div className={classes.title}>
                    We provide a solution that connects directly to the Payoneer payment gateway to make it quick and convenient to top up your fund.
                  </div>
                  <div className='d-flex py-4' style={{ gap: 10 }}>
                    <div style={{ flex: 3 }}>
                      <Box fontSize={16} fontWeight="bold">
                        Connect Payoneer Account
                      </Box>
                      <Box fontSize={14} marginTop={1}>
                        Drebest will synchronize your payoneer account into the system. 
                      </Box>
                    </div>
                    {this.state.status == 'logged' ?
                    <div className={clsx(classes.box, 'd-flex align-items-center')} style={{ flex: 5 }}>
                      <div className='d-flex align-items-center justify-content-between w-100'>
                        <span><b>Account ID: </b> {data?.account_id}</span>
                        <Button color="error" variant="outlined" onClick={this.disconnectPayoneer}>Disconnect</Button>
                      </div>
                    </div> :
                    <div className={classes.box} style={{ flex: 5 }}>
                      <Box fontSize={14}>
                        Connect with your Payoneer Account to start the integration.
                      </Box>
                      <Box fontSize={14}>
                        Don't have Payoneer account? <a href="#" onClick={e => this.createAccount(e)}>Create one.</a>
                      </Box>
                      {this.state.status == 'new' &&
                      <Button 
                        color="primary" 
                        variant="contained"
                        className='mt-2'
                        onClick={() => {
                          this.setState({ status: 'get_code' })
                          const payoneer = getEnv(this.state.env)
                          const url = `${payoneer.AUTHORIZATION_URL}/api/v2/oauth2/authorize?client_id=${payoneer.CLIENT_ID}&redirect_uri=${payoneer.REDIRECT_URL}&scope=read write openid personal-details&response_type=code`
                          window.open(url, '_blank')
                        }}
                      >
                        Connect
                      </Button>
                      }
                      {this.state.status == 'get_code' &&
                        <div className='mt-2'>
                          <Box fontSize={15} fontWeight="bold">
                            Authorization Code
                          </Box>
                          <div className='d-flex align-items-center gap-1'>
                            <TextField 
                              variant='outlined'
                              fullWidth
                              margin="dense"
                              name="code"
                              value={this.state.code}
                              onChange={e => this.setState({ code: e.target.value })}
                              error={this.state.error ? true : false}
                              helperText={this.state.error || ''}
                            />
                            <Button 
                              style={{ height: 44 }}
                              color="primary" 
                              variant="contained"
                              onClick={this.handleLogin}
                            >
                              Continue
                            </Button>
                          </div>
                        </div>
                      }
                    </div>
                    }
                  </div>
                  {this.state.status == 'logged' && 
                    <div className='d-flex gap-2 mt-2'>
                      {data?.balances?.map(item => (
                        <div className={clsx(classes.card_balance, 'flex-1', item.currency)}>
                          <div className='d-flex flex-column h-100 gap-2'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <span className='text-white font-weight-bold' style={{ fontSize: 24 }}>{Number.parseFloat(item.available_balance).toLocaleString('en-US', { style: 'currency', currency: item.currency, maximumFractionDigits: 2 })}</span>
                              <img src={mastercard} />
                            </div>
                            <span className='text-white'>{this.formatId(item)}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </Paper>
              </Scrollbar>
            </Grid>
          </Grid>
        </Container>
      </Page>
    )
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(withStyles(useStyles)(withRouter(PayoneerIntegration)));
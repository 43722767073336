import React, { Component } from 'react';
import {
  TextField, Box, Grid, Button, Container, Paper, IconButton, Checkbox, FormControlLabel, Stack, Typography
} from '@mui/material';
import { IconChevronLeft, IconChevronRight, IconArrowLeft } from '@tabler/icons';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { customStyles, errorStyles, disabledStyles } from '../utils/selectStyle';
import { withStyles } from '@mui/styles';
import validate from 'validate.js';
import clsx from 'clsx';
import { toastr } from 'src/_helpers_';
import { Page, Scrollbar, CustomNumberFormat, CustomCircularProgress, ModalForm } from '../components';
import { loadOptions, handleInputChange } from '../utils/select';
import DatePicker from "react-datepicker";
import AsyncCreatableSelect from 'react-select/async-creatable';

const useStyles = theme => ({
  root: {
    width: '100%',
    borderRadius: 0,
    height: 'calc(100vh - 148px)'
  },
  main: {
    backgroundColor: '#FFF',
    height: 'calc(100vh - 148px)',
    padding: '0px 20px',
    overflow: 'auto',
  },
  input: {
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  }
})

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    }
  }
})(TextField);

class ResourceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: {
        values: {},
        changes: {},
        errors: {},
        isLoading: false,
        isValid: true
      },
      loadingData: false,
      nextId: null,
      prevId: null
    }
  }

  getData = (id, props) => {
    if (props.onLoadData) {
      this.setState({ loadingData: true }, () => {
        props.onLoadData(id).then(res => {
          const formState = Object.assign({}, this.state.formState)
          formState.values = res?.result || {}
          this.setState({ formState, loadingData: false, nextId: res.next_id, prevId: res.prev_id })
        }, err => {
          this.setState({ loadingData: false })
          toastr.error("There was an error loading your data")
        })
      })
    }
  }

  componentDidMount() {
    this.getData(this.props.match.params.id, this.props)
  }

  componentWillUpdate(nextProps) {
    if (this.props.match.params.id != nextProps.match.params.id) {
      this.getData(nextProps.match.params.id, nextProps)
    }
  }

  handleNext = () => {
    if (this.props.route && this.state.nextId) {
      this.props.navigate(`/${this.props.route}/${this.state.nextId}`)
    }
  }

  handlePrev = () => {
    if (this.props.route && this.state.prevId) {
      this.props.navigate(`/${this.props.route}/${this.state.prevId}`)
    }
  }

  handleChange = (event) => {
    const formState = Object.assign({}, this.state.formState)
    var value = event.target.value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'radio') {
      value = Number.isNaN(event.target.value) ? event.target.value : Number.parseInt(event.target.value)
    }
    formState.values[event.target.name] = value
    formState.changes[event.target.name] = value
    this.setState({ formState })
  }

  getFieldValue = (field) => {
    if (field.renderValue) {
      return field.renderValue(this.state.formState.values, this)
    }
    return this.state.formState.values[field.name]
  }

  handleCancel = () => {
    this.getData(this.props.match.params.id, this.props)
  }

  getVisibleAction = (actions) => {
    if (!actions) {
      return []
    }
    const visibleActions = []
    actions.map(action => {
      if (Object.keys(action).includes('visible')) {
        if (typeof action.visible === 'function') {
          if (action.visible(this.state.formState.values)) {
            visibleActions.push(action)
          }
        } else {
          if (action.visible) {
            visibleActions.push(action)
          }
        }
      } else {
        visibleActions.push(action)
      }
    })
    return visibleActions
  }

  getVisibleField = (fields) => {
    if (!fields) {
      return []
    }
    const visibleFields = []
    fields.map(field => {
      if (Object.keys(field).includes('visible')) {
        if (typeof field.visible === 'function') {
          if (field.visible(this.state.formState.values)) {
            visibleFields.push(field)
          }
        } else {
          if (field.visible) {
            visibleFields.push(field)
          }
        }
      } else {
        visibleFields.push(field)
      }
    })
    return visibleFields
  }

  render() {
    const { formState } = this.state;
    const { classes, mode } = this.props;
    return (
      <Page title={this.props.title || 'Form Detail'}>
        <ModalForm />
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <div className={classes.title}>
              {this.props.actionBack &&
                <IconArrowLeft cursor={"pointer"} size={24} stroke={2} onClick={() => this.props.actionBack()} />
              }
              <Typography variant="h4" marginLeft={5}>
                {this.props.title || 'Detail'}
              </Typography>
            </div>
            {this.props.actions &&
              <div className={classes.actions}>
                {this.getVisibleAction(this.props.actions).reverse().map((action, index) => (
                  <Button
                    className="ml-2"
                    key={index}
                    color={action.color || 'primary'}
                    size={action.size || 'small'}
                    variant={action.variant || 'contained'}
                    startIcon={action.startIcon}
                    endIcon={action.endIcon}
                    onClick={() => action.action(this, formState)}
                  >
                    {action.text}
                  </Button>
                ))}
              </div>
            }
          </Stack>
          <Scrollbar>
            {this.state.loadingData ?
              <div className={clsx(classes.root, 'd-flex align-items-center justify-content-center')}>
                <CustomCircularProgress />
              </div>
              :
              <Paper className={classes.root}>
                <div className='form-previous-btn'>
                  <IconButton disabled={!this.state.prevId} onClick={this.handlePrev}>
                    <IconChevronLeft size={30} stroke={1} />
                  </IconButton>
                </div>
                <div className='form-next-btn'>
                  <IconButton disabled={!this.state.nextId} onClick={this.handleNext}>
                    <IconChevronRight size={30} stroke={1} />
                  </IconButton>
                </div>
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={6} className={classes.main}>
                    {this.getVisibleField(this.props.fields).filter(e => (e.mode?.includes(mode) || !e.mode)).map(field => {
                      // if(mode=='view' && !this.getFieldValue(field)){
                      //   return null
                      // }
                      if (field.type === 'text') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <CustomTextField
                              disabled={mode == 'view' || field.disabled}
                              margin="dense"
                              InputProps={{
                                classes: {
                                  disabled: classes.input
                                },
                              }}
                              type={'text'}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={this.getFieldValue(field) || ''}
                              onChange={(e) => {
                                if (field.onChange) {
                                  field.onChange(e, this)
                                } else {
                                  this.handleChange(e)
                                }
                              }}
                              fullWidth
                              variant="outlined"
                              multiline={field.multiline}
                              rows={field.rows || 3}
                            />
                          </div>
                        )
                      } else if (field.type === 'number') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <CustomTextField
                              disabled={mode == 'view' || field.disabled}
                              margin="dense"
                              InputProps={{
                                classes: {
                                  disabled: classes.input
                                },
                                inputComponent: CustomNumberFormat
                              }}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={this.getFieldValue(field) || 0}
                              onChange={(e) => {
                                if (field.onChange) {
                                  field.onChange(e, this)
                                } else {
                                  this.handleChange(e)
                                }
                              }}
                              fullWidth
                              variant="outlined"
                            />
                          </div>
                        )
                      } else if (field.type === 'select') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <AsyncSelect
                              cacheOptions
                              isDisabled={mode == 'view' || field.disabled}
                              loadOptions={(inputValue) => loadOptions(inputValue, field.loadOptions, this.state.formState.values)}
                              defaultOptions
                              onInputChange={handleInputChange}
                              isSearchable
                              isClearable
                              name={field.name}
                              onChange={(value) => {
                                var e = {
                                  target: {
                                    name: field.name,
                                    value
                                  },
                                };
                                if (field.onChange) {
                                  field.onChange(e, this)
                                } else {
                                  this.handleChange(e)
                                }
                              }}
                              placeholder={field.placeholder || ''}
                              getOptionLabel={(item) => field.getOptionLabel ? field.getOptionLabel(item) : (field.labelKey ? item[field.labelKey] : item.name)}
                              getOptionValue={(item) => field.getOptionValue ? field.getOptionValue(item) : (field.valueKey ? item[field.valueKey] : item.id)}
                              valueKey={field.valueKey || "id"}
                              value={this.getFieldValue(field)}
                              styles={mode == 'view' || field.disabled ? disabledStyles : customStyles}
                            />
                          </div>
                        )
                      } else if (field.type === 'select_create') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <AsyncCreatableSelect
                              cacheOptions
                              isDisabled={mode == 'view' || field.disabled}
                              loadOptions={(inputValue) => loadOptions(inputValue, field.loadOptions, this.state.formState.values)}
                              defaultOptions
                              onInputChange={handleInputChange}
                              isSearchable
                              isClearable
                              name={field.name}
                              onChange={(value) => {
                                var e = {
                                  target: {
                                    name: field.name,
                                    value
                                  },
                                };
                                if (field.onChange) {
                                  field.onChange(e, this)
                                } else {
                                  this.handleChange(e)
                                }
                              }}
                              placeholder={field.placeholder || ''}
                              getOptionLabel={(item) => field.getOptionLabel ? field.getOptionLabel(item) : (field.labelKey ? item[field.labelKey] : item.name)}
                              getOptionValue={(item) => field.getOptionValue ? field.getOptionValue(item) : (field.valueKey ? item[field.valueKey] : item.id)}
                              valueKey={field.valueKey || "id"}
                              value={this.getFieldValue(field)}
                              styles={mode == 'view' || field.disabled ? disabledStyles : customStyles}
                              onCreateOption={(inputValue) => field.onCreateOption(inputValue, this)}
                              getNewOptionData={(inputValue, optionLabel) => ({
                                id: inputValue,
                                name: 'Create \"' + inputValue + '\"...',
                                __isNew__: true
                              })}
                              menuPortalTarget={document.body}

                            />
                          </div>
                        )
                      } else if (field.type === 'multiselect') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isDisabled={mode == 'view' || field.disabled}
                              loadOptions={(inputValue) => loadOptions(inputValue, field.loadOptions, this.state.formState.values)}
                              defaultOptions
                              onInputChange={handleInputChange}
                              isSearchable
                              isClearable
                              name={field.name}
                              onChange={(value) => {
                                var e = {
                                  target: {
                                    name: field.name,
                                    value
                                  },
                                };
                                if (field.onChange) {
                                  field.onChange(e, this)
                                } else {
                                  this.handleChange(e)
                                }
                              }}
                              placeholder={field.placeholder || ''}
                              getOptionLabel={(item) => field.getOptionLabel ? field.getOptionLabel(item) : (field.labelKey ? item[field.labelKey] : item.name)}
                              getOptionValue={(item) => field.getOptionValue ? field.getOptionValue(item) : (field.valueKey ? item[field.valueKey] : item.id)}
                              valueKey={field.valueKey || "id"}
                              value={this.getFieldValue(field) || []}
                              styles={mode == 'view' || field.disabled ? disabledStyles : customStyles}
                            />
                          </div>
                        )
                      } else if (field.type === 'datetime') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <DatePicker
                              className='mt-1 font-date'
                              disabled={mode == 'view' || field.disabled}
                              wrapperClassName='custom-picker'
                              popperClassName='custom-popper-picker'
                              selected={this.getFieldValue(field) || null}
                              onChange={(value) => {
                                const event = {
                                  target: {
                                    name: field.name,
                                    value
                                  }
                                }
                                if (field.onChange) {
                                  field.onChange(event, this)
                                } else {
                                  this.handleChange(event)
                                }
                              }}
                              timeInputLabel="Time:"
                              dateFormat="dd/MM/yyyy HH:mm:ss"
                              showTimeInput
                            />
                          </div>
                        )

                      } else if (field.type === 'date') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <DatePicker
                              className='mt-1 font-date'
                              disabled={mode == 'view' || field.disabled}
                              wrapperClassName='custom-picker'
                              popperClassName='custom-popper-picker'
                              selected={this.getFieldValue(field) || null}
                              onChange={(value) => {
                                const event = {
                                  target: {
                                    name: field.name,
                                    value
                                  }
                                }
                                if (field.onChange) {
                                  field.onChange(event, this)
                                } else {
                                  this.handleChange(event)
                                }
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        )

                      } else if (field.type === 'checkbox') {
                        return (
                          <div className='mt-2' key={field.name}>
                            <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box>
                            <FormControlLabel
                              label={""}
                              control={
                                <Checkbox
                                  checked={field.checked(formState.values)}
                                  onChange={event => {
                                    if (field.onChange) {
                                      field.onChange(event, this)
                                    } else {
                                      this.handleChange(event)
                                    }
                                  }}
                                  color="primary"
                                  disabled={mode == 'view' || field.disabled}
                                  name={field.name}
                                />
                              }
                            />
                          </div>
                        )
                      } else if (field.type === 'custom') {
                        return (
                          <div className='mt-2' key={field.name}>
                            {/* <Box fontSize={15} fontWeight="bold">
                              {field.label}
                            </Box> */}
                            {field.renderValue(formState.values, this)}
                          </div>
                        )
                      }
                    })}
                  </Grid>
                </Grid>
              </Paper>
            }
          </Scrollbar>
        </Container>
      </Page>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
  };
}

const connectedForm = connect(mapStateToProps)(withStyles(useStyles)(ResourceForm));
export { connectedForm as ResourceForm }
import { Link as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Box, TextField, Container, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Button, IconButton } from '@mui/material';
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import { request } from '../_services_';
import { toastr } from '../_helpers_';
// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}));

// ----------------------------------------------------------------------

const Login = (props) => {
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const [formPassword, setFormPassword] = useState({values: {}, errors: {}})
  const handleClose = () => {
    setOpenPasswordModal(false)
    setFormPassword({values: {}, errors: {}})
  }

  const handleChange = (event) => {
    const form = Object.assign({}, formPassword)
    form['values'][event.target.name] = event.target.value;
    setFormPassword(form)
  }

  const forgotPassword = () => {
    setOpenPasswordModal(true)
  }

  const handleReset = () => {
    const form = Object.assign({}, formPassword)
    if (!form['values']['email']) {
      form['errors']['email'] = "This field is required"
      setFormPassword(form)
      return
    }
    request.post('/auth/forgot', {email: form.values.email}).then(res => {
      if (res.data.success) {
        handleClose()
        toastr.success("We sent an email to recovery your password")
      } else {
        toastr.error(res.data.msg)
      }
    })
  }
  return (
    <RootStyle title="Drebest | Login">
      <AuthLayout>
        {/* Don't have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register_company">
          Get started
        </Link> */}
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to Drebest
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm {...props} forgotPassword={forgotPassword} />

          {/* <MHidden width="sm"> */}
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don't have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/register_company">
                Sign Up
              </Link>
            </Typography>
          {/* </MHidden> */}
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="modal-form-dialog"
            open={openPasswordModal}
            maxWidth={'sm'}
            fullWidth
          >
            <DialogTitle
              classes={{ root: 'dialog-title' }}
            >
              <span>Forgot Password</span>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className='p-2'>
                <TextField 
                  fullWidth
                  placeholder='Your email address'
                  margin='dense'
                  value={formPassword.values.email}
                  name="email"
                  onChange={handleChange}
                  variant="outlined"
                  inputProps={{
                    style: { textAlign: 'center' }
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                  }}
                  error={formPassword.errors.email ? true : false}
                  helperText={formPassword.errors.email || ''}
                />
              </div>
            </DialogContent>
            <DialogActions classes={{ root: 'dialog-actions' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleReset}
                style={{ borderRadius: 4, padding: '10px 30px' }}
              >
                Reset My Password
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
};

export default connect(null)(Login);
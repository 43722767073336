import React from 'react';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import validate from 'validate.js';
import axios from 'axios';
import { Page, CustomCircularProgress } from '../components';
import { toastr, withRouter } from '../_helpers_';
import { request } from '../_services_';


const useStyles = (theme) => ({
  container: {
    position: 'relative',
    zIndex: 1,
    height: '100vh',
    width: '100%',
    overflow: 'hidden'
  },
  section_login: {
    '& h1': {
      textTransform: 'uppercase'
    },
    padding: '7em 0px'
  },
  text_field: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      '& fieldset': {
        borderRadius: 8,
      },
    },
    background: 'transparent',
    border: '1px solid transparent'
  },
  loadingIndicator: {
    color: '#fff !important'
  }
})

class RecoveryPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePassword: false,
      visibleConfirmPassword: false,
      uid: null,
      loading: false,
      formState: {
        values: {
          password: "",
          confirm_password: ""
        },
        isSubmitting: false,
        errors: {},
        isValid: true
      }
    }
    this.schema = {
      password: {presence: { allowEmpty: false, message: '^Required'}},
      confirm_password: {
        presence: { allowEmpty: false, message: '^Required'},
        equality: {
          attribute: 'password',
          message: '^Password does not match'
        }
      },
    }
  }

  componentDidMount() {
    const token = this.props.match.params.token
    if (token) {
      this.getData(token)
    }
  }

  componentWillUpdate(nextProps) {
    if (this.props.match.params.token != nextProps.match.params.token) {
      this.getData(nextProps.match.params.token)
    }
  }

  getData = (token) => {
    this.setState({ loading: true }, () => {
      request.get('/auth/reset_password', {token}).then(res => {
        if (res.data.success) {
          this.setState({ uid: res.data.data.uid, loading: false })
        } else {
          this.setState({ loading: false })
          toastr.error(res.data.msg)
        }
      }, err => toastr.error(err))
    })
  }

  validateForm = () => {
    const formState = Object.assign({}, this.state.formState)
    const errors = validate(this.state.formState.values, this.schema)
    formState.errors = errors || {}
    formState.isValid = errors ? false : true
    return formState
  }

  handleChange = (e) => {
    const formState = Object.assign({}, this.state.formState);
    formState.values[e.target.name] = e.target.value
    this.setState({ formState })
  }

  handleSave = () => {
    const formState = this.validateForm()
    formState.isSubmitting = formState.isValid
    this.setState({ formState }, () => {
      if (formState.isValid) {
        const data = {
          uid: this.state.uid,
          password: formState.values.password,
          confirm_password: formState.values.confirm_password
        }
        request.post('/auth/reset_password', data).then(res => {
          if (res.data.success) {
            toastr.success("Reset password successfully")
            window.location.assign('/login')
          } else {
            toastr.error(res.data.msg)
          }
        })
      }
    })
    
  }

  render() {
    const { classes } = this.props;
    const { visiblePassword, visibleConfirmPassword, formState, loading, uid } = this.state;
    return (
      <Page title="Login">
        {loading && 
          <div className={clsx(classes.container, 'd-flex align-items-center justify-content-center')}>
            <CustomCircularProgress />
          </div>
        }
        {!uid && !loading && 
          <div className={clsx(classes.container, 'd-flex justify-content-center')}>
            <h1>User does not exists</h1>
          </div>
        }
        {uid && !loading &&
          <div className={clsx(classes.container, 'd-flex justify-content-center')}>
            <div className={clsx(classes.section_login, 'container')}>
              <div className="row justify-content-center">
                <div className={'col-md-6 col-lg-4 text-center'}>
                  <form autoComplete='off'>
                    <h2 className='mt-4'>Reset Account Password</h2>
                    <div className='mt-3' style={{ position: 'relative' }}>
                      <TextField 
                        className={classes.text_field}
                        autoComplete="none"
                        margin="dense"
                        variant="outlined"
                        placeholder='Password'
                        fullWidth
                        name="password"
                        value={formState.values.password || ""}
                        type={visiblePassword ? "text" : "password"}
                        onChange={this.handleChange}
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                          style: { textAlign: 'center' }
                        }}
                        error={formState.errors?.password}
                        helperText={formState.errors?.password ? formState.errors?.password[0] : ""}
                      />
                      <div style={{ position: 'absolute', top: 23, right: 10 }}>
                        <div 
                          style={{ cursor: 'pointer' }} 
                          onClick={() => this.setState({ visiblePassword: !this.state.visiblePassword })}
                        >
                          {visiblePassword ? <VisibilityOffIcon fontSize='small' color="disabled" /> : <VisibilityIcon fontSize='small' color="disabled" />}
                        </div>
                      </div>
                    </div>
                    <div className='mt-1' style={{ position: 'relative' }}>
                      <TextField 
                        className={classes.text_field}
                        margin="dense"
                        autoComplete='none'
                        variant="outlined"
                        placeholder='Confirm Password'
                        fullWidth
                        name="confirm_password"
                        onChange={this.handleChange}
                        value={formState.values.confirm_password || ""}
                        type={visibleConfirmPassword ? "text" : "password"}
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                          style: { textAlign: 'center' }
                        }}
                        error={formState.errors?.confirm_password}
                        helperText={formState.errors?.confirm_password ? formState.errors?.confirm_password[0] : ""}
                      />
                      <div style={{ position: 'absolute', top: 23, right: 10 }}>
                        <div 
                          style={{ cursor: 'pointer' }} 
                          onClick={() => this.setState({ visibleConfirmPassword: !this.state.visibleConfirmPassword })}
                        >
                          {visibleConfirmPassword ? <VisibilityOffIcon fontSize='small' color="disabled" /> : <VisibilityIcon fontSize='small' color="disabled" />}
                        </div>
                      </div>
                    </div>
                    
                    <LoadingButton 
                      loading={formState.isSubmitting}
                      color="primary"
                      className={clsx('mt-4 p-3', classes.button)}
                      variant="contained"
                      style={{ width: '100%', borderRadius: 8, color: '#fff', textTransform: 'uppercase' }}
                      onClick={this.handleSave}
                    >
                      Reset Password
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
      </Page>
    )
  }


}

export default withStyles(useStyles)(withRouter(RecoveryPassword));
import { userConstants } from '../_constants_'

const user = JSON.parse(localStorage.getItem('user_drebest'))
const initialState = user ? { isLogged: true, user } : {}

export function authentication (state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST: {
      return {
        isLogging: true,
        user: action.user
      }
    }
    case userConstants.LOGIN_SUCCESS:
      return {
        isLogged: true,
        user: action.user
      }
    case userConstants.LOGIN_FAILURE:
      return {}
    case userConstants.LOGOUT:
      return {}
    default:
      return state
  }
}

import React from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { IconArrowLeft } from '@tabler/icons';

const useStyles = theme => ({
  root: {
    borderTop: '1px solid #ebeff3',
    width: '100%',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const Pagination = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.row_per_page}>
        <select>
          <option value={15}>15 / page</option>
          <option value={25}>25 / page</option>
          <option value={50}>50 / page</option>
          <option value={100}>100 / page</option>
        </select>
      </div>
      <div className={classes.pages}>
        1
      </div>
      <div className={classes.go_to_page}>
        Go to <input />
      </div>
    </div>
  )
}

export default withStyles(useStyles)(Pagination)
import React from 'react'
import { Checkbox } from '@mui/material'
import NumberFormat from "react-number-format"
import moment from 'moment'

export const fudLevel = [
  {id: 1, name: 'Level 2K', value: 2000},
  {id: 2, name: 'Level 5K', value: 5000},
  {id: 3, name: 'Level 10K', value: 10000},
  {id: 4, name: 'Level 20K', value: 20000},
  {id: 5, name: 'Level 50K', value: 50000},
  {id: 6, name: 'Level 100K', value: 100000},
  {id: 7, name: 'Level 200K', value: 200000},
  {id: 8, name: 'Level 500K', value: 500000},
]

const color = (item) => {
  const countDown = item.count_down_day
  let color = 'unset'

  if (countDown > 7) {
    color = 'green'
  } else if (countDown > 0) {
    color = 'darkorange'
  } else {
    color = 'black'
  }

  // if (item.can_use == 1) {
  //   color = 'green'
  // } else {
  //   color = 'black'
  // }

  // if (countDown == 0 && item.remaining_topup <= 0) {
  //   color = 'black'
  // } else if (countDown == 0 && item.remaining_topup > 0) {
  //   color = 'red'
  // } else if ( countDown <= 7 ) {
  //   color = 'darkorange'
  // }

  // if (item.remaining_topup == 0) {
  //   color
  // }

  return color
}

export const fields = [
  {
    name: 'fud_id',
    label: 'FUD ID',
    renderValue: (item) => `FUD ${item.fud_id}`,
    sort: true,
    width: '5rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => `Team_${item.team_id}`,
    sort: true,
    width: '6rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'can_use',
    label: 'Can Use',
    renderValue: (item) => <Checkbox checked={item.can_use === 1} color="primary" disabled disableRipple />,
    sort: true,
    width: '5rem',
    align: 'center',
  },
  {
    name: 'package_money',
    label: 'Package Money',
    renderValue: (item) => <NumberFormat value={item.package_money} displayType="text" thousandSeparator={','} />,
    sort: true,
    width: '6rem',
    align: 'right',
    renderColor: (item) => color(item)
  },
  {
    name: 'fee_of_pack',
    label: 'B- Actual fee sent',
    renderValue: (item) => <NumberFormat value={item.fee_of_pack?.toFixed(2)} displayType="text" thousandSeparator={','} />,
    sort: true,
    width: '6rem',
    align: 'right',
    renderColor: (item) => color(item)
  },
  {
    name: 'total_topup_to_now',
    label: 'A - Total topup by FUD',
    renderValue: (item) => <NumberFormat value={item.total_topup_to_now?.toFixed(2)} displayType="text" thousandSeparator={','} />,
    sort: true,
    width: '7rem',
    align: 'right',
    renderColor: (item) => color(item)
  },
  {
    name: 'remaining_topup',
    label: 'Remaining Topup',
    renderValue: (item) => <NumberFormat value={item.remaining_topup?.toFixed(2)} displayType="text" thousandSeparator={','} />,
    sort: true,
    width: '6rem',
    align: 'right',
    renderColor: (item) => color(item)
  },
  {
    name: 'create_date',
    label: 'First Day of FUD',
    renderValue: (item) => moment(item.create_date).format('DD-MM-YYYY'),
    sort: true,
    width: '9rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'extend_special',
    label: 'Extend Day',
    renderValue: (item) => item.extend_special || 0,
    sort: true,
    width: '7rem',
    align: 'center',
    align: 'right',
    renderColor: (item) => color(item)
  },
  {
    name: 'cycle_end_date',
    label: 'Cycle end Day',
    renderValue: (item) => moment(item.cycle_end_date).format('DD-MM-YYYY'),
    sort: true,
    width: '8rem',
    renderColor: (item) => color(item)
  },
  {
    name: 'countdown_day',
    label: 'Countdown day',
    renderValue: (item) => item.count_down_day > 0 ? item.count_down_day : '',
    sort: true,
    width: '8rem',
    align: 'center',
    align: 'right',
    renderColor: (item) => color(item)
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    disabled: true,
    renderValue: (item) => `FUD ${item.fud_id}`
  },
  {
    type: 'text',
    name: 'team',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id}`
  },
  {
    type: 'text',
    name: 'team_name',
    label: 'Team ID & Name',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id} ${item.team?.name}`
  },
  {
    type: 'text',
    name: 'username',
    label: 'Team_Seller Username',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id} ${item.creator?.name}`
  },
  {
    type: 'text',
    name: 'level',
    label: 'Level',
    disabled: true
  },
  {
    type: 'text',
    name: 'package_id',
    label: 'Package',
    disabled: true,
    renderValue: (item) => item.package_id?.name
  },
  {
    type: 'number',
    name: 'lack_of_fee',
    label: 'Lack of fee',
    disabled: true,
  },
  {
    type: 'number',
    name: 'package_money',
    label: 'Package Money',
    disabled: true,
  },
  {
    type: 'number',
    name: 'fee_of_pack',
    label: 'B- Actual fee sent',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_topup_to_now',
    label: 'A - Total topup by FUD',
    disabled: true,
  },
  {
    type: 'number',
    name: 'remaining_topup',
    label: 'The remaining topup',
    disabled: true,
  },
  {
    type: 'checkbox',
    name: 'can_use',
    label: 'Can use',
    disabled: true,
    checked: (item) => item.can_use === 1 ? true : false
  },
  {
    type: 'number',
    name: 'percent_fee_actual',
    label: '% fee actual',
    disabled: true,
  },
  {
    type: 'number',
    name: 'percent_fee_by_package',
    label: '% fee by package',
    disabled: true,
  },
  {
    type: 'text',
    name: 'rate_type_code',
    label: 'Rate type code',
    disabled: true,
  },
  {
    type: 'text',
    name: 'create_date',
    label: 'First Day of FUD',
    renderValue: (item) => moment(item.create_date).format('DD-MM-YYYY'),
  },
  {
    type: 'number',
    name: 'extend_special',
    label: 'Extend Day',
    renderValue: (item) => item?.extend_special || 0,
    disabled: true,
  },
  {
    type: 'text',
    name: 'cycle_end_date',
    label: 'Cycle end Day',
    renderValue: (item) => moment(item.cycle_end_date).format('DD-MM-YYYY'),
    disabled: true,
  },
  {
    type: 'number',
    name: 'countdown_day',
    label: 'Countdown day',
    renderValue: (item) => item.count_down_day,
    disabled: true,
  },
] 
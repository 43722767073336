import NumberFormat from "react-number-format";
import moment from 'moment';
import { ImagePreview } from '../../../components';

export const fields = [
  {
    name: 'transaction_time',
    label: 'Transaction Time',
    renderValue: (item) => item.transaction_time ? moment(item.transaction_time).format('DD/MM/YYYY') : '',
    width: '10rem',
    sort:true
  },
  {
    name: 'topup_id',
    label: 'Topup ID',
    renderValue: (item) => `Topup ${item.topup_id}`,
    width: '6rem',
    sort:true
  },
  {
    name: 'money_to_wallet',
    label: 'A - Money to Wallet',
    renderValue: (item) => <NumberFormat value={item.money_to_wallet || 0} thousandSeparator="," displayType="text" />,
    width: '12rem',
    sort:true
  },
  {
    name: 'actual_fee',
    label: 'B - Actual fee',
    renderValue: (item) => <NumberFormat value={item.actual_fee || 0} thousandSeparator="," displayType="text" />,
    width: '10rem',
    sort:true
  },
  {
    name: 'ad_acc_per_issuance_fee',
    label: 'C - Ad Acc creation/insurance fee',
    renderValue: (item) => <NumberFormat value={item.ad_acc_per_issuance_fee || 0} thousandSeparator="," displayType="text" />,
    width: '16rem',
    sort:true
  },
  {
    name: 'acc_deposit',
    label: 'D - Acc Deposit',
    renderValue: (item) => <NumberFormat value={item.acc_deposit || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort:true
  },
  {
    name: 'total_money',
    label: 'Total Money',
    renderValue: (item) => <NumberFormat value={item.total_money || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort:true
  },
  {
    name: 'drebest_received',
    label: 'Drebest Received',
    renderValue: (item) => item.drebest_received,
    width: '10rem',
    sort:true
  },
  {
    name: 'fud_id',
    label: 'FUD ID',
    renderValue: (item) => item.fud_id ? `FUD ${item.fud_id.fud_id}` : '',
    width: '8rem',
    sort:true,
    sortName:"fud_wallet.fud_id"
  },
  {
    name: 'lack_of_fee_fud',
    label: 'Lack of fee FUD',
    renderValue: (item) => <NumberFormat value={item.lack_of_fee_fud || 0} thousandSeparator="," displayType="text" />,
    width: '12rem',
    sort:true,
  },
  {
    name: 'remaining_topup',
    label: 'Remaining Topup',
    renderValue: (item) => <NumberFormat value={item.remaining_topup || 0} thousandSeparator="," displayType="text" />,
    width: '12rem',
    sort:true,
  },
  {
    name: 'total_topup',
    label: 'A - Total Topup -> Now',
    renderValue: (item) => <NumberFormat value={item.total_topup || 0} thousandSeparator="," displayType="text" />,
    width: '16rem',
    sort:true,
  },
  {
    name: 'package_money',
    label: 'Package Money',
    renderValue: (item) => <NumberFormat value={item.package_money || 0} thousandSeparator="," displayType="text" />,
    width: '8rem',
    sort:true,
  },
  {
    name: 'payment_method_id',
    label: 'Payment Method',
    renderValue: (item) => item.payment_method_id?.name,
    width: '12rem',
    sort:true,
    sortName:'payment_methods.name'
  },
  {
    name: 'payoneer_transaction_id',
    label: 'Payoneer Transaction ID',
    renderValue: (item) => item.payoneer_transaction_id,
    width: '12rem',
    sort:true
  },
  {
    name: 'extra',
    label: 'Screen shoot',
    renderValue: (item) => item.screenshot_transaction_type == 'direct_link' ?
    <ImagePreview  url={item.screenshot_transaction_link} /> 
     : (item.extra && item.extra.length > 0 ?
      <ImagePreview  url={`${process.env.REACT_APP_API_URL}/${item.extra[0].url}`} /> : ''),
    width: '8rem',
    
  },
  {
    name: 'apply_rate',
    label: 'Apply Rate',
    renderValue: (item) => item.apply_rate,
    width: '8rem',
    sort:true
  },
  {
    name: 'total_money_actual',
    label: 'Total Money VND actual',
    renderValue: (item) => item.total_money_actual,
    width: '12rem',
    sort:true
  },
  {
    name: 'email_from',
    label: 'Send from email',
    renderValue: (item) => item.email_from,
    width: '12rem',
    sort:true
  },
  {
    name: 'tranfer_content',
    label: 'Transfer Contents',
    renderValue: (item) => item.tranfer_content,
    width: '12rem',
    sort:true
  },
  {
    name: 'note',
    label: 'Long Note',
    renderValue: (item) => item.note,
    width: '12rem',
    sort:true
  },
]
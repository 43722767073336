import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import React, { useState } from 'react';
// material
import { 
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { userActions } from '../_actions_';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useFormik, Form, FormikProvider } from 'formik';
import { request } from '../_services_';
import Logo from '../components/Logo';
import { withRouter, toastr } from '../_helpers_';
import { connect } from 'react-redux';


// ----------------------------------------------------------------------

const ComfirmPassWord = (props) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const token = props.match.params.token

  const ConfirmPWSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string().required('Confirm Password is required')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
      token: token
    },
    validationSchema: ConfirmPWSchema,
    onSubmit: () => {
      const { dispatch } = props;
      request.put('/auth/validate', formik.values).then(res => {
        if(res.data.success) {
          const query = {username: res.data.data.email, password: formik.values.password, remember: 1}
          dispatch(userActions.login(query, (success) => {
              if(success){
                navigate('/')
              }else{
                formik.setSubmitting(false)
              }
          }));
        }
      })
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ height: '30vh', textAlign: '-webkit-center ' }}>
        <Logo sx={{paddingTop: '2.5rem'}}/>
      </div>
      <div style={{ transform: 'translate(-50%, -50%)', position: 'fixed', top: '40%', left: '50%', width: '25vw', height: '50vh'}}>
        <div style={{padding: '10px'}}>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Confirm your Password
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Confirm Password"
                  {...getFieldProps('confirm_password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.confirm_password && errors.confirm_password)}
                  helperText={touched.confirm_password && errors.confirm_password}
                />
              </Stack>
              <div> 
                <div style={{marginTop: '1.8rem'}}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Confirm
                  </LoadingButton>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  )
};


export default connect(null)(withRouter(ComfirmPassWord));
import { useParams } from 'react-router-dom';

export const withRouter = WrappedComponent => props => {
  const match = {params: useParams()};

  return (
    <WrappedComponent
      {...props}
      match={match}
    />
  );
};
export function fetchTeam(state = {}, action = {}) {
  switch (action.type) {
    case 'LOAD_LIST_TEAM':
      return {
        listTeam: action.dataTeam
      }
    default:
      return state;
  }
}

// routes
import React from 'react';
import { connect } from 'react-redux';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { history } from './_helpers_';
import { alertActions } from './_actions_';
import Router from './routes';
import OneSignal from 'react-onesignal'
import { request } from './_services_';
import { userConstants } from './_constants_';

// ----------------------------------------------------------------------

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(alertActions.clear());
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user != this.props.user && this.props.user) {
      const { user } = this.props;
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        if (isSubscribed) {
          OneSignal.getUserId(function (userId) {
            if (user.public_id) {
              const query = {onesignal_id: userId}
              request.put(`/api/v1/user/${user.public_id}`, query)
            }
          });
        }
      });
    } 
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(alertActions.clear());
    if (user) {
      const newUser = Object.assign({}, user)
      request.get(`/api/v1/user/${newUser.public_id}`).then(res => {
        if (res.data.success) {
          newUser.team_id = res.data.data.result.team_id
          dispatch({ type: userConstants.LOGIN_SUCCESS, user: newUser })
        }
      })
      // OneSignal.on('subscriptionChange', function (isSubscribed) {
      //   if (isSubscribed) {
      //     console.log('11111')
      //     OneSignal.getUserId(function (userId) {
      //       console.log(userId, 'zzzz')
      //       if (user.public_id) {
      //         const query = {onesignal_id: userId}
      //         request.put(`/api/v1/user/${user.public_id}`, query)
      //       }
      //     });
      //   }
      // });
    }
  }

  render() {
    return (
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router user={this.props.user} />
      </ThemeConfig>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    alert: state.alert,
    lang: state.locale.lang,
    loadingBar: state.loadingBar,
    user
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };

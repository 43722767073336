import React, { Component } from 'react';
import validate from "validate.js";
import { connect } from "react-redux";
import {
  Button, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { request } from '../_services_';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const singleton = { current: null }

export default class ModalForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      submitData: {
        isValid: true,
        values: {},
        errors: {},
        isSubmiting: false,
      },
      options: {}
    }

    this.config = {}
  }

  componentDidMount() {
    singleton.current = this
  }

  handleChange = (e) => {
    const submitData = Object.assign({}, this.state.submitData);
    const { name, value, depends } = e.target;
    submitData.values[name] = e.target.type === 'checkbox' ? e.target.checked : value;
    if (this.config.multipleActions) {
      this.config.actions.map((action) => {
        if (action.schema) {
          var schema = null
          if (typeof action.schema == 'function') {
            schema = action.schema(this.state.submitData)
          } else {
            schema = action.schema
          }
          const errors = validate(submitData.values, schema);
          if (errors && errors[name]) {
            submitData.errors[name] = errors[name]
          } else {
            delete submitData.errors[name]
          }
        }
      })
    } else if (this.config.action.schema) {
      var schema = null
      if (typeof this.config.action.schema == 'function') {
        schema = this.config.action.schema(this.state.submitData)
      } else {
        schema = this.config.action.schema
      }
      const errors = validate(submitData.values, schema);
      if (errors && errors[name]) {
        submitData.errors[name] = errors[name]
      } else {
        delete submitData.errors[name]
      }
    }
    this.setState({ submitData })
    if (depends) {
      depends.forEach(dep => {
        submitData.values[dep.key] = null
        this.setState({ submitData })
        request.get(dep.route, dep.query || {}).then(res => {
          if (res.data.success) {
            const options = Object.assign({}, this.state.options)
            options[dep.key] = res.data.data.items
            this.setState({ options })
          }
        })
      })
    }
  }

  openForm = (config) => {
    this.config = config || {}
    const submitData = Object.assign({}, this.state.submitData)
    if (config.data) {
      submitData.values = config.data
      submitData.errors = {}
      submitData.isSubmiting = false
      submitData.isValid = true
    }
    this.setState({
      open: true,
      submitData
    })
  }

  onClose = () => {
    this.setState({
      open: false,
      submitData: {
        isValid: true,
        values: {},
        errors: {},
        isSubmiting: false,
      }
    })
    this.config?.actionAfterClose && this.config.actionAfterClose(this)
  }

  validateForm(schema) {
    const submitData = Object.assign({}, this.state.submitData);
    const errors = validate(submitData.values, schema);
    submitData.isValid = errors ? false : true;
    submitData.errors = errors || {};
    return submitData
  }

  getVisibleAction = (actions) => {
    if (!actions) {
      return []
    }
    const visibleActions = []
    actions.map(action => {
      if (Object.keys(action).includes('visible')) {
        if (typeof action.visible === 'function') {
          if (action.visible(this.state.submitData.values, this)) {
            visibleActions.push(action)
          }
        } else {
          if (action.visible) {
            visibleActions.push(action)
          }
        }
      } else {
        visibleActions.push(action)
      }
    })
    return visibleActions
  }

  // onDropFile = (accepted, rejected, namespace) => {
  //   if (Object.keys(rejected).length !== 0) {
      
  //   } else {
  //     var formData = new FormData();
  //     formData.append("file", accepted[0]);
  //     formData.append("namespace", namespace)
  //     const userObj = cookies.get('user_hebecore')
  //     axios({
  //       method: "POST",
  //       url: `${config.apiCoreUrl}/api/upload_file`,
  //       headers: {
  //         'x-access-token': userObj.token
  //       },
  //       data: formData
  //     }).then(res => {
  //       if (res.data.success) {
  //         const submitData = Object.assign({}, this.state.submitData)
  //         submitData.values['files']
  //       }
  //     })
  //   }
  // };

  render() {
    const { open } = this.state;
    return (
      <BootstrapDialog
        onClose={this.onClose}
        aria-labelledby="modal-form-dialog"
        open={open}
        maxWidth={this.config.size || 'sm'}
        fullWidth
      >
        <BootstrapDialogTitle id="modal-form-dialog" onClose={this.onClose}>
          {this.config.title || 'Modal'}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {this.config.customView ? this.config.customView(this.state.submitData, this.handleChange, this) : (this.config.content || "")}
        </DialogContent>
        <DialogActions>
          <Button
            // color="default"
            color="secondary"
            variant="outlined"
            disabled={this.state.submitData.isSubmiting}
            onClick={this.onClose}
          >
            {this.state.submitData.isSubmiting ? <CircularProgress style={{ width: 15, height: 15, color: '#fff' }} /> : 'Close'}
          </Button>
          {!this.config.multipleActions && this.config.action &&
            <Button
              color="primary"
              variant="contained"
              disabled={this.state.submitData.isSubmiting}
              style={{ marginLeft: 5 }}
              onClick={() => {
                var submitData = Object.assign({}, this.state.submitData);
                submitData.isSubmiting = true;
                if (this.config.action.schema) {
                  var schema = {}
                  if (typeof this.config.action.schema == 'object') {
                    schema = this.config.action.schema
                  } else if (typeof this.config.action.schema == 'function') {
                    schema = this.config.action.schema(this.state.submitData)
                  }
                  submitData = this.validateForm(schema)
                  if (!submitData.isValid) {
                    submitData.isSubmiting = false
                    this.setState({ submitData })
                    return
                  }
                }
                this.setState({ submitData })

                this.config.action.onAction(submitData, this).then(res => {
                  if (res) {
                    this.setState({open: false })
                  }
                  if (this.config.action.onActionDone){
                    this.config.action.onActionDone(submitData, res)
                  }
                });
              }}
            >
              {this.state.submitData.isSubmiting ? <CircularProgress style={{ width: 15, height: 15, color: '#fff' }} /> : (this.config.action.titleAction || 'Confirm')}
            </Button>
          }
          {this.config.multipleActions && this.getVisibleAction(this.config.actions).map( (action, index) => (
            <Button
              key={index}
              color="primary"
              variant="contained"
              disabled={action.disabled}
              style={{ marginLeft: 5 }}
              onClick={() => {
                var submitData = Object.assign({}, this.state.submitData);
                submitData.isSubmiting = true;
                if (action.schema) {
                  var schema = {}
                  if (typeof action.schema == 'object') {
                    schema = action.schema
                  } else if (typeof action.schema == 'function') {
                    schema = action.schema(this.state.submitData)
                  }
                  submitData = this.validateForm(schema)
                  if (!submitData.isValid) {
                    submitData.isSubmiting = false
                    this.setState({ submitData })
                    return
                  }
                }
                this.setState({ submitData })
                action.onAction(submitData, this).then(res => {
                  if (res) {
                    this.setState({ open: false })
                  }
                  if (action.onActionDone){
                    action.onActionDone(submitData, res)
                  }
                });
              }}
            >
              {this.state.submitData.isSubmiting ? <CircularProgress style={{ width: 15, height: 15, color: '#fff' }} /> : (action.titleAction || 'Confirm')}
            </Button>
          ))}
        </DialogActions>
      </BootstrapDialog>
    )
  }
}

ModalForm.instance = singleton
const connectedList = connect(null)(ModalForm);
export { connectedList as ModalForm };
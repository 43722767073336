import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box, Radio, RadioGroup, FormControlLabel
} from '@mui/material'
import AsyncSelect from 'react-select/async'
import { customStyles, errorStyles, loadOptions, handleInputChange } from '../../../utils'
import { fields } from './fields'
import { toastr } from '../../../_helpers_'
import { ModalForm, Uploader, ResourceList } from  '../../../components';
import { request } from  '../../../_services_';


class TopupWallet extends Component {

  newTopup = () => {
    ModalForm.instance.current.openForm({
      title: "New Topup",
      data: {
        money_to_wallet: 0,
        actual_fee: 0,
        c_fee: 0,
        acc_deposit: 0,
        screenshot_transaction_type: 'upload',
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Email Address
            </Box>
            <TextField
              disabled
              fullWidth
              margin='dense'
              name="email"
              value={this.props.user?.email}
              variant="outlined"
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
              }}
            />
          </div>
          {this.props.user?.is_owner &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Team
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/teams', {search: inputValue}).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name="team_id"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'team_id',
                      value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Team"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ team_id }) => team_id}
                valueKey={"team_id"}
                value={submitData.values.team_id}
                styles={submitData.errors?.team_id ? errorStyles : customStyles}
              />
              {submitData.errors?.team_id && <span style={{ color: 'red' }}>{submitData.errors.team_id[0]}</span>}
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              FUD ID 
            </Box>
            <AsyncSelect
              isClearable
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/fuds', {can_use: 1, }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="fud_id"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "fud_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"FUD ID"}
              menuPortalTarget={document.body}
              getOptionLabel={({ fud }) => fud}
              getOptionValue={({ fud_id }) => fud_id}
              valueKey={"fud_id"}
              value={submitData?.values.fud_id || null}
              styles={submitData.errors?.fud_id ? errorStyles : customStyles}
            />
            {submitData.errors?.fud_id && <span style={{ color: 'red' }}>{submitData.errors?.fud_id[0]}</span>}
          </div>
          {submitData?.values?.fud_id &&<div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Remaining Topup (Số tiền được nạp miễn phí còn lại)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              disabled
              type="number"
              name="remaining_topup"
              value={submitData.values.fud_id?.remaining_topup || 0}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>}
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Payment Method <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/payment_methods', {name: inputValue}).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items)
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="payment_method_id"
              onChange={(value) => {
                var e = {
                  target: {
                    name: "payment_method_id",
                    value
                  },
                };
                handleChange(e)
              }}
              placeholder={"Payment method..."}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ payment_method_id }) => payment_method_id}
              valueKey={"payment_method_id"}
              value={submitData?.values.payment_method_id || null}
              styles={submitData.errors?.payment_method_id ? errorStyles : customStyles}
            />
            {submitData.errors?.payment_method_id && <span style={{ color: 'red' }}>{submitData.errors?.payment_method_id[0]}</span>}
          </div>
          {submitData?.values.payment_method_id &&
            <div style={{ marginTop: 10 }}>
              <Box fontSize={15} fontWeight='bold'>
                Sent to
              </Box>
              <div style={{ background: '#F8F9FA', padding: '16px 25px', border: '1px solid #C2C7CD', borderRadius: 8, maxHeight: 150, overflow: 'auto' }}>
                <div dangerouslySetInnerHTML={{ __html: submitData.values.payment_method_id.info }} />
              </div>
            </div>
          }
          {submitData?.values.payment_method_id && submitData.values.payment_method_id?.code === 'po' &&
            <div style={{ marginTop: 10 }}>
              <Box fontSize={15} fontWeight='bold'>
                Send from email
              </Box>
              <TextField 
                fullWidth
                margin='dense'
                name="email_from"
                value={submitData.values.email_from}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline'
                  }
                }}
              />
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              A - Money To Wallet ($)<span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="money_to_wallet"
              onChange={handleChange}
              value={submitData.values.money_to_wallet}
              variant="outlined"
              type="number"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.money_to_wallet}
              helperText={submitData.errors?.money_to_wallet ? submitData.errors.money_to_wallet[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              B - Actual Fee ($)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="actual_fee"
              onChange={handleChange}
              value={submitData.values.actual_fee}
              variant="outlined"
              type="number"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              C - Ad Acc creation/issuance fee ($)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="ad_acc_per_issuance_fee"
              onChange={handleChange}
              value={submitData.values.ad_acc_per_issuance_fee}
              variant="outlined"
              type="number"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              D - Acc Deposit ($)
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="acc_deposit"
              onChange={handleChange}
              value={submitData.values.acc_deposit}
              variant="outlined"
              type="number"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Total money USD
            </Box>
            <TextField 
              fullWidth
              disabled
              type="number"
              margin='dense'
              name="total_money"
              value={Number.parseFloat(submitData.values.money_to_wallet || 0) + Number.parseFloat(submitData.values.actual_fee || 0) + Number.parseFloat(submitData.values.ad_acc_per_issuance_fee || 0) + Number.parseFloat(submitData.values.acc_deposit || 0)}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Voucher Code
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="voucher_code"
              onChange={handleChange}
              value={submitData.values.voucher_code}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Screenshot Transaction Type
            </Box>
            <RadioGroup
              value={submitData.values.screenshot_transaction_type}
              name="screenshot_transaction_type"
              onChange={handleChange}
            >
              <FormControlLabel value="upload" control={<Radio />} label="Upload Image" />
              <FormControlLabel value="direct_link" control={<Radio />} label="Direct Link" />
            </RadioGroup>
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Screenshot of Transaction <span style={{ color: 'red' }}>*</span>
            </Box>
            {submitData.values.screenshot_transaction_type == 'upload' ? 
            <>
              <Uploader 
                className={submitData.errors?.files ? 'form-upload-error' : ''}
                name="attachment"
                multiple={false}
                accept='image/*'
                uploadOnPicked
                handleChange={handleChange}
                submitData={submitData}
              />
              {submitData.errors?.files && <span style={{ color: 'red' }}>{submitData.errors.files[0]}</span>}
            </> 
            : 
            <TextField 
              fullWidth
              margin='dense'
              name="screenshot_transaction_link"
              onChange={handleChange}
              value={submitData.values.screenshot_transaction_link}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              helperText={submitData.errors?.screenshot_transaction_link ? submitData.errors.screenshot_transaction_link[0] : ''}
              error={submitData.errors?.screenshot_transaction_link ? true : false}
            />
            }
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Transfer Contents
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="tranfer_content"
              onChange={handleChange}
              value={submitData.values.tranfer_content}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Long Note
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="note"
              onChange={handleChange}
              value={submitData.values.note}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'Save',
        schema: (submitData) => {
          const schema = {
            payment_method_id: {presence: {message: '^Required'}},
            money_to_wallet: {presence: {message: '^Required'}}
          }
          if (submitData.values.screenshot_transaction_type == 'upload') {
            schema['files'] = {presence: {message: '^Required'}}
          } else {
            schema['screenshot_transaction_link'] = {presence: {message: '^Required'}}
          }
          return schema
        },
        onAction: (submitData) => {
          const query = {
            fud_id: submitData?.values?.fud_id?.fud_id || 0,
            payment_method_id: submitData.values.payment_method_id.payment_method_id,
            email_to: submitData.values.payment_method_id.info,
            email_from: submitData.values.email_from || "",
            extra: submitData.values.files,
            money_to_wallet: Number.parseFloat(submitData.values.money_to_wallet || 0),
            actual_fee: Number.parseFloat(submitData.values.actual_fee || 0),
            ad_acc_per_issuance_fee: Number.parseFloat(submitData.values.ad_acc_per_issuance_fee || 0),
            acc_deposit: Number.parseFloat(submitData.values.acc_deposit || 0),
            voucher_code: submitData.values.voucher_code || "",
            tranfer_content: submitData.values.tranfer_content || "",
            note: submitData.values.note || "",
          }
          return new Promise((resolve) => {
            request.post('/api/v1/topups', query).then(res => {
              if (res.data.success) {
                var items = Object.assign([], this.state?.items)
                items.push(res.data.data)
                this.setState({ items })
                toastr.success("Add topup successful")
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  render () {

    const { navigate } = this.props;
    return (
      <ResourceList 
        route="/api/v1/topups"
        titlePage="Drebest | Topup Wallet"
        actions={[
          // {
          //   text: 'New',
          //   variant: 'contained',
          //   icon: <AddIcon />,
          //   action: (ctx) => this.newTopup(ctx),
          //   visible: true
          // }
        ]}
        title="Topup Wallet"
        fields={fields}
        valueKey="topup_id"
        onClickRow={(item) => navigate(`/topup/${item.topup_id}`)}
      />
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TopupWallet);
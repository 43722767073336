import { FormControl, FormControlLabel, Radio, RadioGroup, IconButton, Button, TextField, Box } from "@mui/material";
import { timezones, customStyles, errorStyles, handleInputChange, loadOptions, disabledStyles } from '../../../utils';
import { ImagePreview } from '../../../components';
import { request } from '../../../_services_'
import { validate } from 'validate.js';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { IconTrash } from '@tabler/icons';
import { withStyles } from "@mui/styles";

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    }
  }
})(TextField);

const formsSchema = {
  domain: { 
    presence: { message: '^Required' },
  },
  platform: {
    presence: { message: '^Required' }
  },
  page_link: {
    presence: { message: '^Required' }
  },
  page_name: {
    presence: { message: '^Required' }
  },
}

export const productType = [
  { id: 'dropship', name: 'Dropship' },
  { id: 'pod', name: 'POD' },
  { id: 'beauty', name: 'Beauty' },
  { id: 'functional_foods', name: 'Functional foods' },
  { id: 'game_online', name: 'Game online' },
]

export const fields = [
  {
    name: 'sin_number',
    label: 'SIN Number',
    renderValue: (item) => `SIN_${item.sin_id}`,
    sort: true,
    width: '8rem'
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.id ? `Team ${item.team_id?.id}` : '',
    sort: true,
    width: '8rem'
  },
  {
    name: 'spent_per_month',
    label: 'Spent USD/month (estimated)',
    renderValue: (item) => item.spent_per_month,
    sort: true
  },
  {
    name: 'total_acc_request',
    label: 'Total Acc request',
    renderValue: (item) => item.total_acc_request,
    sort: true
  },
  {
    name: 'request_acc_timezone',
    label: 'Request Acc Timezone',
    renderValue: (item) => item.request_acc_timezone,
    sort: true
  },
  {
    name: 'product_type_id',
    label: 'Product Type',
    renderValue: (item) => item.product_type_id?.name,
    sort: true,
    sortName: 'product_type.name',
    width: '10rem'
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'sin_id',
    label: 'SIN Number',
    renderValue: (item) => `SIN ${item.sin_id}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'username',
    label: 'Team_Seller Username',
    renderValue: (item) => item.team_id ? `Team_${item.team_id.team_id} ${item.team_id.owner_id?.shortname}` : '',
    disabled: true
  },
  {
    type: 'number',
    name: 'pause',
    label: 'Pause',
  },
  {
    type: 'number',
    name: 'spent_per_month',
    label: 'Monthly spending (USD)',
  },
  {
    type: 'number',
    name: 'total_acc_request',
    label: 'Number of accounts'
  },
  {
    type: 'select',
    name: 'request_acc_timezone',
    label: 'Account Timezone',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = timezones.filter(e => e.name.toLowerCase().includes(inputValue.toLowerCase()))
        resolve(result)
      })
    },
    valueKey: 'id',
    labelKey: 'name',
    renderValue: (item) => timezones.find(e => e.name === item.request_acc_timezone)
  },
  {
    type: 'select',
    name: 'product_type_id',
    label: 'Product Type',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/product_types', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({id: e.product_type_id, name: e.name})))
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'id',
    labelKey: 'name',
  },
  {
    type: 'custom',
    name: 'domains',
    renderValue: (items, ctx) => 
    <div>
      <div>
        <Box fontSize={15} fontWeight='bold'>
          Domains (Domain, Platform)
        </Box>
      </div>
      {items?.domains?.map((item, index) =>
        <div className='d-flex' key={index}>
          <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
            <div className="flex-1">
              <CustomTextField
                fullWidth
                disabled={ctx.props.mode == 'view' || false}
                margin='dense'
                name='domain'
                onChange={(event) => {
                  item.domain = event.target.value
                  const errors = validate(item, formsSchema);
                  item.isValid = errors ? false : true;
                  item.errors = errors || {}; 
                  const e = {
                    target: {
                      name: 'domains',
                      value: [...items.domains]
                    }
                  }
                  ctx.handleChange(e)
                }}
                value={item.domain || ''}
                variant="outlined"
                placeholder='https://'
                InputProps={{
                  classes: {
                    disabled: ctx.props.classes.input
                  }
                }}
                error={item.errors?.domain}
                helperText={item.errors?.domain ? item.errors.domain[0] : ''}
              />
            </div>
            <div className="flex-1">
              <AsyncCreatableSelect
                isDisabled={ctx.props.mode == 'view' || false}
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/platforms', { name: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      } else {
                        resolve([])
                      }
                    }, error => {
                      resolve([])
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name='platform'
                onChange={(value) => {
                  item.platform = value
                  const errors = validate(item, formsSchema);
                  item.isValid = errors ? false : true;
                  item.errors = errors || {}; 
                  var e = {
                    target: {
                      name: "domains",
                      value: [...items.domains]
                    },
                  };
                  ctx.handleChange(e)
                }}
                placeholder={`Platform of domain ${index + 1}`}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ platform_id }) => platform_id}
                valueKey={"platform_id"}
                value={item?.platform || null}
                styles={ctx.props.mode == 'view' ? disabledStyles : customStyles}
                getNewOptionData={(inputValue, optionLabel) => ({
                  platform_id:inputValue,
                  name: 'Create \"' + inputValue + '\"...',
                  __isNew__: true
                })}
                onCreateOption={(inputValue) => {
                  request.post('/api/v1/platforms', { name: inputValue }).then(res => {
                    if (res.data.success) {
                      var e = {
                        target: {
                          name: "platform",
                          value:res.data.data
                        },
                      };
                      ctx.handleChange(e)
                    } else {
                      toastr.error(res.data.msg)
                    }
                  }, err => toastr.error(err))
                }}
              />
              {item.errors?.platform ? <span style={{ fontSize: 12, color: "red", paddingLeft: 10 }}>{item.errors.platform[0]}</span> : null}
            </div>
          </div>
          {ctx.props.mode != 'view' &&
          <div className='d-flex align-items-center justify-content-center' style={{ width: '24px' }}>
            <IconButton 
              color="primary"
              onClick={() => {
                const domains = Object.assign([], items.domains)
                if(domains.length >= 2){
                  domains.splice(index, 1)
                } else {
                  domains[0] = {} 
                }

                const event = {
                  target: {
                    name: 'domains',
                    value: domains
                  }
                }
                ctx.handleChange(event)
              }}
              >
              <IconTrash color="red" width={16} height={16} />
            </IconButton>
          </div>}
        </div>
      )}
      {ctx.props.mode != 'view' &&
      <Button 
        variant="outlined"
        onClick={() => {
          const domains = Object.assign([], items.domains)
          domains.push({})
          ctx.handleChange({ target: { name: 'domains', value: domains } })
        }}
        disabled={items.domains && (!items?.domains[items?.domains?.length - 1]?.platform?.id || !items.domains[items.domains?.length -1].domain)}
      >
        Add
      </Button>}
    </div>,
  },
  {
    type: 'custom',
    name: 'exists_at_another_agency',
    label: 'Have you ever created ad account with this domain and fanpage at other Agency?',
    renderValue: (item, ctx) => (
      <div className="mt-2">
        <Box fontSize={15} fontWeight="bold">Have you ever created ad account with this domain and fanpage at other Agency?</Box>
        <FormControl>
          <RadioGroup
            row
            name="exists_at_another_agency"
            type="radio"
            value={item.exists_at_another_agency ? 1 : 0}
            onChange={ctx.handleChange}
          >
            <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={ctx.props.mode == 'view'} />
            <FormControlLabel value={0} control={<Radio />} label="No" disabled={ctx.props.mode == 'view'} />
          </RadioGroup>
        </FormControl>
      </div>
    )
  },
  {
    type: 'custom',
    name: 'change_dns',
    label: 'Nếu khách hàng chạy Vpcs thì có thể tạm thời chỉnh sửa DNS của website không?',
    renderValue: (item, ctx) => (
      <div className="mt-2">
        <Box fontSize={15} fontWeight="bold">Nếu khách hàng chạy Vpcs thì có thể tạm thời chỉnh sửa DNS của website không?</Box>
        <FormControl>
          <RadioGroup
            row
            name="change_dns"
            type="radio"
            value={item.change_dns ? 1 : 0}
            onChange={ctx.handleChange}
          >
            <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={ctx.props.mode == 'view'} />
            <FormControlLabel value={0} control={<Radio />} label="No" disabled={ctx.props.mode == 'view'} />
          </RadioGroup>
        </FormControl>
      </div>
    )
  },
  {
    type: 'custom',
    name: 'pages',
    label: 'Pages',
    renderValue: (items, ctx) => 
      <div>
        <div style={{ marginTop: 10 }}>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              Pages (Page Link, Page ID, Page Name)
            </Box>
          </div>
          {items?.pages?.map((item, index) => 
            <div className='d-flex' key={index}>
              <div className='d-flex align-items-center' style={{ gap: '5px', width: '100%' }}>
                <div className="flex-1">
                  <CustomTextField
                    fullWidth
                    disabled={ctx.props.mode == 'view' || false}
                    margin='dense'
                    name="page_link"
                    onChange={(event) => {
                      item.page_link = event.target.value
                      const errors = validate(item, formsSchema);
                      item.isValid = errors ? false : true;
                      item.errors = errors || {}; 
                      const e = {
                        target: {
                          name: 'pages',
                          value: [...items.pages]
                        }
                      }
                      ctx.handleChange(e)
                    }}
                    value={item.page_link || ''}
                    variant="outlined"
                    placeholder='https://'
                    InputProps={{
                      classes: {
                        disabled: ctx.props.classes.input
                      }
                    }}
                    error={item.errors?.page_link}
                    helperText={item.errors?.page_link ? item.errors.page_link[0] : ''}
                  />
                </div>
                <div className="flex-1">
                  <CustomTextField
                    fullWidth
                    disabled={ctx.props.mode == 'view' || false}
                    placeholder={`Page ${index + 1} - Name`}
                    margin='dense'
                    name="page_name"
                    onChange={(event) => {
                      item.page_name = event.target.value
                      const errors = validate(item, formsSchema);
                      item.isValid = errors ? false : true;
                      item.errors = errors || {}; 
                      const e = {
                        target: {
                          name: 'pages',
                          value: [...items.pages]
                        }
                      }
                      ctx.handleChange(e)
                    }}
                    value={item.page_name || ''}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        disabled: ctx.props.classes.input
                      }
                    }}
                    error={item.errors?.page_name}
                    helperText={item.errors?.page_name ? item.errors.page_name[0] : ''}
                  />
                </div>
              </div>
              {ctx.props.mode != 'view' &&
              <div className='d-flex align-items-center justify-content-center' style={{ width: '24px' }}>
                <IconButton 
                  color="primary"
                  onClick={() => {
                    const pages = Object.assign([], items?.pages)
                    if(pages.length >= 2){
                      pages.splice(index, 1)
                    } else {
                      pages[0] = {} 
                    }
                    
                    const event = {
                      target: {
                        name: 'pages',
                        value: pages
                      }
                    }
                    ctx.handleChange(event)
                  }}
                  >
                  <IconTrash color="red" width={16} height={16}/>
                </IconButton>
              </div>}
            </div>
          )}
        </div>
        {ctx.props.mode != 'view' &&
        <Button 
          variant="outlined"
          onClick={() => {
            const pages = Object.assign([], items?.pages)
            pages.push({})
            ctx.handleChange({ target: { name: 'pages', value: pages } })
          }}
          disabled={items.pages && (!items?.pages[items?.pages?.length - 1]?.page_name || !items?.pages[items?.pages?.length - 1]?.page_link)}
        >
          Add
        </Button>
        }
      </div>
  },
  {
    type: 'custom',
    name: 'bm_id',
    renderValue: (item, ctx) => 
      <div className='d-flex' style={{ marginTop: 10, gap: 10 }}>
        <div style={{ width: '50%' }}>
          <Box fontSize={15} fontWeight='bold'>
            BM ID
          </Box>
          <TextField
            fullWidth
            margin='dense'
            name="bm_id"
            onChange={() => ctx.handleChange()}
            value={item?.bm_id}
            variant="outlined"
            type="number"
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline'
              }
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </div>
        <div style={{ width: '50%' }}>
          <Box fontSize={15} fontWeight='bold'>
            BM Name
          </Box>
          <TextField
            fullWidth
            margin='dense'
            name="bm_name"
            onChange={() => ctx.handleChange()}
            value={item?.bm_name}
            variant="outlined"
            type="text"
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline'
              }
            }}
          />
        </div>
      </div>
  },
  {
    type: 'text',
    name: 'bm_admin_link',
    label: 'BM ID 2 (preventive)'
  },
  {
    type: 'text',
    name: 'other_note',
    label: 'Note'
  },
]
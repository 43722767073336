import TeamUser from "./TeamUser"

export const fields = [
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => `Team_${item.team_id}`,
    width: '100px'
  },
  {
    name: 'type_acc',
    label: 'Type Acc',
    width: '100px'
  },
  {
    name: 'total_fund_to_now',
    label: 'Total fund',
    renderValue: (item) => item.total_fund_to_now?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '120px',
    align: 'right'
  },
  {
    name: 'fund_remaining',
    label: 'Fund Remaining',
    renderValue: (item) => item.total_fund?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '130px',
    align: 'right'
  },
  {
    name: 'total_topup_to_wallet',
    label: 'Total topup to Wallet (A)',
    renderValue: (item) => item.total_topup_to_wallet?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '190px',
    align: 'right'
  },
  {
    name: 'wallet_remaining',
    label: 'Wallet remaining',
    renderValue: (item) => item.wallet_a?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '150px',
    align: 'right'
  },
  {
    name: 'total_acc_rental_fee',
    label: 'Rental Fee (B)',
    renderValue: (item) => item.total_acc_rental_fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '150px',
    align: 'right'
  },
  {
    name: 'total_acc_creation_insurance_fee',
    label: 'Acc Creation Fee (C)',
    renderValue: (item) => item.total_acc_creation_insurance_fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '160px',
    align: 'right'
  },
  {
    name: 'total_acc_deposit',
    label: 'Deposit Fee (D)',
    renderValue: (item) => item.total_acc_deposit?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '150px',
    align: 'right'
  },
  {
    name: 'total_others',
    label: 'Others (O)',
    renderValue: (item) => item.total_others?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '150px',
    align: 'right'
  },
  {
    name: 'refund',
    label: 'Refund (R)',
    renderValue: (item) => item.refund?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '150px',
    align: 'right'
  },
  {
    name: 'platform_id',
    label: 'Platform',
    renderValue: (item) => item.platform_id?.title,
    width: '100px'
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id}`
  },
  {
    type: 'number',
    name: 'total_fund_to_now',
    label: 'Total Fund',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_topup_to_wallet',
    label: 'Sum Service A',
    disabled: true,
  },
  {
    type: 'number',
    name: 'wallet_a',
    label: 'Wallet Remaining',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_acc_rental_fee',
    label: 'Sum Service B',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_acc_creation_insurance_fee',
    label: 'Sum Service C',
    disabled: true
  },
  {
    type: 'number',
    name: 'total_acc_deposit',
    label: 'Sum Service D',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_others',
    label: 'O - Others',
    disabled: true,
  },
  {
    type: 'number',
    name: 'refund',
    label: 'R - Refund',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_fund',
    label: 'Fund Remaining',
    disabled: true,
  },
  {
    type: 'text',
    name: 'platform_id',
    label: 'Platform',
    renderValue: (item) => item.platform_id?.title,
    disabled: true,
  },
  {
    type: 'text',
    name: 'product_url',
    label: 'Product Url',
    disabled: true,
  },
  {
    type: 'text',
    name: 'type_acc',
    label: 'Type Acc',
    disabled: true,
  },
  {
    type: 'custom',
    name: 'team_user',
    label: 'Team User',
    renderValue: (item, ctx) => <TeamUser user={ctx.props.user} />
  },
]
import React, { useEffect } from 'react'
import { withStyles } from '@mui/styles'
import { 
  TextField, Box, FormControlLabel, Checkbox,
  RadioGroup, Radio
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles, renderExtraField, disabledStyles } from '../../../utils';
import { CustomNumberFormat } from '../../../components';
import { request } from '../../../_services_';
import { toastr } from 'src/_helpers_';

const useStyles = theme => ({
  input: {
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  },
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    }
  }
})

const ServicePopup = (props) => {
  const { submitData, handleChange, ctx, classes } = props;
  const [config, setConfig] = React.useState({})
  const [discountFeeValue, setDiscountFeeValue] = React.useState(0)

  useEffect(() => {
    request.get('/api/v1/all_configs').then(res => {
      if (res.data.success) {
        setConfig(res.data.data)
      }
    })
  }, [])

  useEffect(() => {
    if (submitData.values.discount_code) {
      (async () => {
        const fee = await discountFee(submitData);
        setDiscountFeeValue(fee);
      })();
    }
  }, [submitData.values.discount_code]);

  const getFeeOfPackB = (team, _package) => {
    const type_acc = team.type_acc
    if (!type_acc) {
      return 0
    }
    const total_topup = _package.total_topup_30days
    const fee_percent = _package[type_acc] || 0
    const fee = total_topup * fee_percent / 100
    return fee
  }

  const getFeeUpgradeFUD = (team, _package, fud) => {
    const new_fee = getFeeOfPackB(team, _package)
    const old_fee = fud.fee_of_pack
    return new_fee > old_fee ? (new_fee - old_fee).toFixed(2) : 0
  }

  const getFeeAcc = (team, num_account, service_code) => {
    const number_account = Number.parseInt(num_account)
    if (service_code == 'C') {
      const type_acc = team.type_acc
      const value = JSON.parse(config['acc_creation_fee'] || "{}")
      const fee_per_account = value[type_acc] || 0
      const fee = fee_per_account * number_account;
      return fee
    } else if (service_code == 'D') {
      const fee_per_account = Number.parseFloat(config['acc_deposit'])
      const fee = fee_per_account * number_account;
      return fee
    }
  }

  const customFee = (submitData) => {
    let fee = 0
    if (submitData.values.product_id?.code == 'A1') {
      const category_type = submitData.values?.team_id?.category_type
      const list_fee = submitData.values?.fee_topup_to_account || []
      const fee_obj = list_fee.find(e => e.key == category_type)
      
      if (typeof fee_obj == 'object') {
        const percent = fee_obj['value']
        const dataAcc = submitData.values?.extra_field?.length > 0 ? submitData.values?.extra_field[0] : {value: []}
        dataAcc.value.map(obj => {
          fee += Number.parseFloat(obj.money || 0)*percent/100
        })
      }
    }
    return fee
  }

  const discountFee = async (submitData) => {
    let discount_fee = 0
    var query = {}
    if(submitData.values.discount_code){
      query['fee'] = submitData.values.fee 
      query['discount_code'] = submitData.values.discount_code
    }
    try {
      const res = await request.get("/api/v1/check_discount", query);
      if (res.data.success) {
        discount_fee = res.data.data.new_fee;
      } else {
        discount_fee = submitData.values.fee
      }
    } catch (err) {
      discount_fee = submitData.values.fee
    }
    return discount_fee
  }

  return (
    <div className='p-2'>
      <div>
        <Box fontSize={15} fontWeight='bold'>
          Team ID
        </Box>          
          <TextField 
            fullWidth
            margin='dense'
            disabled
            name="role"
            value={submitData.values.team_id?.team_search}
            variant="outlined"
            className={classes.root}
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline',
                disabled: classes.input
              }
            }}
          />
      </div>
      {submitData.values.product_id?.code != 'A1' &&
        <div style={{ marginTop: 10 }}>
          <Box fontSize={15} fontWeight='bold'>
            Fund Remaining
          </Box>
          <TextField 
            fullWidth
            margin='dense'
            disabled
            name="role"
            value={submitData.values?.team_id?.total_fund}
            className={classes.root}
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline',
                disabled: classes.input
              },
              inputComponent: CustomNumberFormat
            }}
          />
        </div>
      }
      <div style={{ marginTop: 10 }}>
        <Box fontSize={15} fontWeight='bold'>
          Service <span style={{ color: 'red' }}>*</span>
        </Box>
        <AsyncSelect
          className="MuiFormControl-marginDense"
          cacheOptions
          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
            return new Promise(resolve => {
              request.get('/api/v1/products', {search: inputValue, no_limit: 1, sort: 'code', type_sort: 'asc'}).then(res => {
                if (res.data.success) {
                  resolve(res.data.data.items)
                }
              })
            })
          })}
          isDisabled={ctx.state.openForm}
          defaultOptions
          onInputChange={handleInputChange}
          isSearchable
          name={"product_id"}
          onChange={(value) => {
            const data = Object.assign({}, submitData)
            const keys = Object.keys(submitData.values)
            keys.map(key => {
              if (!['team_id', 'product_id', 'fee', 'note'].includes(key)) {
                delete data.values[key]
              }
            })
            ctx.setState({ submitData: data })
            var e = {
              target: {
                name: "product_id",
                value
              },
            };
            handleChange(e)
            if (['A', 'A1'].includes(value.code)) {
              request.get(`/api/v1/team/${submitData.values.team_id?.team_id}`).then(res => {
                if (res.data.success) {
                  var e3 = {
                    target: {
                      name: "wallet_a",
                      value: res.data.data.result.wallet_a
                    },
                  };
                  handleChange(e3)
                }
              })
              if (value.code == 'A1') {
                const event = {
                  target: {
                    name: 'fee',
                    value: value.price
                  }
                }
                handleChange(event)
                request.get('/api/v1/fee_topup_to_acc').then(res => {
                  if (res.data.success) {
                    var e4 = {
                      target: {
                        name: "fee_topup_to_account",
                        value: res.data.data.items
                      },
                    };
                    handleChange(e4)
                  }
                })
              }
            } else if (value.code == 'B' && !submitData.values.option) {
              const option = {
                target: {
                  name: 'option',
                  value: 'new_fud'
                }
              }
              handleChange(option)
            } else if (['C', 'D'].includes(value.code) && submitData.values.num_account) {
              const event = {
                target: {
                  name: 'fee',
                  value: getFeeAcc(submitData.values.team_id, submitData.values.num_account, value.code)
                }
              }
              handleChange(event)
            } else {
              const event = {
                target: {
                  name: 'fee',
                  value: value.price
                }
              }
              handleChange(event)
            }
            if (value.extra_field) {
              const keys = value.extra_field.map(e => e.key)
              if (keys.includes('link_content') && submitData.values.team_id?.content_file) {
                const e1 = {
                  target: {
                    name: 'link_content',
                    value: submitData.values.team_id?.content_file
                  }
                }
                handleChange(e1)
                const extra_field = Object.assign([], value.extra_field)
                const obj = extra_field.find(e => e.key == 'link_content')
                obj['value'] = submitData.values.team_id?.content_file
                const event = {
                  target: {
                    name: 'extra_field',
                    value: extra_field
                  }
                }
                handleChange(event)
              }
            }
          }}
          placeholder={"Service"}
          menuPortalTarget={document.body}
          getOptionLabel={({ title }) => title}
          getOptionValue={({ id }) => id}
          valueKey={"id"}
          value={submitData?.values.product_id || null}
          styles={submitData.errors?.product_id ? errorStyles : (ctx.state.openForm ? disabledStyles : customStyles)}
        />
        {submitData.errors.product_id && <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>{submitData.errors.product_id[0]}</span>}
      </div>
      {submitData.values.product_id?.code == 'A' &&
        <>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              FUD ID <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/fuds', {search: inputValue, service_code: submitData.values.product_id?.code, team_id: submitData.values.team_id?.team_id }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({...e, id: e.fud_id})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"fud_id"}
              onChange={(value) => {
                const fud = {
                  target: {
                    name: 'fud_id',
                    value
                  }
                }
                handleChange(fud)
              }}
              placeholder={"FUD"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.fud_id || null}
              styles={submitData.errors?.fud_id ? errorStyles : customStyles}
            />
          </div>
          {submitData.values.fud_id && 
            <div style={{ marginTop: 10 }}>
              <Box fontSize={15} fontWeight='bold'>
                Remaining Topup to Wallet
              </Box>
              <TextField 
                disabled
                fullWidth
                margin='dense'
                name="fund_remaining"
                value={submitData.values.fud_id?.remaining_topup}
                variant="outlined"
                className={classes.root}
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline',
                    disabled: classes.input
                  },
                  inputComponent: CustomNumberFormat
                }}
              />
            </div>
          }
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Money topup to Wallet <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="fee"
              onChange={handleChange}
              value={submitData.values.fee}
              placeholder="Money topup..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                },
                inputComponent: CustomNumberFormat
              }}
              error={submitData.errors?.fee ? true : false}
              helperText={submitData.errors?.fee ? submitData.errors?.fee[0] : ''}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={submitData.values.topup_to_account == 1 ? true : false}
                  onChange={e => {
                    const event = {
                      target: {
                        name: 'topup_to_account',
                        value: e.target.checked ? 1 : 0
                      }
                    }
                    handleChange(event)
                  }}
                />
              }
              label={"Do you want to topup to account?"}
            />
          </div>
        </>
      }
      {submitData.values.product_id?.code == 'B' &&
      <>
        <div style={{ marginTop: 10 }}>
          <Box fontSize={15} fontWeight="bold">Option <span style={{ color: 'red' }}>*</span></Box>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="option"
            value={submitData.values.option}
            onChange={(e) => {
              handleChange(e)
              const extra_field = Object.assign([], submitData.values.extra_field)
              const option = extra_field.find(e => e.key == "option")
              if (option) {
                option['value'] = e.target.value
              } else {
                extra_field.push({
                  "key": "option",
                  "label": "Option",
                  "type": "radio",
                  "options": [
                    {"key": "upgrade_fud", "label": "Upgrade FUD"},
                    {"key": "new_fud", "label": "New FUD"},
                  ],
                  "value": e.target.value
                })
              }
              var extra = {
                target: {
                  name: "extra_field",
                  value: extra_field
                }
              };
              handleChange(extra)
              var fee_value = 0
              if (e.target.value == 'new_fud' && submitData.values.package_id) {
                fee_value = getFeeOfPackB(submitData.values.team_id, submitData.values.package_id)
              } else if (e.target.value == 'upgrade_fud' && submitData.values.package_id && submitData.values.fud_id) {
                fee_value = getFeeUpgradeFUD(submitData.values.team_id, submitData.values.package_id, submitData.values.fud_id)
              }
              const event = {
                target: {
                  name: 'fee',
                  value: fee_value
                }
              }
              handleChange(event)
            }}
            row
          >
            <FormControlLabel value="upgrade_fud" control={<Radio />} label="Upgrade FUD" />
            <FormControlLabel value="new_fud" control={<Radio />} label="New FUD" />
          </RadioGroup>
        </div>
        {submitData.values.option == 'upgrade_fud' && 
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              FUD ID <span style={{ color: 'red' }}>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              cacheOptions
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/fuds', {search: inputValue, service_code: submitData.values.product_id?.code, team_id: submitData.values.team_id?.team_id }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({...e, id: e.fud_id})))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name={"fud_id"}
              onChange={(value) => {
                const fud = {
                  target: {
                    name: 'fud_id',
                    value
                  }
                }
                handleChange(fud)
                const extra_field = Object.assign([], submitData.values.extra_field)
                const fud_id = extra_field.find(e => e.key == 'fud_id')
                if (fud_id) {
                  fud_id['value'] = value
                } else {
                  extra_field.push({
                    "key": "fud_id",
                    "label": "FUD ID",
                    "type": "select",
                    "route": "/api/v1/fuds",
                    "valueKey": "id",
                    "labelKey": "title",
                    "value": value
                  })
                }
                var e = {
                  target: {
                    name: "extra_field",
                    value: extra_field
                  }
                };
                handleChange(e)
                if (submitData.values.package_id) {
                  const fee = {
                    target: {
                      name: "fee",
                      value: getFeeUpgradeFUD(submitData.values.team_id, submitData.values.package_id, value)
                    }
                  }
                  handleChange(fee)
                }
              }}
              placeholder={"FUD"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name, level }) => `${name} (${level})`}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData?.values.fud_id || null}
              styles={submitData.errors?.fud_id ? errorStyles : customStyles}
            />
          </div>
        }
        <div style={{ marginTop: 10 }}>
          <Box fontSize={15} fontWeight='bold'>
            Level <span style={{ color: 'red' }}>*</span>
          </Box>
          <AsyncSelect
            className="MuiFormControl-marginDense"
            cacheOptions
            loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
              return new Promise(resolve => {
                request.get('/api/v1/rate_ex', {search: inputValue, no_limit: 1, hidden: 0}).then(res => {
                  if (res.data.success) {
                    resolve(res.data.data.items.map(e => ({ ...e, id: e.package_id })))
                  }
                })
              })
            })}
            defaultOptions
            onInputChange={handleInputChange}
            isSearchable
            name={"package_id"}
            onChange={(value) => {
              const _package = {
                target: {
                  name: 'package_id',
                  value
                }
              }
              handleChange(_package)
              const extra_field = Object.assign([], submitData.values.extra_field)
              const package_id = extra_field.find(e => e.key == "package_id")
              if (package_id) {
                package_id['value'] = value
              } else {
                extra_field.push({
                  "key": "package_id",
                  "label": "Level",
                  "type": "select",
                  "route": "/api/v1/rate_ex?no_limit=1&hidden=0",
                  "valueKey": "id",
                  "labelKey": "title",
                  "value": value
                })
              }
              var extra = {
                target: {
                  name: "extra_field",
                  value: extra_field
                }
              };
              handleChange(extra)
              if (submitData.values.option == 'new_fud') {
                const event = {
                  target: {
                    name: 'fee',
                    value: getFeeOfPackB(submitData.values.team_id, value)
                  }
                }
                handleChange(event)
              } else if (submitData.values.option == 'upgrade_fud' && submitData.values.fud_id) {
                const event = {
                  target: {
                    name: 'fee',
                    value: getFeeUpgradeFUD(submitData.values.team_id, value, submitData.values.fud_id)
                  }
                }
                handleChange(event)
              }
            }}
            placeholder={"Level"}
            menuPortalTarget={document.body}
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            valueKey={"id"}
            value={submitData?.values.package_id || null}
            styles={submitData.errors?.package_id ? errorStyles : customStyles}
          />
          {submitData.errors.package_id && <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>{submitData.errors.package_id[0]}</span>}
        </div>
      </>
      }
      {['C', 'D'].includes(submitData.values.product_id?.code) &&
        <div style={{ marginTop: 10 }}>
          <Box fontSize={15} fontWeight='bold'>
            Number of accounts <span style={{ color: 'red' }}>*</span>
          </Box>
          <TextField
            fullWidth
            margin='dense'
            name="num_account"
            onChange={(e) => {
              handleChange(e)
              if (submitData.values.team_id) {
                const event = {
                  target: {
                    name: 'fee',
                    value: getFeeAcc(submitData.values.team_id, e.target.value, submitData.values.product_id?.code)
                  }
                }
                handleChange(event)
              }
              const extra_field = Object.assign([], submitData.values.extra_field)
              const num_account = extra_field.find(e => e.key == "num_account")
              if (num_account) {
                num_account['value'] = e.target.value
              } else {
                extra_field.push({
                  "key": "num_account",
                  "label": "Number of acc",
                  "type": "number",
                  "value": e.target.value
                })
              }
              var extra = {
                target: {
                  name: "extra_field",
                  value: extra_field
                }
              };
              handleChange(extra)
            }}
            value={submitData.values.num_account}
            placeholder="Number of accounts..."
            variant="outlined"
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline'
              },
              inputComponent: CustomNumberFormat
            }}
            error={submitData.errors?.num_account ? true : false}
            helperText={submitData.errors?.num_account ? submitData.errors?.num_account[0] : ''}
          />
        </div>
      }
      {submitData.values.product_id?.code == 'A1' && 
        <div style={{ marginTop: 10 }}>
          <Box fontSize={15} fontWeight='bold'>
            Wallet Remaining
          </Box>
          <TextField 
            fullWidth
            margin='dense'
            disabled
            name="role"
            value={submitData.values?.wallet_a}
            className={classes.root}
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline',
                disabled: classes.input
              },
              inputComponent: CustomNumberFormat
            }}
          />
        </div>
      }
      {!['A', 'B', 'C', 'D'].includes(submitData.values.product_id?.code) && submitData.values.product_id?.extra_field?.map(field => renderExtraField(field, handleChange, submitData))}
      {!['A'].includes(submitData.values.product_id?.code) && 
        <div className="mt-2">
          <Box fontSize={15} fontWeight='bold'>
            Fee
          </Box>
          <TextField 
            fullWidth
            margin='dense'
            disabled={!submitData.values.product_id?.dynamic_fee}
            name="fee"
            value={['A1'].includes(submitData.values.product_id?.code) ? customFee(submitData) : submitData.values?.fee}
            onChange={handleChange}
            className={classes.root}
            InputProps={{
              classes: {
                notchedOutline: 'notchedOutline',
                disabled: classes.input
              },
              inputComponent: CustomNumberFormat
            }}
            error={submitData.errors?.fee && submitData.values.product_id?.dynamic_fee ? true : false}
            helperText={submitData.errors?.fee && submitData.values.product_id?.dynamic_fee ? submitData.errors?.fee[0] : ''}
          />
        </div>
      }
      {!['A'].includes(submitData.values.product_id?.code) &&  
        <>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Discount Code
            </Box>
            <TextField
              className='mt-2'
              fullWidth
              name="discount_code"
              onChange={handleChange}
              value={submitData.values.discount_code || ""}
              placeholder="Code..."
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline',
                }
              }}
            />
          </div>
          { submitData.values.discount_code && 
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold' className='mb-2'>
                Fee After Discount
              </Box>
              <TextField
                disabled
                fullWidth
                name="discount_fee"
                onChange={handleChange}
                value={discountFeeValue}
                variant="outlined"
                className={classes.root}
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline',
                      disabled: classes.input
                    },
                    inputComponent: CustomNumberFormat
                  }}
              />
            </div>
          }
        </>
      }
      <div className='mt-2'>
        <Box fontSize={15} fontWeight='bold'>
          Customer's Note
        </Box>
        <TextField
          className='mt-2'
          fullWidth
          name="note"
          onChange={handleChange}
          value={submitData.values.note || ""}
          placeholder="Note..."
          variant="outlined"
          multiline
          maxRows={3}
          InputProps={{
            classes: {
              notchedOutline: 'notchedOutline',
            }
          }}
        />
      </div>
      {submitData.values.product_id?.drebest_note &&
        <div className='mt-2'>
          <Box fontSize={15} fontWeight='bold' sx={{textDecoration: 'underline'}}>
            Note:
          </Box>
          <Box fontSize={15}>
            <div dangerouslySetInnerHTML={{ __html: submitData.values.product_id?.drebest_note }}/>
          </Box>
        </div>
      }
    </div>
  )

}


export default withStyles(useStyles)(ServicePopup)
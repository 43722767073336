import React, { useEffect, useState } from "react";
import { GoogleLogin, GoogleLogout, useGoogleLogout } from 'react-google-login';
// import { gapi } from 'gapi-script';
import { refreshTokenSetup } from "../utils/refreshToken";
import { request } from "src/_services_";
import { useNavigate } from "react-router-dom";
import { toastr, withRouter } from "../../../_helpers_";
import { slugifyStr } from '../../../utils';
import cookie from 'react-cookies';

const SignInWithGoogle = () => {
  const navigate = useNavigate();

  const onFailure = (err) => {
    console.log('failed:', err);
  };

  // const onLogoutSuccess = (res) => {
  // };

  // const { signOut } = useGoogleLogout({
  //   clientId,
  //   onLogoutSuccess,
  //   onFailure,
  // });

  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope: ''
  //     });
  //   };
  //   gapi.load('client:auth2', initClient);
  // });

  const onSuccess = (res) => {
    const data = {
      email: res.profileObj.email,
      fullname: res.profileObj.name,
      password: res.profileObj.googleId,
      shortname: slugifyStr(res.profileObj.name)
    }
    const aff_code = cookie.load('aff_code')
    if (aff_code) {
      data['aff_code'] = aff_code
    }
    request.post('/api/v1/register_company', data).then(resp => { 
      if(resp.data.success){
        toastr.success("Create an account successful!")
        navigate(`/mail_success`, {state: {email: res.profileObj.email}})
      } else {
        toastr.error(resp.data.msg)
        // gapi.auth2.getAuthInstance().disconnect();
      }
    }, err => {
      toastr.error(err);
    })
    refreshTokenSetup(res)
  };

  return (
    <GoogleLogin
      className="db-google_button"
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      buttonText="Sign up with Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      // isSignedIn={true}
      style={ {
        'font-size': '20px',
        flexDirection: 'column',
        width:'100px'
    }}
    />
  );
}

export default SignInWithGoogle
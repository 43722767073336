import React, { useEffect, useState } from "react";
import { 
  Container, Grid, Stack, Typography, Card, CardContent, Box, Table, TableContainer, TableBody, TableRow, TableCell, Avatar, Button, Menu, MenuItem, Dialog, DialogTitle, TextField
} from "@mui/material";
import { Page } from "../../../components";
import { styled } from "@mui/material/styles"
import AppWebsiteVisits from "./AppWebsiteVisits";
import { connect } from "react-redux";
import { request } from "../../../_services_";
import { toastr } from "../../../_helpers_";
import moment from 'moment'
import { IconCalendarEvent, IconX, IconLink } from '@tabler/icons'
import DateRangePicker from 'react-bootstrap-daterangepicker';

const CardStyle = styled(Card)({
  borderRadius: '5px'
});

const CardContentStyle = styled(CardContent)({
  padding: '0px 16px',
  paddingBottom: '0px !important'
})

const TypographyStyle = styled(Typography)({
  display: 'flex',
  fontSize: '0.9rem',
  color: '#000',
  padding: '10px 0'
})

const links = [
  {
    href: 'https://drebest-agency.larksuite.com/wiki/wikushbyIX4TpOzmYuxGIp7RPVc',
    title: 'Guide to making money Affiliate',
    target: '_blank'
  },
  {
    href: `${process.env.REACT_APP_URL}/user_commissions`,
    title: 'Your bank info',
    target: '_self'
  },
]

const DashboardDefault = (props) => {
  const year = new Date().getFullYear()
  const [commissionData, setCommissionData] = useState({})
  const [daterange, setDaterange] = React.useState([new Date(), new Date()])

  useEffect(() => {
    const date_range = JSON.stringify([moment(daterange[0]).format('YYYY-MM-DD'), moment(daterange[1]).format('YYYY-MM-DD')])
    fetchCommission({date_range})
  }, [])

  const fetchCommission = (query={}) => {
    request.get('/api/v1/commissions', query).then(res => {
      if(res.data.data){
        setCommissionData(res.data.data)
      }
    }).catch(err => toastr.error(err))
  }

  return(
    <Page title={props.notification?.count > 0 ? `(${props.notification?.count}) Dashboard | Drebest CRM` : 'Dashboard | Drebest CRM'}>
      <Container>
        <Stack sx={{display: 'flex', alignItems: 'center', flexFlow: 'row', justifyContent: 'space-between'}}>
          <Box>
            <Typography variant="h4">
              Affiliate Money
            </Typography>
          </Box>
          <DateRangePicker
            initialSettings={{
              startDate: new Date(),
              minYear: 1901,
              autoApply: true,
              locale: {
                format: "DD/MM/YYYY"
              },
              ranges: {
                'Today': [new Date(), new Date()],
                'Yesterday': [new Date(moment().subtract(1, "days")), new Date(moment().subtract(1, "days"))],
                'Last 7 Days': [new Date(moment().subtract(6, "days")), new Date(moment())],
                'Last 30 Days': [new Date(moment().subtract(29, "days")), new Date(moment())],
                'This Month': [new Date(moment().startOf("month")), new Date(moment().endOf("month"))],
                'Last Month': [new Date(moment().subtract(1, "month").startOf("month")), new Date(moment().subtract(1, "month").endOf("month"))]
              },
              showCustomRangeLabel: true,
              alwaysShowCalendars: true,
              opens: 'left',
            }}
            locale={{ format: "DD/MM/YYYY" }}
            onApply={(event, picker) => {
              const startDate = new Date(picker.startDate)
              const endDate = new Date(picker.endDate)
              const date_range = JSON.stringify([moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")])
              const _date = [startDate, endDate]
              setDaterange(_date)
              fetchCommission({date_range})
            }}
          >
            <div style={{
              width: 240,
              border: '1px solid #ccc',
              height: 44,
              display: 'flex',
              alignItems: 'center',
              padding: '0px 8px',
              borderRadius: 8,
              cursor: 'pointer',
              justifyContent: 'space-between'
            }}>
              <div className="d-flex" style={{ gap: 8 }}>
                <IconCalendarEvent size={20} color="#666666" />
                {daterange.length > 0 ? <span style={{ color: '#333333', fontSize: 14}}>{moment(daterange[0]).format('DD/MM/YYYY')} ~ {moment(daterange[1]).format('DD/MM/YYYY')}</span> : <span style={{ color: '#AAAAAA', fontSize: 14 }}>Select Date</span>}
              </div>
              {daterange.length > 0 && 
                <IconX 
                  size={16} 
                  color="#444444" 
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    setDaterange([])
                    fetchCommission({})
                  }}
                />
              }
            </div>
          </DateRangePicker>
        </Stack>
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} sm={12} lg={4}>
            <CardStyle style={{ height: 216, background: '#BEEBFA' }}>
              <CardContentStyle>
                <TypographyStyle style={{ fontWeight: 'bold' }}>
                  Affiliate Link
                </TypographyStyle>
                <div className="d-flex justify-content-between" style={{ padding: 2, background: '#fff', borderRadius: 12 }}>
                  <div className="d-flex align-items-center" style={{ gap: 6, margin: '0px 6px' }}>
                    <IconLink size={16} color="#000" strokeWidth={2} />
                    <span style={{ fontSize: 13 }}>{process.env.REACT_APP_URL_NO_PROTOCOL}/s/{props.user?.aff_code || ''}</span>
                  </div>
                  <div 
                    className="d-flex align-items-center cursor-pointer" 
                    style={{ padding: '8px 16px', fontSize: 13, color: '#FFF', fontWeight: 'bold', borderRadius: 12, background: '#182D43' }}
                    onClick={() => {
                      navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/s/${props.user?.aff_code || ""}`)
                      toastr.success('Copied')
                    }}
                  >
                    Copy
                  </div>
                </div>
                <div className="mt-4">
                  <Box fontWeight="bold" fontSize={15}>
                    Link Options
                  </Box>
                  {links.map((link, index) => (
                    <Box key={index} display="flex" alignItems={"center"} style={{ gap: 6 }} fontSize={14} className="mt-2">
                      <IconLink size={16} color="#000" strokeWidth={2} />
                      <a href={link.href} target={link.target}>{link.title}</a>
                    </Box>
                  ))}
                </div>
              </CardContentStyle>
            </CardStyle>
          </Grid>
          <Grid item xs={12} sm={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <CardStyle>
                  <CardContentStyle>
                    <TypographyStyle>
                      Number click on link
                    </TypographyStyle>
                    <div style={{display: 'flex', padding: '0.7rem 0'}}>
                      <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, marginRight: '1.5rem'}}>
                        {commissionData?.number_click || 0}
                      </Typography>
                    </div>
                  </CardContentStyle>
                </CardStyle>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CardStyle>
                  <CardContentStyle>
                    <TypographyStyle>
                      Create account by link
                    </TypographyStyle>
                    <div style={{display: 'flex', padding: '0.7rem 0'}}>
                      <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, marginRight: '1.5rem'}}>
                        {commissionData?.create_account_by_link || 0}
                      </Typography>
                    </div>
                  </CardContentStyle>
                </CardStyle>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CardStyle>
                  <CardContentStyle>
                    <TypographyStyle>
                      Commission In Period
                    </TypographyStyle>
                    <div style={{display: 'flex', padding: '0.7rem 0'}}>
                      <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, marginRight: '1.5rem'}}>
                        {`$${commissionData?.commission_in_period || 0}`}
                      </Typography>
                    </div>
                  </CardContentStyle>
                </CardStyle>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CardStyle>
                  <CardContentStyle>
                    <TypographyStyle>
                      Total commissions
                    </TypographyStyle>
                    <div style={{display: 'flex', padding: '0.7rem 0'}}>
                      <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, marginRight: '1.5rem'}}>
                        {`$${commissionData?.total_commissions || 0}`}
                      </Typography>
                    </div>
                  </CardContentStyle>
                </CardStyle>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CardStyle>
                  <CardContentStyle>
                    <TypographyStyle>
                      Total paid amount
                    </TypographyStyle>
                    <div style={{display: 'flex', padding: '0.7rem 0'}}>
                      <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, marginRight: '1.5rem'}}>
                        {`$${commissionData?.total_paid_amount || 0}`}
                      </Typography>
                    </div>
                  </CardContentStyle>
                </CardStyle>
              </Grid>
              <Grid item xs={6} sm={4}>
                <CardStyle>
                  <CardContentStyle>
                    <TypographyStyle>
                      Total unpaid amount
                    </TypographyStyle>
                    <div style={{display: 'flex', padding: '0.7rem 0'}}>
                      <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, marginRight: '1.5rem'}}>
                        {`$${commissionData?.total_unpaid_amount || 0}`}
                      </Typography>
                    </div>
                  </CardContentStyle>
                </CardStyle>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid container style={{marginTop: '0.5rem', marginBottom: '0.7rem'}} spacing={2}>
          <Grid item xs={12} sm={8}>
            <AppWebsiteVisits
              title="Demo chart (updating...)"
              subheader="(+43%) than last year"
              chartLabels={[
                `01/01/${year}`,
                `02/01/${year}`,
                `03/01/${year}`,
                `04/01/${year}`,
                `05/01/${year}`,
                `06/01/${year}`,
                `07/01/${year}`,
                `08/01/${year}`,
                `09/01/${year}`,
                `10/01/${year}`,
                `11/01/${year}`,
                `12/01/${year}`,
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 20],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 33],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 29],
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardStyle>
              <CardContentStyle>
                <Typography style={{padding: '1.5rem 0', fontSize: '1.1rem', fontWeight: 700}}>
                  {`Commission from accounts (${commissionData?.comission_accounts?.length || 0})`}
                </Typography>
                <TableContainer style={{ maxHeight: 'calc(100vh - 345px)' }}>
                  <Table>
                    <TableBody>
                      { commissionData?.comission_accounts?.map((acc, index) => 
                        <TableRow key={index}>
                          <TableCell sx={{padding: '16px 10px', textAlign: 'center'}}>
                            <Avatar />
                          </TableCell>
                          <TableCell sx={{padding: '16px 10px'}}>
                            <Typography style={{fontSize: '0.9rem' , fontWeight: 700 }}>{acc.fullname}</Typography>
                            <Typography style={{fontSize: '0.8rem', color: '#727272'}}>{acc.email}</Typography>
                          </TableCell>
                          <TableCell sx={{padding: '16px 10px', textAlign: 'center'}}>
                            <Typography style={{fontSize: '1.2rem' , fontWeight: 700 }}>{`$${acc.amount}`}</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContentStyle>
            </CardStyle>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

function mapStateToProps(state) {
  const { authentication, notification } = state;
  const { user } = authentication;
  return {
    user, notification
  };
}

export default connect(mapStateToProps)(DashboardDefault)
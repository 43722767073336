import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, toastr } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';

const fields = [
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => item?.team_id ? `Team_${item?.team_id?.team_id}` : ''
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email Address',
    disabled: true,
  },
  {
    type: 'text',
    name: 'groups',
    label: 'User - Role',
    disabled: true,
    renderValue: (item) => item?.groups?.length > 0 ? item?.groups.map(e => e.name).join(', ') : ''
  },
  {
    type: 'text',
    name: 'fullname',
    label: 'User - Fullname',
  },
  {
    type: 'text',
    name: 'shortname',
    label: 'User - Shortname',
    disabled: true
  },
  {
    type: 'text',
    name: 'facebook_profile_name',
    label: 'User - FB Profile Name',
  },
  {
    type: 'text',
    name: 'facebook_link',
    label: 'User - FB Link',
  },
  {
    type: 'text',
    name: 'phone',
    label: 'User - Phone number',
  },
]

class TeamUserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'view'
    };
  }

  handleSave = (ctx, formState) => {
    // console.log(formState)
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/team_user/${formState.values.uid}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view' })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  render() {
    const { mode } = this.state;
    return (
      <ResourceForm
        mode={mode}
        fields={fields}
        route="team_user"
        onLoadData={(id) => {
          return new Promise((resolve) => {
            request.get(`/api/v1/team_user/${id}`).then(res => {
              if (res.data.success) {
                resolve(res.data.data)
              } else {
                resolve(false)
              }
            }, err => {
              resolve(false)
            })
          });
        }}
        title="Team User Detail"
        actionBack={() => this.props.navigate('/team_user')}
        {...this.props}
        actions={[
          {
            text: 'Edit',
            variant: 'contained',
            color: 'primary',
            action: () => this.setState({ mode: 'edit' }),
            visible: mode == 'view'
          },
          {
            text: 'Save',
            variant: 'contained',
            color: 'primary',
            action: (ctx, formState) => this.handleSave(ctx, formState),
            visible: mode == 'edit'
          },
          {
            text: 'Cancel',
            variant: 'outlined',
            color: 'error',
            action: (ctx, formState) => this.handleCancel(ctx),
            visible: mode == 'edit'
          },
        ]}
      />
    )
  }
}

export default withRouter(TeamUserDetail);
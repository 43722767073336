


class NavigationService {
    constructor() {
      this._navigate = null;
    }
  
    set navigate(nav) {
      this._navigate = nav;
    }
  
    get navigate() {
      return this._navigate;
    }
  }
  
  const navigationService = new NavigationService();
  
  export default navigationService;
import {
  TextField, Box, FormControlLabel, Radio, Checkbox, RadioGroup, Button
} from '@mui/material';
import { request } from '../_services_';
import { handleInputChange, loadOptions, customStyles, disabledStyles, makeId, errorStyles } from '../utils';
import { CustomNumberFormat } from '../components';
import AsyncSelect from 'react-select/async';
import { IconTrash } from '@tabler/icons';
import { NumericString } from 'src/components/CustomNumberFormat';
import { validate } from 'validate.js';

const disableButton = (field, values) => {
  const keys = field.keys.map(e => e.key)
  var sum_errors = 0
  keys.map(key => {
    if (values[values.length - 1]['_errors'] || !values[values.length - 1][key]) {
      sum_errors += 1
    }
  })
  if (sum_errors == 0) {
    return false
  }
  return true
}

export const checkVisible = (field, values) => {
  if (field.has_condition == 1) {
    const conditions = field.condition
    const key = conditions[0]
    const value = conditions[1]
    if (values[key] == value) {
      return true
    }
    return false
  }
  return true
}

export const checkRequired = (field, values) => {
  if (field.required == 1) {
    return true
  } else if (field.required instanceof Array) {
    const condition = field.required
    const key = condition[0]
    const value = condition[1]
    if (values[key] == value) {
      return true
    }
    return false
  }
  return false
}

const onChange = (field, value, handleChange, submitData) => {
  const e1 = {
    target: {
      name: field.key,
      value
    }
  }
  handleChange(e1)
  const extra_field = Object.assign([], submitData.values.extra_field)
  const obj = extra_field.find(e => e.key == field.key)
  if (obj) {
    obj['value'] = value
  } else {
    extra_field.push({...field, value: value})
  }
  const event = {
    target: {
      name: 'extra_field',
      value: extra_field
    }
  }
  handleChange(event)
}

export const schemaArray = (field) => {
  const schema = {}
  const keys = field.keys
  keys.map(f => {
    schema[f.key] = {
      presence: {allowEmpty: false, message: '^Required'}
    }
    if (f.type == 'number') {
      schema[f.key]['numericality'] = {
        greaterThan: 0,
        message: '^Value must be positive'
      }
    }
  })
  return schema
}

export const renderExtraField = (field, handleChange, submitData, classes, disabled) => {
  if (field.type == 'text' || field.type == 'number' || field.type == 'textarea') {
    const inputProps = {
      classes: {
        notchedOutline: 'notchedOutline'
      }
    }
    if (disabled) {
      inputProps['classes']['disabled'] = classes.input
    }
    if (field.type == 'number') {
      inputProps['inputComponent'] = CustomNumberFormat
    }
    if (!checkVisible(field, submitData.values)) {
      return null
    }
    const keys = Object.keys(submitData.values)
    if (!keys.includes(field.key)) {
      onChange(field, "", handleChange, submitData) 
    }
    return (
      <div className='mt-2' key={field.key}>
        <Box fontSize={15} fontWeight='bold'>
          <span dangerouslySetInnerHTML={{ __html: field.label }} /> {checkRequired(field, submitData.values) && <span style={{ color: 'red' }}>*</span>}
        </Box>
        <TextField
          disabled={disabled}
          fullWidth
          margin='dense'
          multiline={field.type == 'textarea' ? true : false}
          maxRows={3}
          name={field.key}
          placeholder={field.placeholder}
          onChange={(e) => {
            onChange(field, e.target.value, handleChange, submitData)
          }}
          value={submitData.values[field.key] || ""}
          variant="outlined"
          InputProps={inputProps}
          error={submitData.errors[field.key] && !disabled ? true : false}
          helperText={submitData.errors[field.key] && !disabled ? submitData.errors[field.key][0] : ''}
        />
      </div>
    )
  } else if (field.type == 'select') {
    if (!checkVisible(field, submitData.values)) {
      return null
    }
    const keys = Object.keys(submitData.values)
    if (!keys.includes(field.key)) {
      onChange(field, null, handleChange, submitData) 
    }
    return (
      <div className='mt-2' key={field.key}>
        <Box fontSize={15} fontWeight='bold'>
          <span dangerouslySetInnerHTML={{ __html: field.label }} /> {checkRequired(field, submitData.values) && <span style={{ color: 'red' }}>*</span>}
        </Box>
        <AsyncSelect
          isDisabled={disabled}
          isClearable
          className="MuiFormControl-marginDense"
          cacheOptions
          isMulti={field.isMulti}
          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
            return new Promise(resolve => {
              if (field.route) {
                const query = field.query ? {...field.query, search: inputValue, type: 'search'} : {search: inputValue, type: 'search'}
                request.get(field.route, query).then(res => {
                  if (res.data.success) {
                    resolve(res.data.data.items)
                  }
                })
              } else if (field.options?.length > 0) {
                const result = field.options.filter(e => e.title.toLowerCase().includes(inputValue.toLowerCase()))
                resolve(result)
              } else {
                resolve([])
              }
            })
          })}
          defaultOptions
          onInputChange={handleInputChange}
          isSearchable
          name={field.key}
          onChange={(value) => {
            if (field.isMulti) {
              const values = value?.map(e => ({...e, row_id: makeId(20)}))
              onChange(field, values, handleChange, submitData)
            } else {
              onChange(field, value, handleChange, submitData)
            }
          }}
          menuPortalTarget={document.body}
          getOptionLabel={({ title }) => title}
          getOptionValue={({ id }) => id}
          valueKey={"id"}
          value={submitData?.values[field.key] || null}
          styles={disabled ? disabledStyles : (submitData.errors[field.key] ? errorStyles : customStyles)}
        />
        {submitData.errors[field.key] && !disabled && <span className='field-error'>{submitData.errors[field.key][0]}</span>}
      </div>
    )
  } else if (field.type == 'checkbox') {
    if (!checkVisible(field, submitData.values)) {
      return null
    }
    const keys = Object.keys(submitData.values)
    if (!keys.includes(field.key)) {
      onChange(field, false, handleChange, submitData) 
    }
    return (
      <div style={{ marginTop: 10 }}>
        <FormControlLabel
          control={
            <Checkbox 
              disabled={disabled}
              checked={submitData.values[field.key]}
              onChange={e => {
                onChange(field, e.target.checked, handleChange, submitData)
              }}
            />
          }
          label={field.label}
        />
      </div>
    )
  } else if (field.type == 'radio') {
    if (!checkVisible(field, submitData.values)) {
      return null
    }
    if (!submitData.values[field.key]) {
      onChange(field, field.options[0].key, handleChange, submitData)
    }
    return (
      <div className='mt-2'>
        <Box fontSize={15} fontWeight="bold"><span dangerouslySetInnerHTML={{ __html: field.label }} /> {checkRequired(field, submitData.values) && <span style={{ color: 'red' }}>*</span>}</Box>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={field.key}
          value={submitData.values[field.key]}
          onChange={(e) => {
            onChange(field, e.target.value, handleChange, submitData)
          }}
          row
        >
          {field.options.map(option => (
            <FormControlLabel disabled={disabled} value={option.key} key={option.key} control={<Radio />} label={option.label} />
          ))}
        </RadioGroup>
      </div>
    )
  } else if (field.type == 'array') {
    if (!checkVisible(field, submitData.values)) {
      return null
    }
    const schema = schemaArray(field)
    if (!submitData.values[field.key] || submitData.values[field.key]?.length == 0) {
      onChange(field, [{row_id: makeId(12)}], handleChange, submitData)
    }
    
    return (
      <div className='mt-2'>
        <Box fontSize={15} fontWeight="bold"><span dangerouslySetInnerHTML={{ __html: field.label }} /> <span style={{ color: 'red' }}>*</span></Box>
        {submitData.values[field.key]?.map(item => (
          <div className="d-flex" key={item.id}>
            <div className="d-flex align-items-center" style={{ gap: 5, width: disabled ? '100%' : '94%' }}>
              {field.keys.map((f, index) => {
                if (f.type == 'text' || f.type == 'number' || f.type == 'textarea') {
                  const inputProps = {
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }
                  if (f.type == 'number') {
                    inputProps['inputComponent'] = CustomNumberFormat    
                  }
                  if (f.numericString == 1) {
                    inputProps['inputComponent'] = NumericString
                  }
                  return (
                    <div style={f.width ? { width: f.width } : {}} className={f.width ? '' : 'flex-1'} key={f.key}>
                      <TextField
                        fullWidth
                        margin='dense'
                        multiline={f.type == 'textarea' ? true : false}
                        maxRows={3}
                        name={f.key}
                        placeholder={f.label}
                        onChange={(e) => {
                          const values = Object.assign([], submitData.values[field.key])
                          const f_value = values.find(e => e.row_id == item.row_id)
                          f_value[f.key] = e.target.value
                          const errors = validate(f_value, schema)
                          f_value['_errors'] = errors
                          onChange(field, values, handleChange, submitData)
                        }}
                        value={item[f.key] || ""}
                        variant="outlined"
                        InputProps={inputProps}
                        error={item._errors && item._errors[f.key] ? true : false}
                        helperText={item._errors && item._errors[f.key] ? item._errors[f.key][0] : ''}
                      />
                    </div>
                  )
                } else if (f.type == 'select') {
                  return (
                    <div style={f.width ? { width: f.width } : {}} className={f.width ? '' : 'flex-1'} key={f.key}>
                      <AsyncSelect
                        className="MuiFormControl-marginDense"
                        cacheOptions
                        isClearable
                        placeholder={f.label}
                        loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                          return new Promise(resolve => {
                            const exclude_ids = submitData.values[field.key]?.map(e => e[f.key]?.id || 0) || []
                            if (f.route) {
                              const query = f.query ? {...f.query, search: inputValue, type: 'search'} : {search: inputValue, type: 'search'}
                              request.get(f.route, {...query, exclude_ids: JSON.stringify(exclude_ids)}).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                }
                              })
                            } else if (f.options?.length > 0) {
                              const result = f.options.filter(e => !exclude_ids.includes(e.id)).filter(e => e.title.toLowerCase().includes(inputValue.toLowerCase()))
                              resolve(result)
                            } else {
                              resolve([])
                            }
                          })
                        })}
                        defaultOptions
                        onInputChange={handleInputChange}
                        isSearchable
                        name={f.key}
                        onChange={(value) => {
                          const values = Object.assign([], submitData.values[field.key])
                          const f_value = values.find(e => e.row_id == item.row_id)
                          f_value[f.key] = value
                          const errors = validate(f_value, schema)
                          f_value['_errors'] = errors
                          onChange(field, values, handleChange, submitData)
                        }}
                        menuPortalTarget={document.body}
                        getOptionLabel={({ title }) => title}
                        getOptionValue={({ id }) => id}
                        valueKey={"id"}
                        value={item[f.key] || null}
                        styles={item._errors && item._errors[f.key] ? errorStyles : customStyles}
                      />
                      {item._errors && item._errors[f.key] && <span className='field-error'>{item._errors[f.key][0]}</span>}
                    </div>
                  )
                }
              })}
            </div>
            <div className="d-flex align-items-center justify-content-center" style={{ width: '6%' }}>
              <div 
                className="d-flex align-items-center justify-content-center cursor-pointer" 
                style={{ padding: 4, border: '1px solid red', borderRadius: 4 }}
                onClick={() => {
                  const values = Object.assign([], submitData.values[field.key])
                  const data_values = values.filter(e => e.row_id !== item.row_id)
                  if (data_values.length == 0) {
                    data_values.push({row_id: makeId(12)})
                  } else {
                    const last_element = data_values.pop()
                    const keys = Object.keys(last_element).filter(key => !['_errors', 'row_id'].includes(key))
                    const replace_element = {row_id: makeId(12)}
                    keys.map(key => {
                      replace_element[key] = last_element[key]
                    })
                    data_values.push(replace_element) 
                  }
                  const event = {
                    target: {
                      name: field.key,
                      value: data_values
                    }
                  }
                  handleChange(event)
                  onChange(field, data_values, handleChange, submitData)
                }}
              >
                <IconTrash color="red" width={16} height={16} />
              </div>
            </div>
          </div>
        ))}
        <Button 
          onClick={() => {
            const values = Object.assign([], submitData.values[field.key])
            values.push({row_id: makeId(12)})
            handleChange({ target: { name: field.key, value: values } })
            onChange(field, values, handleChange, submitData)
          }}
          variant="outlined"
          disabled={ 
            submitData.values[field.key] && submitData.values[field.key].length > 0 && disableButton(field, submitData.values[field.key])
          }
        >
          Add
        </Button>
      </div>
    )
    
  }
}
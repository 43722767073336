import React, { Component } from 'react';
import { ModalForm } from './ModalForm';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';


const useStyles = theme => ({
})



class ImagePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    previewImage = (url) => {
        ModalForm.instance.current.openForm({
            data: {},
            size: 'md',
            centered: true,
            title: 'Detail',
            customView: () => (
                <img loading="lazy" style={{ width: '100%', height: 'auto' }} src={url} />
            )
        })

    }

    render() {
        const { url } = this.props;
        return (
            <div onClick={(e) => {
                e.preventDefault()
                this.previewImage(url)
                e.stopPropagation()
            }}>
                <img
                    alt="" style={{ width: 'auto', height: 100, objectFit: "scale-down" }}
                    src={url} />
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user,
    };
}

const connectedForm = connect(mapStateToProps)(withStyles(useStyles)(ImagePreview));
export { connectedForm as ImagePreview }
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
// material
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import Logo from 'src/components/Logo';

const MailSuccess = () => {
  const navigation = useNavigate()
  const clientId = '598232846008-bj9bl5fr7au9ik9647hceenh88n4fe9b.apps.googleusercontent.com';
  const { state } = useLocation()

  const onLogoutSuccess = (res) => {
    gapi.auth2.getAuthInstance().disconnect();
    navigation('/login')
  };

  const onFailure = () => {
    // console.log('Handle failure cases');
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return(
    <div style={{ position: 'relative' }}>
      <div style={{ height: '30vh', backgroundColor: '#129535', textAlign: '-webkit-center ' }}>
        <Logo sx={{paddingTop: '2.5rem'}}/>
      </div>
      <div style={{ borderRadius: 10, transform: 'translate(-50%, -50%)', position: 'fixed', top: '40%', left: '50%', width: '25vw', height: '50vh', backgroundColor: '#fff', border: '1px solid #ccc' }}>
        <div className="mt-4 text-center">
          <h1 style={{ fontSize: 48, fontFamily: 'Public Sans', fontWeight: '400', margin: 2 }}>Welcome!</h1> 
          <img src="https://img.icons8.com/clouds/100/000000/handshake.png" width="125" height="120" style={{ display: 'block', border: '0px', margin: 'auto' }} />
        </div>
        <div className="mt-4" style={{ fontFamily: 'Public Sans', fontSize: 16, padding: '10px 30px', textAlign: 'center' }}>
          We're excited to have you get started. First, you need to confirm your account. <br />
          We were sent you a verify email to {state.email}. 
          Please check your inbox and active your account.
        </div>
        <div style={{textAlign: 'center'}}>
          <Button onClick={signOut}>Back to Login</Button>
        </div>
      </div>
    </div>
  )
}

export default MailSuccess;
import PropTypes from 'prop-types';
import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import clockFill from '@iconify/icons-eva/clock-fill';
// material
import {
  Box,
  Typography,
  ListItemText,
  ListItemButton,
} from '@mui/material';
// components
import CircleIcon from '@mui/icons-material/Circle';

function renderContent(notification, read, readAll) {
    const title = (
    <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between', position: 'relative'}}>
        <div>
            <Typography variant="subtitle2" className='line-clamp-1'>
                {notification.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <div className='line-clamp-2' dangerouslySetInnerHTML={{ __html: notification.content }}/>
            </Typography>
        </div>
        {!readAll && !read && 
          <div style={{ position: 'absolute', top: '50%', right: -20, transform: 'translateX(-50%)' }}>
            <CircleIcon style={{  }} sx={{color: 'dodgerblue', fontSize: '15px'}} />
          </div>
        }
    </div>
    );
    return {
    title
    };
}

NotificationItem.propTypes = {
    notification: PropTypes.object.isRequired
};

export default function NotificationItem({ notification, clickItem, props, readAll }) {
    const [read, setRead] = useState(notification.current_user_read)
    const { title } = renderContent(notification, read, readAll);
    
    return (
    <ListItemButton
      disableGutters
      onClick={ () => clickItem(notification, read, setRead)}
      sx={{ py: 1.5, px: 2.5, mt: '1px', borderBottom: '1px solid #ECECEC' }}
    >
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled'
              }}
            >
              <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
              {formatDistanceToNow(new Date(notification.create_date))}
            </Typography>
          }
        />
    </ListItemButton>
    );
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResourceList, DrawerForm, ModalForm, NumericString } from '../../../components';
import { request } from '../../../_services_';
import { toastr } from '../../../_helpers_';
import { fields, formFields } from './constant'
import AddIcon from '@mui/icons-material/Add'
import { withStyles } from '@mui/styles';
import { Box, TextField, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import AsyncSelect from 'react-select/async'
import { loadOptions, handleInputChange, customStyles, errorStyles } from '../../../utils';

const useStyles = theme => ({
  input: {
    "& input.Mui-disabled": {
      '-webkit-text-fill-color': 'black',
    }
  },
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      backgroundColor: "#ECECEC"
    }
  }
})

class ReceivingCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mode: 'view',
      objectId: null
    }
  }

  newAccount = (ctx) => {
    const { classes, user } = this.props
    if (user) {
      ModalForm.instance.current.openForm({
        title: "New Account",
        data: {
          user: user.team_id ? `Team_${user.team_id.team_id} ${user.shortname}` : user.shortname,
          account_type: 'bank_vnd'
        },
        customView: (submitData, handleChange) => (
          <div className='p-2'>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                User
              </Box>        
              <TextField 
                fullWidth
                margin='dense'
                disabled
                name="user"
                value={submitData.values.user}
                variant="outlined"
                className={classes.root}
                InputProps={{
                  classes: {
                    notchedOutline: 'notchedOutline',
                    disabled: classes.input
                  }
                }}
              />
            </div>
            <div className='mt-2'>
              <Box fontSize={15} fontWeight="bold">Account Type</Box>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="account_type"
                value={submitData.values.account_type}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="paypal" control={<Radio />} label="Paypal" />
                <FormControlLabel value="po" control={<Radio />} label="Payoneer" />
                <FormControlLabel value="bank_vnd" control={<Radio />} label="Bank VND" />
              </RadioGroup>
            </div>
            {submitData.values.account_type == 'bank_vnd' &&
            <>
              <div className='mt-2'>
                <Box fontSize={15} fontWeight='bold'>
                  Bank Name
                </Box>
                <AsyncSelect
                  className="MuiFormControl-marginDense"
                  cacheOptions
                  loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                    return new Promise(resolve => {
                      request.get('/api/v1/banks', {name: inputValue}).then(res => {
                        if (res.data.success) {
                          resolve(res.data.data.items)
                        }
                      })
                    })
                  })}
                  defaultOptions
                  onInputChange={handleInputChange}
                  isSearchable
                  name="bank_id"
                  onChange={(value) => {
                    var e = {
                      target: {
                        name: "bank_id",
                        value
                      },
                    };
                    handleChange(e)
                  }}
                  placeholder={"Bank..."}
                  menuPortalTarget={document.body}
                  getOptionLabel={({ title }) => title}
                  getOptionValue={({ id }) => id}
                  valueKey={"id"}
                  value={submitData?.values.bank_id || null}
                  styles={submitData.errors?.bank_id ? errorStyles : customStyles}
                />
                {submitData.errors?.bank_id && <span style={{ color: 'red', fontSize: 11, marginLeft:10 }}>{submitData.errors.bank_id[0]}</span>}
              </div>
              <div className='mt-2'>
                <Box fontWeight="bold" fontSize={15}>
                  Account Holder
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  name="holder_name"
                  onChange={handleChange}
                  value={submitData.values.holder_name}
                  variant="outlined"
                  placeholder='Account Holder'
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  error={submitData.errors?.holder_name}
                  helperText={submitData.errors?.holder_name ? submitData.errors?.holder_name[0] : ''}
                />
              </div>
              <div className='mt-2'>
                <Box fontWeight="bold" fontSize={15}>
                  Bank Number
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  name="bank_number"
                  onChange={handleChange}
                  value={submitData.values.bank_number}
                  variant="outlined"
                  placeholder='Bank Number...'
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    },
                  }}
                  error={submitData.errors?.bank_number}
                  helperText={submitData.errors?.bank_number ? submitData.errors?.bank_number[0] : ''}
                />
              </div>
              <div className='mt-2'>
                <Box fontWeight="bold" fontSize={15}>
                  Bank Branch
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  name="bank_branch"
                  onChange={handleChange}
                  value={submitData.values.bank_branch}
                  variant="outlined"
                  placeholder='Bank Branch...'
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  error={submitData.errors?.bank_branch}
                  helperText={submitData.errors?.bank_branch ? submitData.errors?.bank_branch[0] : ''}
                />
              </div>
            </>
            }
            {submitData.values.account_type == 'po' && 
              <div className='mt-2'>
                <Box fontWeight="bold" fontSize={15}>
                  Payoneer Account
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  name="payoneer_account"
                  onChange={handleChange}
                  value={submitData.values.payoneer_account}
                  variant="outlined"
                  placeholder='Payoneer account'
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  error={submitData.errors?.payoneer_account}
                  helperText={submitData.errors?.payoneer_account ? submitData.errors?.payoneer_account[0] : ''}
                />
              </div>
            }
            {submitData.values.account_type == 'paypal' && 
              <div className='mt-2'>
                <Box fontWeight="bold" fontSize={15}>
                  Paypal Account
                </Box>
                <TextField
                  fullWidth
                  margin='dense'
                  name="paypal_account"
                  onChange={handleChange}
                  value={submitData.values.paypal_account}
                  variant="outlined"
                  placeholder='Paypal account'
                  InputProps={{
                    classes: {
                      notchedOutline: 'notchedOutline'
                    }
                  }}
                  error={submitData.errors?.paypal_account}
                  helperText={submitData.errors?.paypal_account ? submitData.errors?.paypal_account[0] : ''}
                />
              </div>
            }
          </div>
        ),
        multipleActions: true,
        actions: [
          {
            titleAction: 'Save',
            schema: (submitData) => {
              const schema = {
                account_type: { presence: { message: '^Required' } },
              }
              if (submitData.values.account_type === 'bank_vnd'){
                schema['bank_id'] = {
                  presence: { message: '^Required' }
                }
                schema['bank_number'] = {
                  presence: { message: '^Required' }
                }
                schema['bank_branch'] = {
                  presence: { message: '^Required' }
                }
                schema['holder_name'] = {
                  presence: { message: '^Required' }
                }
              } else if (submitData.values.account_type === 'po') {
                schema['payoneer_account'] = {
                  presence: { message: '^Required' },
                  email: {
                    message: "^Invalid email"
                  }
                }
              } else {
                schema['paypal_account'] = {
                  presence: { message: '^Required' },
                  email: {
                    message: "^Invalid email"
                  }
                }
              }
              return schema
            },
            onAction: (submitData) => {
              return new Promise((resolve) => {
                request.post('/api/v1/commission_accounts', submitData.values).then(res => {
                  if (res.data.success) {
                    toastr.success("Create account successful")
                    ctx.getListItems(ctx.state.searchQuery)
                    resolve(true);
                  } else {
                    toastr.error(res.data.msg)
                    resolve(false)
                  }
                }, err => {
                  toastr.error(err);
                  resolve(false)
                })
              })
            }
          },
          {
            titleAction: 'Save & Set Default',
            schema: (submitData) => {
              const schema = {
                account_type: { presence: { message: '^Required' } },
              }
              if (submitData.values.account_type === 'bank_vnd'){
                schema['bank_id'] = {
                  presence: { message: '^Required' }
                }
                schema['bank_number'] = {
                  presence: { message: '^Required' }
                }
                schema['bank_branch'] = {
                  presence: { message: '^Required' }
                }
                schema['holder_name'] = {
                  presence: { message: '^Required' }
                }
              } else if (submitData.values.account_type === 'po') {
                schema['payoneer_account'] = {
                  presence: { message: '^Required' },
                  email: {
                    message: "^Invalid email"
                  }
                }
              } else {
                schema['paypal_account'] = {
                  presence: { message: '^Required' },
                  email: {
                    message: "^Invalid email"
                  }
                }
              }
              return schema
            },
            onAction: (submitData) => {
              const query = {...submitData.values, default: 1}
              return new Promise((resolve) => {
                request.post('/api/v1/commission_accounts', query).then(res => {
                  if (res.data.success) {
                    toastr.success("Create account successful")
                    ctx.getListItems(ctx.state.searchQuery)
                    resolve(true);
                  } else {
                    toastr.error(res.data.msg)
                    resolve(false)
                  }
                }, err => {
                  toastr.error(err);
                  resolve(false)
                })
              })
            }
          }
        ]
      })
    }
  }

  handleMakeDefault = (ctx, formState) => {
    ModalForm.instance.current.openForm({
      title: "New Account",
      data: {
        id: formState.values.id
      },
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          Are you want to make default this account?
        </div>
      ),
      action: {
        titleAction: 'Apply',
        onAction: (submitData) => {
          const query = {
            make_default: 1
          }
          return new Promise((resolve) => {
            request.put(`/api/v1/commission_account/${submitData.values.id}`, query).then(res => {
              if (res.data.success) {
                const form = Object.assign({}, formState)
                form.changes = {}
                form.values = res.data.data
                ctx.setState({ formState: form })
                toastr.success("Update successful")
                this.setState({ mode: 'view', reload: true })
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error("There was an error updating data")
              resolve(false)
            })
          })
        }
      }
    })
  }

  handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/commission_account/${formState.values.id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        this.setState({ mode: 'view', reload: true })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  handleCancel = (ctx) => {
    this.setState({ mode: 'view' })
    ctx.handleCancel()
  }

  getRibbon = (item) => {
    if (item.default) {
      return {
        text: 'Default',
        textColor: '#FFF',
        bgColor: '#00AB55',
      }
    }
    return null
  }

  render() {
    const { open, mode, objectId } = this.state;
    const { navigate } = this.props;
    return (
      <>
        <ResourceList
          reload={this.state.reload}
          setReLoad={(bool) => this.setState({reload: bool})}
          rowActive={objectId}
          route="/api/v1/commission_accounts"
          titlePage="Drebest | Bank Info"
          title="2.3 Bank Info"
          fields={fields}
          valueKey="id"
          onClickRow={(item) => this.setState({ open: true, objectId: item.id })}
          actions={[
            {
              text: 'New Account',
              variant: 'contained',
              icon: <AddIcon />,
              action: (ctx) => this.newAccount(ctx),
              visible: true
            }
          ]}
        />
        <DrawerForm 
          fields={formFields}
          mode={mode}
          open={open}
          ribbon={(item) => this.getRibbon(item)}
          onClose={() => this.setState({ open: false, mode: 'view', objectId: null })}
          objectId={objectId}
          onLoadData={(id) => {
            return new Promise((resolve) => {
              request.get(`/api/v1/commission_account/${id}`).then(res => {
                if (res.data.success) {
                  resolve(res.data.data)
                } else {
                  resolve(false)
                }
              }, err => {
                resolve(false)
              })
            });
          }}
          title="Receiving commissions"
          actions={[
            {
              text: 'Make Default',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleMakeDefault(ctx, formState),
              visible: (item) => !item.default && mode == 'view'
            },
            {
              text: 'Edit',
              variant: 'contained',
              color: 'primary',
              action: () => this.setState({ mode: 'edit' }),
              visible: mode == 'view'
            },
            {
              text: 'Save',
              variant: 'contained',
              color: 'primary',
              action: (ctx, formState) => this.handleSave(ctx, formState),
              visible: mode == 'edit'
            },
            {
              text: 'Cancel',
              variant: 'outlined',
              color: 'error',
              action: (ctx, formState) => this.handleCancel(ctx),
              visible: mode == 'edit'
            },
          ]}
        />
      </>
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(withStyles(useStyles)(ReceivingCommission));
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, IconButton, List, Paper, Tooltip, Typography } from '@mui/material';
import { request } from '../../../_services_';
import { styled } from '@mui/styles';
import NotificationDetail from './NotificationDetail';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationItem from 'src/components/NotificationItem';
import { ModalForm } from '../../../components'

const InfiniteScrollBox = styled('div')({
    maxHeight: '70vh',
    overflow: 'auto',
    id: 'boxScroll',
});

const NotificationAll = (props) => {
    const [notificationsList, setNotificationsList] = useState([]);
    const [unRead, setUnRead] = useState(0)
    const [total, setTotal] = useState(0)
    const [loadPage, setLoadPage] = useState(0)
    const limit = 10
    const [readAll, setReadAll] = useState(false)
    const clickItem = (notification, read, setRead) => {
        if (read == false) {
            request.put('/api/v1/mark_as_read', {ids: [notification.id]}).then(res => {
                if (res.data.success) {
                    setRead(true)
                    const currentUnRead = unRead
                    setUnRead(currentUnRead - 1)
                }
            })
        }
        NotificationDetail(notification)
    }

    const handleMarkAllAsRead = () => {
        request.put('/api/v1/mark_as_read', {ids: 'all'}).then(res => {
        if (res.data.success) {
            setUnRead(0)
            setReadAll(true)
        }
        })
    };

    useEffect(() => {
        fetchNotifications()
    },[])

    const fetchNotifications = (query = {page: 1, limit: limit}) => {
        request.get('/api/v1/notification', query).then(res => {
            if (res.data.success) {
                let new_notifications = [...notificationsList].concat(res.data.data.items)
                setNotificationsList(new_notifications)
                setTotal(res.data.data.total)
                setUnRead(res.data.data.un_read)
                setLoadPage(loadPage + 1)
            }
        })
    }

    const handleScroll = (e) => {
        if (notificationsList.length < total) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            fetchNotifications({page: loadPage + 1, limit: limit})
        }
        }
    }

    return (
        <div className='box-noti' style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
            <ModalForm />
            <Paper sx={{ width: '45vw', boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography sx={{fontSize: '1.5rem', fontWeight: 'bold'}}>Notifications</Typography>
                        <Typography sx={{fontSize: '0.9rem', color: 'dimgrey'}}>
                            {`You have ${unRead} messages unread.`}
                        </Typography>
                    </Box>

                    {unRead > 0 && (
                        <Tooltip title=" Mark all as read">
                        <IconButton color="primary" onClick={handleMarkAllAsRead}>
                            <DoneAllIcon width={20} height={20} />
                        </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <InfiniteScrollBox onScroll={handleScroll}>
                    <List
                        disablePadding
                        id='list'
                    >
                        {notificationsList.map((notification, index) => (
                            <NotificationItem key={index} notification={notification} clickItem={clickItem} props={props} readAll={readAll} />
                        ))}
                    </List>
                </InfiniteScrollBox>
            </Paper>
        </div>
    )
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user,
    };
}

export default connect(mapStateToProps)(NotificationAll)
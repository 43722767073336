import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';

const fields = [
  {
    type: 'text',
    name: 'username',
    label: 'Team_Seller Username',
    disabled: true,
    renderValue: (item) => item.team_id ? `Team_${item.team_id.team_id} ${item.shortname}` : ''
  },
  {
    type: 'text',
    name: 'facebook_link',
    label: 'Facebook Link',
  },
  {
    type: 'text',
    name: 'paypal_account',
    label: 'Paypal account',
    renderValue: (item) => item.paygate?.paypal_account,
    onChange: (event, ctx) => {
      const paygate = Object.assign({}, ctx.state.formState.values.paygate)
      paygate[event.target.name] = event.target.value
      const e = {
        target: {
          name: 'paygate',
          value: paygate
        }
      }
      ctx.handleChange(e)
    }
  },
  {
    type: 'text',
    name: 'payoneer_account',
    label: 'Payoneer account',
    renderValue: (item) => item.paygate?.payoneer_account,
    onChange: (event, ctx) => {
      const paygate = Object.assign({}, ctx.state.formState.values.paygate)
      paygate[event.target.name] = event.target.value
      const e = {
        target: {
          name: 'paygate',
          value: paygate
        }
      }
      ctx.handleChange(e)
    }
  },
  // {
  //   type: 'text',
  //   name: 'bank_name',
  //   label: 'Bank name',
  //   renderValue: (item) => item.paygate?.bank_name,
  //   onChange: (event, ctx) => {
  //     const paygate = Object.assign({}, ctx.state.formState.values.paygate)
  //     paygate[event.target.name] = event.target.value
  //     const e = {
  //       target: {
  //         name: 'paygate',
  //         value: paygate
  //       }
  //     }
  //     ctx.handleChange(e)
  //   }
  // },
  {
    name: 'bank_id',
    label: 'Bank Vietnam',
    type: 'select_create',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/banks', { search: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    getOptionValue: (item) => item.bank_id,
    getOptionLabel: (item) => `${item.name}`,
    onCreateOption: (inputValue, ctx) => {
      {
        request.post('/api/v1/banks', { name: inputValue }).then(res => {
          if (res.data.success) {
            var e = {
              target: {
                name: "bank_id",
                value: res.data.data
              },
            };
            ctx.handleChange(e)
          } else {
            toastr.error(res.data.msg)
          }
        }, err => toastr.error(err))
      }
    }
  },
  {
    type: 'text',
    name: 'bank_number',
    label: 'Bank Acc Number',
    renderValue: (item) => item.paygate?.bank_number,
    onChange: (event, ctx) => {
      const paygate = Object.assign({}, ctx.state.formState.values.paygate)
      paygate[event.target.name] = event.target.value
      const e = {
        target: {
          name: 'paygate',
          value: paygate
        }
      }
      ctx.handleChange(e)
    }
  },
  {
    type: 'text',
    name: 'bank_branch',
    label: 'Bank Branch',
    renderValue: (item) => item.paygate?.bank_branch,
    onChange: (event, ctx) => {
      const paygate = Object.assign({}, ctx.state.formState.values.paygate)
      paygate[event.target.name] = event.target.value
      const e = {
        target: {
          name: 'paygate',
          value: paygate
        }
      }
      ctx.handleChange(e)
    }
  },
  {
    type: 'text',
    name: 'holder_name',
    label: 'Account owner',
    renderValue: (item) => item.paygate?.holder_name,
    onChange: (event, ctx) => {
      const paygate = Object.assign({}, ctx.state.formState.values.paygate)
      paygate[event.target.name] = event.target.value
      const e = {
        target: {
          name: 'paygate',
          value: paygate
        }
      }
      ctx.handleChange(e)
    }
  },
]

const ReceivingCommissionForm = (props) => {
  const [mode, setMode] = useState('edit')

  const handleSave = (ctx, formState) => {
    request.put(`/api/v1/user_commission`, formState.changes).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  return (
    <ResourceForm
      mode={mode}
      fields={fields}
      route={'user_commission'}
      onLoadData={() => {
        return new Promise((resolve) => {
          request.get(`/api/v1/user_commission`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="2.3 Your account receiving commissions"
      actionBack={() => props.navigate('/user_commissions')}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(ReceivingCommissionForm))
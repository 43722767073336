import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';

const platform = [
  { value: 'google', label: 'Google'},
  { value: 'facebook', label: 'Facebook'},
  { value: 'tiktok', label: 'Tiktok'}
]

const fields = [
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id}`
  },
  {
    type: 'number',
    name: 'total_fund_to_now',
    label: 'Total Fund',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_topup_to_wallet',
    label: 'Sum Service A',
    disabled: true,
  },
  {
    type: 'number',
    name: 'wallet_a',
    label: 'Wallet Remaining',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_acc_rental_fee',
    label: 'Sum Service B',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_acc_creation_insurance_fee',
    label: 'Sum Service C',
    disabled: true
  },
  {
    type: 'number',
    name: 'total_acc_deposit',
    label: 'Sum Service D',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_others',
    label: 'O - Others',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_fund',
    label: 'Fund Remaining',
    disabled: true,
  },
  {
    type: 'text',
    name: 'platform',
    label: 'Platform',
    renderValue: (item) => platform.find(e => e.value == item.platform)?.label,
    disabled: true,
  },
  {
    type: 'text',
    name: 'product_url',
    label: 'Product Url',
    disabled: true,
  },
  {
    type: 'text',
    name: 'type_acc',
    label: 'Type Acc',
    disabled: true,
  }
]

class TeamSummaryDetail extends Component {

  render () {
    return (
      <ResourceForm 
        mode="view"
        fields={fields}
        route="team_summary"
        onLoadData={(id) => {
          return new Promise((resolve) => {
            request.get('/api/v1/team_summary').then(res => {
              if (res.data.success) {
                resolve({result: res.data.data.items[0]})
              } else {
                resolve(false)
              }
            }, err => {
              resolve(false)
            })
          });
        }}
        title="Team Summary Detail"
        actionBack={() => this.props.navigate('/team_summary')}
        {...this.props}
      />
    )
  }
}

export default withRouter(TeamSummaryDetail);
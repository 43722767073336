import * as React from 'react';
import { useState, useEffect } from 'react';
import { ModalForm, CustomNumberFormat } from '../../components'
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles'
// material
import {
  Card, Stack, Container, Typography,
  Grid, CardContent, FormControl, TextField,
  FormControlLabel, Button, Tooltip, Checkbox, 
  Box, Autocomplete, InputAdornment
} from '@mui/material';
// components
import Page from '../../components/Page';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { withRouter, toastr } from '../../_helpers_';
import { userConstants } from '../../_constants_';
import { request } from 'src/_services_';
import { showLoading, hideLoading } from '../../_actions_';
import { slugifyStr } from '../../utils';
import Dropzone from 'react-dropzone';
import userImg from '../../static/images/no-avatar.jpg';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import { NumericString } from 'src/components/CustomNumberFormat';
import validate from "validate.js";
// ----------------------------------------------------------------------

const LabelStyle = styled('label')({
  fontWeight: 700,
  marginBottom: '0rem'
});

const TextFieldStyle = styled(TextField)({
  '& .MuiOutlinedInput-input' : {
      height: '15px'
  },
  "& input.Mui-disabled": {
    '-webkit-text-fill-color': 'black',
    backgroundColor: "#e7e4e4",
    borderRadius: '8px'
  }
});

const FormControlStyle = styled(FormControl)({
  width: '100%'
});

const CardStyle = styled(Card)({
  borderRadius: '10px'
});

// ----------------------------------------------------------------------

const schemaProfile = {
  // fullname: {
  //   format: {
  //     pattern: /^[a-zA-Z0-9_ ]+$/,
  //     message: "^Shortname contain only latin"
  //   }
  // },
  facebook_link: {
    format: {
      pattern: /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i,
      message: "^Enter correct facebook link!"
    }
  },
  phone: {
    length: {
      minimum: 8,
      maximum: 15,
      message: '^Phone needs to have 8-15 digits.'
    },
    format: {
      pattern: /^\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      message: "^Not a valid phone number"
    },
  }
}

const Profile = (props) => {
  const user = JSON.parse(localStorage.getItem('user_drebest') || "{}")
  const [mode, setMode] = useState('view');
  const { public_id } = props?.match.params;
  const { navigate, classes, dispatch, type } = props;
  const [groups, setGroups] = useState([])
  const [mainMarket, setMainMarket] = useState([]);
  const [productType, setProductType] = useState([]);
  const [sources, setSources] = useState([])
  const [previewImg, setPreviewImg] = useState(null);
  const [formState, setFormState] = useState({
    values: {},
    changes: {}
  })
  const [formCached, setFormCache] = useState({
    values: {}
  })
  const [errs, setErrs] = useState({})

  useEffect(() => {
    request.get('/api/v1/groups', {limit: 100}).then(res => {
      if (res.data.success) {
        setGroups(res.data.data.items)
      }
    });

    request.get('/api/v1/main_market').then(res => {
      if (res.data.success) {
        setMainMarket(res.data.data.items)
      }
    });

    request.get('/api/v1/product_types').then(res => {
      if (res.data.success) {
        setProductType(res.data.data.items)
      }
    });

    request.get('/api/v1/sources').then(res => {
      if (res.data.success) {
        setSources(res.data.data.items)
      }
    })
  }, [])

  useEffect(() => {
    if(public_id){
      dispatch(showLoading())
      request.get(`/api/v1/user/${public_id}`, {}).then(res => {
        dispatch(hideLoading())
        if (res.data.success) {
          const form = Object.assign({}, formState)
          form['values'] = res.data.data.result
          setFormState(form)
        } else {
          toastr.error(res.data.msg)
        }
      }, err => {
        console.log(err)
      })
    }
  }, [public_id])

  const onDropImage = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      return
    }
    const file = accepted[0]
    request.post_form(`/api/v1/upload_image`, {file}).then(res => {
      if (res.data.success) {
        request.put(`/api/v1/user/${formState.values.public_id}`, {avatar: `${process.env.REACT_APP_API_URL}/${res.data.data.url}`}).then(resp => {
          if (resp.data.success) {
            toastr.success('Update success')
            const form = Object.assign({}, formState)
            form['values']['avatar'] = `${process.env.REACT_APP_API_URL}/${res.data.data.url}`
            setFormState(form)
            changeCurrentUser(form.values)
          } else {
            toastr.error(resp.data.msg)
          }
        }, err => {
          toastr.error(err)
        })
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      if (err.response) {
        toastr.error(err.response.data.msg)
      } else {
        toastr.error(err)
      }
    })
  }

  const setupPreview = (file) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      setPreviewImg(e.target.result)
    }
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    const form = Object.assign({}, formState)
    form['values'][event.target.name] = event.target.value
    form['changes'][event.target.name] = event.target.value
    setFormState(form)
    let newErr = Object.assign({}, errs)
    delete newErr[event.target.name]
    setErrs(newErr)
  }

  const handleEdit = () => {
    const form = Object.assign({}, formCached)
    const allKeys = Object.keys(formState.values)
    allKeys.forEach(key => {
      form.values[key] = formState.values[key]
    })
    setFormCache(form)
    setMode('edit')
  }

  const changeCurrentUser = (data) => {
    const user = Object.assign({}, JSON.parse(localStorage.getItem('user_drebest') || {}))
    const allKeys = Object.keys(user)
    if (user.public_id == data.public_id) {
      allKeys.map(key => {
        if (key == 'groups') {
          user[key] = data[key].map(e => e.code)
        } else if (data[key] && data[key] !== user[key]) {
          user[key] = data[key]
        }
      })
      localStorage.setItem('user_drebest', JSON.stringify(user))
      dispatch({ type: userConstants.LOGIN_SUCCESS, user })
    }
  }

  const handleSave = () => {
    const query = {}
    const item = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'groups') {
        query[key] = formState.changes[key].map(e => e.code)
      }
      else if (key == 'company_id'){
        const companyKeys = Object.keys(formState.changes.company_id)
        companyKeys.map(cm_key => {
          if(typeof formState.changes.company_id[cm_key] == 'object' && formState.changes.company_id[cm_key] != null){
            if(cm_key == 'main_market_id'){
              item[cm_key] = formState.changes.company_id[cm_key]['id']
            } else {
              item[cm_key] = formState.changes.company_id[cm_key][cm_key]
            }
            query[key] = item
          } else if (cm_key == 'spent_per_month') {
            item[cm_key] = formState.changes.company_id[cm_key] || 0
            query[key] = item
          } else {
            item[cm_key] = formState.changes.company_id[cm_key]
            query[key] = item
          } 
        })
      } 
      else {
        query[key] = formState.changes[key]
      }
    })
    const errors = validate(query, schemaProfile)
    setErrs(errors)
    if (!errors) {
      request.put(`/api/v1/user/${formState.values.public_id}`, query).then(res => {
        if (res.data.success) {
          setMode('view')
          toastr.success('Update Success')
          changeCurrentUser(formState.values)
          const form = Object.assign({}, formState)
          Object.keys(form.changes).forEach(key => {
            delete form['changes'][key]
          })
          setFormState(form)
          setErrs({})
        } else {
          toastr.error(res.data.msg)
        }
      }, err => {
        toastr.error(err)
      })
    } else {
      toastr.error('Information invalid')
    }
  }
  const handleCancel = () => {
    const form = Object.assign({}, formState)
    const allKeys = Object.keys(formCached.values)
    allKeys.forEach(key => {
      form.values[key] = formCached.values[key]
    })
    Object.keys(form.changes).forEach(key => {
      delete form['changes'][key]
    })
    setFormState(form)
    setMode('view')
    setPreviewImg(null)
    setErrs({})
  }

  // const viewAvatar = () => {
  //   ImagePreview.instance.current.openImage({
  //     image: formState.values.avatar
  //   })
  // }

  const handleChangePassword = () => {
    ModalForm.instance.current.openForm({
      title: "Change Password",
      data: {},
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight='bold'>
              New password
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="password"
              type="password"
              autoComplete='none'
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              value={submitData.values.password}
              onChange={handleChange}
              placeholder="Password..."
              variant="outlined"
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <Box fontSize={15} fontWeight='bold'>
              Confirm password
            </Box>
            <TextField 
              fullWidth
              margin='dense'
              name="confirm_password"
              type="password"
              autoComplete='none'
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              value={submitData.values.confirm_password}
              onChange={handleChange}
              placeholder="Confirm password..."
              variant="outlined"
            />
          </div>
        </div>
      ),
      action: {
        titleAction: 'active',
        schema: {
          password: {
            presence: { allowEmpty: false, message: '^Required' }
          },
          confirm_password: {
            presence: { allowEmpty: false, message: '^Required' },
            equality: {
              attribute: 'password',
              message: '^Password does not match'
            }
          },
        },
        onAction: (submitData) => {
          return new Promise((resolve) => {
            const query = {
              password: submitData.values.password
            }
            request.put(`/api/v1/user/${public_id}`, query).then(res => {
              if (res.data.success) {
                toastr.success('Change password success')
                resolve(true)
              } else {
                toastr.error(res.data.msg)
                resolve(false)
              }
            }, err => {
              toastr.error(err)
              resolve(false)
            })
          })
        }
      }
    })
  }

  return (
    <Page title="Profile | Drebest CRM">
      <ModalForm />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Account Settings
          </Typography>
          <div style={{display: 'flex'}}>
            { mode == 'edit' ?
              <>
                <div style={{padding: '0px 5px'}}>
                  <Button
                    variant="contained"
                    onClick={handleCancel}
                    // startIcon={<LockOpenIcon />}
                  >
                    Cancel
                  </Button>
                </div>
                <div style={{padding: '0px 5px'}}>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    // startIcon={<LockOpenIcon />}
                  >
                    Save
                  </Button>
                </div>
              </> : 
              <>
                <div style={{padding: '0px 5px'}}>
                  <Button
                    variant="contained"
                    onClick={handleChangePassword}
                    startIcon={<LockOpenIcon />}
                  >
                    Change Password
                  </Button>
                </div>
                <div style={{padding: '0px 5px'}}>
                  <Button
                    variant="contained"
                    onClick={handleEdit}
                    startIcon={<EditIcon />}
                  >
                    Edit
                  </Button>
                </div>
              </> 
            }
          </div>
        </Stack>
        <Grid container spacing={2} style={{ marginTop: 1 }} >
          <Grid item xs={12} sm={4} >
            <Grid item xs={12} style={{marginBottom: '1rem'}}>
              <CardStyle>
                <CardContent style={{textAlign: '-webkit-center', alignItems: 'center'}}>
                  <div className='p-4 d-flex align-items-center justify-content-center flex-column' style={{ zIndex: 2 }}>
                    <div className='profile-avatar' 
                      style={{ width: 160, height: 160, backgroundColor: '#F5F5F5', zIndex: 2, borderRadius: '50%', position: 'relative' }}
                    >
                      <div style={{ 
                        backgroundImage: `url(${formState.values.avatar ?? userImg})`,
                        width: 160, height: 160, borderRadius: '50%',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        objectFit: 'cover',
                        }} 
                      />
                      <Dropzone
                        multiple={false}
                        accept="image/*"
                        onDrop={(accepted, rejected) => onDropImage(accepted, rejected)}
                      >
                        {({ getRootProps, getInputProps, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => (
                          <section className='add-image'>
                            <div
                              {...getRootProps()}
                              className='d-flex align-items-center justify-content-center'
                              style={{
                                borderRadius: '50%',
                                marginTop: '-35px',
                                marginRight: '-70px',
                                position: 'relative',
                                width: '40px',
                                height: '40px',
                                background: '#e0e0e0'}}
                            >
                              {/* <span className='text-action'>Change</span> */}
                              <input {...getInputProps()} />
                              <PhotoCameraIcon />
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="mt-3 text-center" style={{ width: '100%' }}>
                      <Tooltip title={formState.values.fullname} arrow placement='top'>
                        <h3 className='line-clamp-1' style={{fontSize: '1.2rem'}}>{formState.values.fullname}</h3>
                      </Tooltip>
                      <p>{formState.values.email}</p>
                    </div>
                  </div>
                </CardContent>
              </CardStyle>
            </Grid>
            <CardStyle>
              <div className='p-3'>
                <h6 className='font-weight-bold mb-0'>Access Rights</h6>
              </div>
              <hr className='m-0' />
              <div className='p-3 d-flex flex-column'>
                {groups?.map(group => (
                  <FormControlLabel
                    key={group.id}
                    control={
                      <Checkbox
                        color="primary"
                        name={'groups'}
                        disabled={mode === 'view' || !user.is_owner}
                        onChange={(event) => {
                          var arr = Object.assign([], formState.values['groups'])
                          if (event.target.checked) {
                            arr.push(group)
                          } else {
                            const indexItem = arr.findIndex(e => e.code == group.code)
                            if (indexItem > -1) {
                              arr.splice(indexItem, 1);
                            }
                          }
                          const e = {
                            target: {
                              name: 'groups',
                              value: arr
                            }
                          }
                          handleChange(e)
                        }}
                        checked={formState.values.groups ? (formState.values.groups.map(e => e.code).includes(group.code) ? true : false) : false}
                      />
                    }
                    label={group.name}
                  />
                ))}
              </div>
            </CardStyle>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid item xs={12} style={{marginBottom: '1rem'}}>
              <CardStyle>
                <Grid container>
                  <Grid item xs={12} sx={{borderBottom: '1px solid #e0e0e0'}}>
                    <Typography sx={{fontSize: '1rem', fontWeight: 700, padding: '1rem'}}>Account info</Typography>
                  </Grid>
                  <Grid container style={{padding: '1rem'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Fullname</LabelStyle>
                          <TextFieldStyle
                            disabled={mode === 'view'}
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            name="fullname"
                            value={formState.values.fullname || ''}
                            onChange={(e) => { 
                              handleChange({target: {name: 'fullname', value: e.target.value}})
                              handleChange({target: {name: 'shortname', value: slugifyStr(e.target.value)}})
                            }}
                          />
                        </FormControlStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>FB Link</LabelStyle>
                          <TextFieldStyle 
                            disabled={mode === 'view'}
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            name="facebook_link"
                            value={formState.values.facebook_link || ''}
                            onChange={handleChange}
                            error = {errs?.facebook_link}
                          />
                        </FormControlStyle>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{paddingTop: '5px'}}>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Shortname</LabelStyle>
                          <TextFieldStyle
                            disabled
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            name="shortname"
                            onChange={handleChange}
                            value={formState.values.shortname || ''}
                          />
                        </FormControlStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Phone</LabelStyle>
                          <TextFieldStyle
                            disabled={mode === 'view'}
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            name="phone"
                            value={formState.values.phone || ''}
                            onChange={handleChange}
                            InputProps={{inputComponent: NumericString}}
                            error = {errs?.phone}
                          />
                        </FormControlStyle>
                      </Grid>
                    </Grid>
                    <Grid container sx={{paddingTop: '5px'}}>
                      <Grid item xs={12}>
                        <FormControlStyle style={{ position: 'relative' }}>
                          <LabelStyle>Link Affiliate</LabelStyle>
                          <TextFieldStyle
                            disabled
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            name="link_affiliate"
                            value={`${process.env.REACT_APP_URL_NO_PROTOCOL}/s/${formState.values.aff_code}`}
                            InputProps={{
                              endAdornment: 
                                <InputAdornment position="end" sx={{margin: 0}}>
                                  <div 
                                    className='copy-btn'
                                    onClick={() => {
                                      navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/s/${formState.values.aff_code}`)
                                      toastr.success('Copied')
                                    }}
                                  >
                                    Copy
                                  </div>
                                </InputAdornment>
                            }}
                          />
                        </FormControlStyle>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardStyle>
            </Grid>
            
            <Grid item xs={12}  style={{marginBottom: '2rem'}}>
              <CardStyle>
                <Grid container>
                  <Grid item xs={12} sx={{borderBottom: '1px solid #e0e0e0'}}>
                    <Typography sx={{fontSize: '1rem', fontWeight: 700, padding: '1rem'}}>Company</Typography>
                  </Grid>
                  <Grid container style={{padding: '1rem'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Company name</LabelStyle>
                          <TextFieldStyle
                            disabled={mode === 'view' || !user?.is_owner }
                            fullWidth
                            variant='outlined'
                            name="name"
                            value={formState.values.company_id?.name || ''}
                            onChange={(e) => {
                              const formCompany = Object.assign({}, formState.values.company_id)
                              formCompany[e.target.name] = e.target.value
                              handleChange({
                                target : {
                                  name: 'company_id',
                                  value: formCompany 
                                }
                              })
                            }}
                            margin='dense'
                          />
                        </FormControlStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Main products</LabelStyle>
                          <Autocomplete 
                            sx={{marginTop: '7px'}}
                            disabled={mode === 'view' || !user?.is_owner }
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            options={productType}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {
                              const formCompany = Object.assign({}, formState.values.company_id)
                              formCompany['product_type_id'] = value
                              handleChange({
                                target : {
                                  name: 'company_id',
                                  value: formCompany
                                }
                              })}}
                            value={formState.values.company_id?.product_type_id || null}
                            renderInput={(params) => <TextFieldStyle {...params} />}
                          />
                        </FormControlStyle>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{paddingTop: '5px'}}>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Main market</LabelStyle>
                          <Autocomplete
                            sx={{marginTop: '7px'}}
                            disabled={mode === 'view' || !user?.is_owner }
                            fullWidth
                            variant='outlined'
                            margin='dense'
                            options={mainMarket}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {
                              const formCompany = Object.assign({}, formState.values.company_id)
                              formCompany['main_market_id'] = value
                              handleChange({
                                target : {
                                  name: 'company_id',
                                  value: formCompany
                                }
                              })}}
                            value={formState.values.company_id?.main_market_id || null}
                            renderInput={(params) => <TextFieldStyle {...params} />}
                          />
                        </FormControlStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>{`Estimated Spent/ month(USD)`}</LabelStyle>
                          <TextFieldStyle
                            disabled={mode === 'view' || !user?.is_owner }
                            fullWidth
                            variant='outlined'
                            name="spent_per_month"
                            value={formState.values.company_id?.spent_per_month || ''}
                            onChange={(e) => {
                              const formCompany = Object.assign({}, formState.values.company_id)
                              formCompany[e.target.name] = e.target.value
                              handleChange({
                                target : {
                                  name: 'company_id',
                                  value: formCompany 
                                }
                              })}}
                            margin='dense'
                            InputProps={{
                              classes: {
                                notchedOutline: 'notchedOutline'
                              },
                              inputComponent: CustomNumberFormat
                            }}
                          />
                        </FormControlStyle>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{paddingTop: '5px'}}>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Detail info/ Link</LabelStyle>
                          <TextFieldStyle
                            disabled={mode === 'view' || !user?.is_owner }
                            fullWidth
                            variant='outlined'
                            name="detail_link"
                            onChange={(e) => {
                              const formCompany = Object.assign({}, formState.values.company_id)
                              formCompany[e.target.name] = e.target.value
                              handleChange({
                                target : {
                                  name: 'company_id',
                                  value: formCompany 
                                }
                              })}}
                            value={formState.values.company_id?.detail_link || ''}
                            margin='dense'
                          />
                        </FormControlStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlStyle>
                          <LabelStyle>Discount code</LabelStyle>
                          <TextFieldStyle
                            disabled={mode === 'view' || !user?.is_owner}
                            fullWidth
                            variant='outlined'
                            name="discount_code"
                            value={formState.values.company_id?.discount_code || ''}
                            onChange={(e) => {
                              const formCompany = Object.assign({}, formState.values.company_id)
                              formCompany[e.target.name] = e.target.value
                              handleChange({
                                target : {
                                  name: 'company_id',
                                  value: formCompany 
                                }
                              })}}
                            margin='dense'
                          />
                        </FormControlStyle>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{paddingTop: '5px'}}>
                      <FormControlStyle>
                        <LabelStyle>How did you know us?</LabelStyle>
                        <Autocomplete
                          sx={{marginTop: '7px'}}
                          disabled={mode === 'view' || !user?.is_owner}
                          fullWidth
                          variant='outlined'
                          margin='dense'
                          options={sources}
                          getOptionLabel={(option) => option.title}
                          value={formState.values.company_id?.source_id || null}
                          onChange={(e, value) => {
                            const formCompany = Object.assign({}, formState.values.company_id)
                            formCompany['source_id'] = value
                            handleChange({
                              target : {
                                name: 'company_id',
                                value: formCompany 
                              }
                            })}}
                          renderInput={(params) => <TextFieldStyle {...params} />}
                        />
                      </FormControlStyle>
                    </Grid>
                  </Grid>
                </Grid>
              </CardStyle>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(withRouter(Profile))
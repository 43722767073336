import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Tooltip,
  IconButton,
  Typography,
  Button
} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { request } from '../../_services_';
import { connect } from 'react-redux';
import { styled } from '@mui/styles';
import NotificationDetail from 'src/pages/Customer/Notification/NotificationDetail';
import NotificationItem, { PropUnRead } from 'src/components/NotificationItem';
import { ModalForm } from '../../components/ModalForm'
import { notificationConstant } from '../../_constants_'

// ----------------------------------------------------------------------

const InfiniteScrollBox = styled('div')({
  maxHeight: '70vh',
  overflow: 'auto',
  id: 'boxScroll',
});

function NotificationsPopover(props) {
  const { dispatch } = props;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [total, setTotal] = useState(0)
  const [loadPage, setLoadPage] = useState(0)
  const limit = 10
  const navigate = useNavigate();
  const [readAll, setReadAll] = useState(false)
  const [pathName, setPathName] = useState('')

  if (window.location.pathname != pathName) {
    setPathName(window.location.pathname)
  }

  useEffect(() => {
    fetchNotifications()
  }, [pathName])
  

  const clickItem = (notification, read, setRead) => {
    if (read == false) {
      request.put('/api/v1/mark_as_read', {ids: [notification.id]}).then(res => {
        if (res.data.success) {
          setRead(true)
          dispatch({
            type: notificationConstant.COUNTER,
            count: props.notification?.count > 0 ? props.notification?.count - 1 : 0
          })
        }
      })
    }
    NotificationDetail(notification)
  }

  const handleOpen = () => {
    fetchNotifications()
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNotifications([])
    setLoadPage(0)
  };

  const viewAll = () => {
    handleClose()
    navigate('/notifications')
  }

  const handleMarkAllAsRead = () => {
    request.put('/api/v1/mark_as_read', {ids: 'all'}).then(res => {
      if (res.data.success) {
        dispatch({
          type: notificationConstant.COUNTER,
          count: 0
        })
        setReadAll(true)
      }
    })
  };

  const fetchNotifications = (query = {page: 1, limit: limit}) => {
    request.get('/api/v1/notification', query).then(res => {
      if (res.data.success) {
        let new_notifications = [...notifications].concat(res.data.data.items)
        setNotifications(new_notifications)
        setTotal(res.data.data.total)
        dispatch({
          type: notificationConstant.COUNTER,
          count: res.data.data.unread
        })
        setLoadPage(loadPage + 1)
      }
    })
  }
  
  const handleScroll = (e) => {
    if ((notifications.length < total) && (notifications.length > 0)) {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        fetchNotifications({page: loadPage + 1, limit: limit})
      }
    }
  }
  
  return (
    <>
      <IconButton
        disabled={window.location.pathname == '/notifications'}
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge max={99} badgeContent={window.location.pathname == '/notifications' ? 0 : props.notification?.count} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360, maxHeight: '90vh' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5, borderBottom: '1px solid #ECECEC' }}>
          <ModalForm />
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{fontSize: '1.2rem', fontWeight: 'bold'}}>Notifications</Typography>
            {/* <Typography sx={{fontSize: '0.9rem', color: 'dimgrey'}}>
              {`You have ${props.notification?.count} messages unread.`}
            </Typography> */}
          </Box>

          {props.notification?.count > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <InfiniteScrollBox onScroll={handleScroll}>
          <List
            disablePadding
            id='list'
          >
            {notifications.map((notification, index) => (
              <NotificationItem key={index} notification={notification} clickItem={clickItem} props={props} readAll={readAll} />
            ))}
          </List>
        </InfiniteScrollBox>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button 
            sx={{ flexGrow: 1 }}
            onClick={() => viewAll()}
          >
            View all
          </Button>
        </Box>

      </MenuPopover>
    </>
  );
}

function mapStateToProps(state) {
  const { authentication, notification } = state;
  const { user } = authentication;
  return {
      user, notification
  };
}

export default connect(mapStateToProps)(NotificationsPopover)
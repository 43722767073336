import { request } from '../../../_services_'
import { Label } from '../../../components'

const getAccountInfo = (item) => {
  if (item.account_type == 'bank_vnd') {
    return (
      <div className='d-flex flex-column'>
        <span><b>Bank Name: </b> {item.bank_id?.name}, <b>Bank Number: </b> {item.bank_number}</span>
        <span><b>Bank Branch: </b> {item.bank_branch}, <b>Account Holder: </b> {item.holder_name}</span>
      </div>
      
    )
  } else if (item.account_type == 'po') {
    return (
      <ul style={{ marginBottom: 0 }}>
        <li><b>Payoneer Account: </b> {item.payoneer_account}</li>
      </ul>
    )
  } else if (item.account_type == 'paypal') {
    return (
      <ul style={{ marginBottom: 0 }}>
        <li><b>Paypal Account: </b> {item.paypal_account}</li>
      </ul>
    )
  }
} 

const types = [
  {id: 'po', name: 'PO'},
  {id: 'paypal', name: 'Paypal'},
  {id: 'bank_vnd', name: 'Bank VND'},
]
export const fields = [
  {
    name: 'team_username',
    label: 'Team_Seller Username',
    renderValue: (item) => item.owner_id?.name,
    width: '10rem'
  },
  {
    name: 'account_type',
    label: 'Account Type',
    width: '8rem',
    renderValue: (item) => types.find(e => e.id == item.account_type)?.name
  },
  {
    name: 'account_info',
    label: 'Account Info',
    width: '20rem',
    renderValue: (item) => getAccountInfo(item)
  },
  {
    name: 'default',
    label: '',
    width: '3rem',
    align: 'center',
    renderValue: (item) => item.default ? <Label variant="ghost" color={'success'}>{'Default'}</Label> : ''
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'username',
    label: 'Team_Seller Username',
    disabled: true,
    renderValue: (item) => item.owner_id?.name
  },
  {
    type: 'select',
    name: 'account_type',
    label: 'Account Type',
    renderValue: (item) => types.find(e => e.id == item.account_type),
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = types.filter(e => e.name.toLowerCase().includes((inputValue || '').toLowerCase()))
        resolve(result)
      })
    },
    labelKey: 'name',
    valueKey: 'id',
    onChange: (event, ctx) => {
      const e = {
        target: {
          name: 'account_type',
          value: event.target.value.id
        }
      }
      ctx.handleChange(e)
    }
  },
  {
    type: 'text',
    name: 'payoneer_account',
    label: 'Payoneer account',
    visible: (item) => item.account_type == 'po'
  },
  {
    type: 'text',
    name: 'paypal_account',
    label: 'paypal_account account',
    visible: (item) => item.account_type == 'paypal'
  },
  {
    name: 'bank_id',
    label: 'Bank Vietnam',
    type: 'select_create',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/banks', { search: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    visible: (item) => item.account_type == 'bank_vnd',
    valueKey: 'id',
    labelKey: 'name',
    onCreateOption: (inputValue, ctx) => {
      request.post('/api/v1/banks', { name: inputValue }).then(res => {
        if (res.data.success) {
          var e = {
            target: {
              name: "bank_id",
              value: res.data.data
            },
          };
          ctx.handleChange(e)
        } else {
          toastr.error(res.data.msg)
        }
      }, err => toastr.error(err))
    }
  },
  {
    type: 'text',
    name: 'bank_number',
    label: 'Bank Number',
    visible: (item) => item.account_type == 'bank_vnd'
  },
  {
    type: 'text',
    name: 'bank_branch',
    label: 'Bank Branch',
    visible: (item) => item.account_type == 'bank_vnd'
  },
  {
    type: 'text',
    name: 'holder_name',
    label: 'Account Holder',
    visible: (item) => item.account_type == 'bank_vnd'
  },
]
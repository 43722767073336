import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { timezones } from '../../../utils';

const fields = [
  {
    type: 'text',
    name: 'sin_id',
    label: 'SIN Number',
    renderValue: (item) => `SIN ${item.sin_id}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'username',
    label: 'Team_Seller Username',
    renderValue: (item) => item.team_id ? `Team_${item.team_id.team_id} ${item.team_id.owner_id?.shortname}` : '',
    disabled: true
  },
  {
    type: 'number',
    name: 'pause',
    label: 'Pause',
  },
  {
    type: 'number',
    name: 'spent_per_month',
    label: 'Monthly spending (USD)',
  },
  {
    type: 'number',
    name: 'total_acc_request',
    label: 'Number of accounts'
  },
  {
    type: 'select',
    name: 'request_acc_timezone',
    label: 'Account Timezone',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        const result = timezones.filter(e => e.name.toLowerCase().includes(inputValue.toLowerCase()))
        resolve(result)
      })
    },
    valueKey: 'id',
    labelKey: 'name',
    renderValue: (item) => timezones.find(e => e.name === item.request_acc_timezone)
  },
  {
    type: 'select',
    name: 'product_type_id',
    label: 'Product Type',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/product_types', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({id: e.product_type_id, name: e.name})))
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'id',
    labelKey: 'name',
  },
  {
    type: 'text',
    name: 'domain1',
    label: 'Domain 1'
  },
  {
    type: 'select',
    name: 'platform1',
    label: 'Platform of domain 1',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/platforms', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({id: e.platform_id, name: e.name})))
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'id',
    labelKey: 'name',
  },
  {
    type: 'text',
    name: 'domain2',
    label: 'Domain 2'
  },
  {
    type: 'select',
    name: 'platform2',
    label: 'Platform of domain 2',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/platforms', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({id: e.platform_id, name: e.name})))
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    valueKey: 'id',
    labelKey: 'name',
  },
  {
    type: 'custom',
    name: 'exists_at_another_agency',
    label: 'Have you ever created ad account with this domain and fanpage at other Agency?',
    renderValue: (item, handleChange, disabled) => (
      <FormControl>
        <RadioGroup
          row
          name="exists_at_another_agency"
          type="radio"
          value={item.exists_at_another_agency ? 1 : 0}
          onChange={handleChange}
        >
          <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={disabled} />
          <FormControlLabel value={0} control={<Radio />} label="No" disabled={disabled} />
        </RadioGroup>
      </FormControl>
    )
  },
  {
    type: 'custom',
    name: 'change_dns',
    label: 'Nếu khách hàng chạy Vpcs thì có thể tạm thời chỉnh sửa DNS của website không?',
    renderValue: (item, handleChange, disabled) => (
      <FormControl>
        <RadioGroup
          row
          name="change_dns"
          type="radio"
          value={item.change_dns ? 1 : 0}
          onChange={handleChange}
        >
          <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={disabled} />
          <FormControlLabel value={0} control={<Radio />} label="No" disabled={disabled} />
        </RadioGroup>
      </FormControl>
    )
  },
  {
    type: 'text',
    name: 'page1_link',
    label: 'Page 1 - Link'
  },
  {
    type: 'text',
    name: 'page1_name',
    label: 'Fanpage 1 - Tên'
  },
  {
    type: 'text',
    name: 'page2_link',
    label: 'Page 2 - Link'
  },
  {
    type: 'text',
    name: 'page2_name',
    label: 'Fanpage 2 - Tên'
  },
  {
    type: 'text',
    name: 'bm_id',
    label: 'ID của BM'
  },
  {
    type: 'text',
    name: 'bm_name',
    label: 'BM Name'
  },
  {
    type: 'text',
    name: 'bm_admin_link',
    label: 'BM admin role invite link'
  },
  {
    type: 'text',
    name: 'other_note',
    label: 'Note'
  },
]

const WebpageForm = (props) => {
  const [mode, setMode] = useState('view')

  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (key == 'request_acc_timezone') {
        query[key] = formState.changes[key].name
      } else if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/signup_web/${formState.values.sin_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx, formState) => {
    setMode('view')
    ctx.handleCancel()
  }

  return (
    <ResourceForm 
      mode={mode}
      route="webpage"
      fields={fields}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/signup_web/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Webpage Detail"
      actionBack={() => props.navigate('/webpage_info')}
      actions={[
        {
          text: 'Edit',
          variant: 'contained',
          color: 'primary',
          action: () => setMode('edit'),
          visible: mode == 'view'
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx, formState),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(WebpageForm))
import slugify from "react-slugify";

export const slugifyStr = (text, split=" ") => {
  if (text == "") {
    return ""
  }
  const splitStr = text.split(split);
  const lastStr = splitStr.pop();
  const lastElement = slugify(lastStr);
  const nameArr2 = splitStr.filter(e => e != '');
  const name = nameArr2.map(e => e[0]).join('')
  const lastname = lastElement.charAt(0).toUpperCase() + lastElement.slice(1)
  return `${lastname}${name}`
}
export const payoneer = {
    sandbox: {
        BASE_URL: 'https://api.sandbox.payoneer.com',
        AUTHORIZATION_URL: 'https://login.sandbox.payoneer.com',
        PROGRAM_ID: '100192220',
        CLIENT_ID: 'EcGpgIYABwbzVOCgPdG03BCwhc9cIonc',
        CLIENT_SECRET: 'JlNAbCZwtAoZvOQe',
        REDIRECT_URL: 'https://www.example.com'
    },
    production: {
        BASE_URL: 'https://api.payoneer.com',
        AUTHORIZATION_URL: 'https://login.payoneer.com',
        PROGRAM_ID: '100192220',
        CLIENT_ID: 'EcGpgIYABwbzVOCgPdG03BCwhc9cIonc',
        CLIENT_SECRET: 'JlNAbCZwtAoZvOQe',
        REDIRECT_URL: 'https://app.spendads.com'
    }
}
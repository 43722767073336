import moment from "moment"
import ExtraField from '../RequestSupport/ExtraField';
import { Box, Radio, RadioGroup, FormControlLabel } from '@mui/material';

const moneyTopup = (extra_fields) => {
  let money = 0
  extra_fields?.map((field) => {
    if (field.key == "accounts") {
      field.value?.map((value) => {
        money += Number.parseFloat(value.money)
      })
    }
  })
  return money
}

export const filters = [
  {
    key: 'create_date',
    type: 'date_picker',
    placeholder: 'Timestamp'
  }
]

export const fields = [
  {
    name: 'create_date',
    label: 'Time',
    sort: true,
    renderValue: (item) => moment(item.create_date).format('DD/MM/YYYY HH:mm:ss'),
    width: '10rem'
  },
  {
    name: 'name',
    label: 'Service ID',
    sort: true,
    width: '6rem'
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_search,
    sort: true,
    width: '6rem'
  },
  {
    name: 'product_id',
    label: 'Service Name',
    sort: true,
    renderValue: (item) => item.product_id?.title,
    width: '15rem'
  },
  {
    name: 'fee',
    label: 'Fee',
    sort: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '6rem',
    align: 'right'
  },
  {
    name: 'fud_id',
    label: 'FUD ID',
    renderValue: (item) => item.fud_id && `FUD ${item.fud_id.fud_id}`,
    width: '6rem'
  },
  // {
  //   name: 'payment_status',
  //   label: 'Payment Status',
  //   renderValue: (item) => item?.payment_status == 'success' ? <CheckBoxIcon sx={{color: 'limegreen'}} /> : <DisabledByDefaultRoundedIcon color="error" />,
  //   width: '10rem'
  // },
  {
    name: 'money_topup',
    label: 'Money topup to Acc',
    renderValue: (item) => ['A','A1'].includes(item?.product_id.code) && item?.extra_fields && moneyTopup(item.extra_fields) != 0 ?
      moneyTopup(item.extra_fields).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }) : 
      '',
    width: '10rem',
    align: 'right'
  },
  {
    name: 'wallet_remaining_after',
    label: 'Wallet Remaining',
    renderValue: (item) => item?.wallet_remaining_after?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    width: '10rem',
    align: 'right'
  },
  // {
  //   name: 'fund_remaining_after',
  //   label: 'Fund Remaning',
  //   renderValue: (item) => item?.fund_remaining_after?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
  //   width: '10rem'
  // },
]

export const formField = [
  {
    type: 'text',
    name: 'create_date',
    label: 'Timestamp',
    disabled: true,
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD/MM/YYYY HH:mm:ss') : ''
  },
  {
    type: 'text',
    name: 'name',
    label: 'Service ID',
    disabled: true,
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => item.team_id?.team_search
  },
  {
    type: 'text',
    name: 'product_id',
    label: 'Service Name',
    disabled: true,
    renderValue: (item) => item.product_id?.title
  },
  {
    type: 'text',
    name: 'fee',
    label: 'Fee',
    disabled: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    visible: (item) => item.product_id?.code != 'A' ? true : false,
  },
  {
    type: 'text',
    name: 'fee',
    label: 'Money topup to wallet',
    disabled: true,
    renderValue: (item) => item.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
    visible: (item) => item.product_id?.code == 'A' ? true : false,
  },
  {
    type: 'custom',
    name: 'option',
    label: 'Option',
    disabled: true,
    visible: (item) => item.product_id?.code == 'B' ? true : false,
    renderValue: (item) => 
      <div style={{ marginTop: 10 }}>
        <Box fontSize={15} fontWeight="bold">Option</Box>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="option"
          value={item.option}
          row
        >
          <FormControlLabel disabled value="upgrade_fud" control={<Radio />} label="Upgrade FUD" />
          <FormControlLabel disabled value="new_fud" control={<Radio />} label="New FUD" />
        </RadioGroup>
      </div>
  },
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    disabled: true,
    visible: (item) => item.product_id?.code == 'A' || item.product_id?.code == 'B' && item.option == 'upgrade_fud' ? true : false,
    renderValue: (item) => item.fud_id ? `FUD ${item.fud_id?.fud_id}` : ''
  },
  {
    type: 'text',
    name: 'level',
    label: 'Level',
    disabled: true,
    visible: (item) => item.product_id?.code == 'B' ? true : false,
    renderValue: (item) => item.package_id?.name
  },
  {
    type: 'number',
    name: 'num_account',
    label: 'Number of accounts',
    disabled: true,
    visible: (item) => ['C', 'D'].includes(item.product_id?.code) ? true : false,
  },
  {
    type: 'custom',
    name: 'extra_fields',
    visible: (item) => item.extra_fields?.length > 0 ? true : false,
    // label: 'Extra fields',
    renderValue: (item, ctx) => <ExtraField submitData={item} handleChange={ctx.handleChange} disabled={ctx.props.mode == 'view'} />
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note',
    disabled: true,
  },
]
// material
import { styled } from '@mui/material/styles';
import {
  Box, Container, Typography, InputAdornment, IconButton, Stack, TextField, Grid, FormControlLabel, Link, Checkbox
} from '@mui/material';
// layouts
// components
import { Page, CustomNumberFormat } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { LoadingButton } from '@mui/lab';
import AsyncSelect from 'react-select/async';
import { mainMarket } from './constants';
import { request } from '../../../_services_'
import { toastr } from '../../../_helpers_';
import { loadOptions, handleInputChange, customStyles, errorStyles, timezones } from '../../../utils';
import { useNavigate } from 'react-router-dom'
import AsyncCreatableSelect from 'react-select/async-creatable';

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2)
// }));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  display: 'flex',
  // minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: 10
  // padding: theme.spacing(12, 0),
  // overflow:'auto'
}));
// ----------------------------------------------------------------------

export default function RegisterTeam() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Fullname is required'),
    shortname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').matches(
      /^[a-zA-Z0-9 ]+$/, "Shortname contain only latin"
    ).required('Shortname is required'),
    facebook_link: Yup.string().matches(
      /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/, 'Enter correct facebook link!'
    ).required('Facebook link is required'),
    team_shortname: Yup.string().min(2, 'Team Shortname min 2 characters').max(4, 'Team Shortname max 4 characters!')
      .matches(
        /^[a-zA-Z0-9 ]+$/, "Shortname contain only latin"
      ),
    product_type_id: Yup.object().nullable().required('Product type is required'),
    time_zone: Yup.object().nullable().required('Timezone is required'),
    main_market: Yup.string().required('Main market is required'),
    main_web: Yup.string().required('Main web is required').matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm, "Main web is not a valid website"),
    phone: Yup.string().matches(/^\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid').min(8, 'Phone number is not valid').max(15, 'Phone number is not valid')
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullname: "",
      shortname: "",
      facebook_link: "",
      team_name: "",
      team_shortname: "",
      main_web: "",
      product_type_id: null,
      main_market: "",
      time_zone: null,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      var data = {}
      var keys = Object.keys(values)
      keys.map(key => {
        if (typeof values[key] === 'object') {
          if (key === 'product_type_id') {
            data[key] = values[key].product_type_id
          } else if (key === 'source_id') {
            data[key] = values[key].source_id
          } else if (key === 'support_channel_id') {
            data[key] = values[key].support_channel_id
          } else if (key === 'support_channel_id') {
            data[key] = values[key].support_channel_id
          } else if (['time_zone'].includes(key)) {
            data[key] = values[key].id
          }
        }
        else if (key == "spent_per_month") {
          data[key] = parseFloat(values[key] || 0)
        }
        else if (key !== "agree_term") {
          data[key] = values[key]
        }
      })
      request.post('/api/v1/register_team', data).then(res => {
        formik.setSubmitting(false)
        if (res.data.success) {
          toastr.success("create team success")
          navigate('/team_summary', { replace: true })
        } else {
          toastr.success(res.data.msg)
        }
      }, error => {
        formik.setSubmitting(false)
        toastr.error(error?.response?.data?.msg || error.toString())
      })
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;
  return (
    <RootStyle title="Drebest | Register Team">
      <Container maxWidth="md">

        <ContentStyle>
          <Grid>
            <div style={{ padding: "10px 5px" }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div style={{
                    position: '-webkit-sticky',
                    position: 'sticky',
                    top: -24,
                    paddingTop: 24,
                    background: '#fff',
                    zIndex: 10
                  }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                      <Typography variant="h4" gutterBottom>
                        Register new team
                      </Typography>
                      <LoadingButton
                        variant="contained"
                        startIcon={<AddIcon />}
                        loading={isSubmitting}
                        // disabled={!values.agree_term}
                        type="submit"
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div>
                        <Box className='mb-1' fontWeight={"bold"}>Email teamlead <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          autoComplete="new-email"
                          type="email"
                          placeholder="Email teamlead"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box fontWeight={"bold"}>Password <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          autoComplete="new-password"
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Password"
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Fullname <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Fullname"
                          {...getFieldProps('fullname')}
                          error={Boolean(touched.fullname && errors.fullname)}
                          helperText={touched.fullname && errors.fullname}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Shortname <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Shortname"
                          {...getFieldProps('shortname')}
                          error={Boolean(touched.shortname && errors.shortname)}
                          helperText={touched.shortname && errors.shortname}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - FB profile name</Box>
                        <TextField
                          fullWidth
                          placeholder="FB profile name"
                          {...getFieldProps('facebook_profile_name')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - FB link <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="FB link"
                          {...getFieldProps('facebook_link')}
                          error={Boolean(touched.facebook_link && errors.facebook_link)}
                          helperText={touched.facebook_link && errors.facebook_link}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>User - Phone number</Box>
                        <TextField
                          fullWidth
                          placeholder="Phone"
                          {...getFieldProps('phone')}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Team fullname</Box>
                        <TextField
                          fullWidth
                          placeholder="Team fullname"
                          {...getFieldProps('team_name')}
                          error={Boolean(touched.team_name && errors.team_name)}
                          helperText={touched.team_name && errors.team_name}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Team shortname </Box>
                        <TextField
                          fullWidth
                          placeholder="Team shortname"
                          {...getFieldProps('team_shortname')}
                          error={Boolean(touched.team_shortname && errors.team_shortname)}
                          helperText={touched.team_shortname && errors.team_shortname}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main Product Type <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncCreatableSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/product_types', { name: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                }
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="product_type_id"
                          onChange={(value) => setFieldValue('product_type_id', value)}
                          placeholder={"Main product type"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ product_type_id }) => product_type_id}
                          valueKey={"product_type_id"}
                          value={values.product_type_id}
                          styles={Boolean(touched.product_type_id && errors.product_type_id) ? errorStyles : customStyles}
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: 'Create \"' + inputValue + '\"...',
                            __isNew__: true
                          })}
                          onCreateOption={(inputValue) => {
                            request.post('/api/v1/product_types', { name: inputValue }).then(res => {
                              if (res.data.success) {
                                setFieldValue('product_type_id', res.data.data)
                              } else {
                                toastr.error(res.data.msg)
                              }
                            }, err => toastr.error(err))
                          }}
                        />
                        {errors.product_type_id && <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>{errors.product_type_id}</span>}
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main web <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Main web"
                          {...getFieldProps('main_web')}
                          error={Boolean(touched.main_web && errors.main_web)}
                          helperText={touched.main_web && errors.main_web}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main fanpage</Box>
                        <TextField
                          fullWidth
                          placeholder="Main Fanpage"
                          {...getFieldProps('main_fanpage')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main market <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncCreatableSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/main_market', { name: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                }
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="main_market"
                          onChange={(value) => setFieldValue('main_market', value.name)}
                          placeholder={"Main market"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ name }) => name}
                          valueKey={"value"}
                          value={values.main_market ? { name: values.main_market } : null}
                          styles={Boolean(touched.main_market && errors.main_market) ? errorStyles : customStyles}
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: 'Create \"' + inputValue + '\"...',
                            __isNew__: true
                          })}
                          onCreateOption={(inputValue) => {
                            request.post('/api/v1/main_market', { name: inputValue }).then(res => {
                              if (res.data.success) {
                                setFieldValue('main_market', inputValue)
                              } else {
                                toastr.error(res.data.msg)
                              }
                            }, err => toastr.error(err))
                          }}
                        />
                        {errors.main_market && <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>{errors.main_market}</span>}
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Timezone Acc <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncCreatableSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/timezones', { name: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items.map(e => ({ id: e.code, name: e.name })))
                                }
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="time_zone"
                          menuPlacement='top'
                          onChange={(value) => setFieldValue('time_zone', value)}
                          placeholder={"Timezone"}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                          valueKey={"value"}
                          value={values.time_zone}
                          styles={Boolean(touched.time_zone && errors.time_zone) ? errorStyles : customStyles}
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: 'Create \"' + inputValue + '\"...',
                            __isNew__: true
                          })}
                          onCreateOption={(inputValue) => {
                            request.post('/api/v1/timezones', { name: inputValue }).then(res => {
                              if (res.data.success) {
                                setFieldValue('time_zone', { id: inputValue, name: inputValue })
                              } else {
                                toastr.error(res.data.msg)
                              }
                            }, err => toastr.error(err))
                          }}
                        />
                        {errors.time_zone && <span style={{ color: 'red', fontSize: 11, marginLeft: 10 }}>{errors.time_zone}</span>}

                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Number of Ads Acc</Box>
                        <TextField
                          fullWidth
                          type="number"
                          placeholder="Number of Ads Acc"
                          {...getFieldProps('number_ads')}
                        />
                      </div>
                      <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Spent/month (USD) <span style={{ color: 'red' }}>*</span></Box>
                        <TextField
                          fullWidth
                          placeholder="Spent/month (USD)"
                          {...getFieldProps('spent_per_month')}
                          InputProps={{
                            inputComponent: CustomNumberFormat
                          }}
                        />
                      </div>
                      {/* <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>How did you know us? <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/sources', { search: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                } else {
                                  resolve([])
                                }
                              }, error => {
                                resolve([])
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="source_id"
                          onChange={(value) => setFieldValue('source_id', value)}
                          placeholder={"How did you know us?"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ title }) => title}
                          getOptionValue={({ source_id }) => source_id}
                          valueKey={"source_id"}
                          value={values.source_id}
                          styles={Boolean(touched.source_id && errors.source_id) ? errorStyles : customStyles}
                        />
                      </div> */}
                      {/* <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Detail info / link</Box>
                        <TextField
                          fullWidth
                          placeholder="Detail info"
                          {...getFieldProps('source_link')}
                        />
                      </div> */}
                      {/* <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Main communication/support channel? <span style={{ color: 'red' }}>*</span></Box>
                        <AsyncSelect
                          className="MuiFormControl-marginDense"
                          cacheOptions
                          loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                            return new Promise(resolve => {
                              request.get('/api/v1/channels', { name: inputValue }).then(res => {
                                if (res.data.success) {
                                  resolve(res.data.data.items)
                                } else {
                                  resolve([])
                                }
                              }, error => {
                                resolve([])
                              })
                            })
                          })}
                          defaultOptions
                          onInputChange={handleInputChange}
                          isSearchable
                          name="support_channel_id"
                          onChange={(value) => setFieldValue('support_channel_id', value)}
                          placeholder={"Main communication/support channel"}
                          menuPortalTarget={document.body}
                          getOptionLabel={({ title }) => title}
                          getOptionValue={({ support_channel_id }) => support_channel_id}
                          valueKey={"support_channel_id"}
                          value={values.support_channel_id}
                          styles={Boolean(touched.support_channel_id && errors.support_channel_id) ? errorStyles : customStyles}
                        />
                      </div> */}
                      {/* <div className='mt-2'>
                        <Box className='mb-1' fontWeight={"bold"}>Detail info / link</Box>
                        <TextField
                          fullWidth
                          disabled
                          margin='dense'
                          name="finish_setting"
                          value={"https://www.messenger.com/t/1398722322"}
                          variant="outlined"
                          InputProps={{
                            classes: {
                              notchedOutline: 'notchedOutline'
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={() => window.open('https://www.messenger.com/t/1398722322', '_blank')}>
                                  <LaunchIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div> */}
                      {/* <div className='mt-2'>
  
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              By register you agree to Drebest&nbsp;
                              <Link href="https://tiny.cc/DBrate" target={"_blank"} underline="always" sx={{ color: 'text.primary' }}>
                                Terms of Service and Privacy Policy.
                              </Link>
                            </Typography>
                      </div> */}
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </div>
          </Grid>

        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

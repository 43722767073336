import React from 'react'
import { Box, Typography } from '@mui/material';
import { ModalForm } from '../../../components';

const NotificationDetail = (notification) => {
    return (
        ModalForm.instance.current.openForm({
            title: 'Notification detail',
            data: {
            },
            customView: (submitData, handleChange, ctx) => {
                return (
                    <div className='mt-2'>
                        <Typography fontSize='1.2rem' fontWeight='bold' paddingBottom={1}>
                            {notification.title}
                        </Typography>
                        <Typography fontSize='1rem'>
                            <div dangerouslySetInnerHTML={{__html: notification.content}}/>
                        </Typography>
                    </div>
                )
            },
        })
    )
}

export default NotificationDetail
import React, { useState } from 'react';
import { withRouter, toastr } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import { formFields } from './fields'
import { ModalForm } from '../../../components';
import { Box } from '@mui/material';

const RequestSupportForm = (props) => {
  const [mode, setMode] = useState('view')

  const handleSave = (ctx, formState) => {
    const query = {}
    const allKeys = Object.keys(formState.changes)
    allKeys.map(key => {
      if (formState.changes[key] instanceof Object || typeof formState.changes[key] == 'object') {
        query[key] = formState.changes[key].id
      } else {
        query[key] = formState.changes[key]
      }
    })
    request.put(`/api/v1/request/${formState.values.request_id}`, query).then(res => {
      if (res.data.success) {
        const form = Object.assign({}, formState)
        form.changes = {}
        form.values = res.data.data
        ctx.setState({ formState: form })
        toastr.success("Update successful")
        setMode('view')
      } else {
        toastr.error(res.data.msg)
      }
    }, err => {
      toastr.error("There was an error updating data")
    })
  }

  const handleCancel = (ctx) => {
    setMode('view')
    ctx.handleCancel()
  }

  const handleCancelRequest = (ctx, formState) => {
    const requestId = formState.values.request_id
    ModalForm.instance.current.openForm({
      data: {},
      title: 'Confirm cancel request',
      customView: (submitData, handleChange) => (
        <div className='p-2'>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Are you sure to cancel this request?
            </Box>
          </div>
        </div>
      ),
      action: {
        schema: {
        },
        titleAction: 'YES',
        onAction: (submitData) => {
          const query = {status: 'cancel', active: false}
          return new Promise((resolve) => {
            request.put(`/api/v1/request/${requestId}`, query).then(res => {
              if (res.data.success) {
                toastr.success("Cancel request successful")
                setMode('view')
                const form = Object.assign({}, formState)
                form.values.status = 'cancel'
                form.values.active = false
                ctx.setState({ formState: form })
                resolve(true)
              } else {
                toastr.error(res.data.msg)
              }
            }, err => {
              toastr.error(err);
            })
          })
        }
      }
    })
  }

  return (
    <ResourceForm 
      mode={mode}
      fields={formFields}
      route={'request_support'}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/request/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Request Supports Detail"
      actionBack={() => props.navigate('/request_supports')}
      actions={[
        // {
        //   text: 'Edit',
        //   variant: 'contained',
        //   color: 'primary',
        //   action: () => setMode('edit'),
        //   visible: mode == 'view',
        //   applyStatus: ['new']
        // },
        {
          text: 'Cancel Request',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleCancelRequest(ctx, formState),
          visible: (item) => mode == 'view' && item.status == 'new',
        },
        {
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          action: (ctx, formState) => handleSave(ctx, formState),
          visible: mode == 'edit'
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          color: 'error',
          action: (ctx, formState) => handleCancel(ctx),
          visible: mode == 'edit'
        },
      ]}
      {...props}
    />
  )
}

export default connect(null)(withRouter(RequestSupportForm))
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../_helpers_';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';
import moment from 'moment';

const fields = [
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    disabled: true,
    renderValue: (item) => `FUD ${item.fud_id}`
  },
  {
    type: 'text',
    name: 'team',
    label: 'Team ID',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id}`
  },
  {
    type: 'text',
    name: 'team_name',
    label: 'Team ID & Name',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id} ${item.team?.name}`
  },
  {
    type: 'text',
    name: 'username',
    label: 'Team_Seller Username',
    disabled: true,
    renderValue: (item) => `Team_${item.team_id} ${item.creator?.name}`
  },
  {
    type: 'text',
    name: 'level',
    label: 'Level',
    disabled: true
  },
  {
    type: 'text',
    name: 'package_id',
    label: 'Package',
    disabled: true,
    renderValue: (item) => item.package_id?.name
  },
  {
    type: 'number',
    name: 'lack_of_fee',
    label: 'Lack of fee',
    disabled: true,
  },
  {
    type: 'number',
    name: 'package_money',
    label: 'Package Money',
    disabled: true,
  },
  {
    type: 'number',
    name: 'fee_of_pack',
    label: 'B- Actual fee sent',
    disabled: true,
  },
  {
    type: 'number',
    name: 'total_topup_to_now',
    label: 'A - Total topup by FUD',
    disabled: true,
  },
  {
    type: 'number',
    name: 'remaining_topup',
    label: 'The remaining topup',
    disabled: true,
  },
  {
    type: 'checkbox',
    name: 'can_use',
    label: 'Can use',
    disabled: true,
    checked: (item) => item.can_use === 1 ? true : false
  },
  {
    type: 'number',
    name: 'percent_fee_actual',
    label: '% fee actual',
    disabled: true,
  },
  {
    type: 'number',
    name: 'percent_fee_by_package',
    label: '% fee by package',
    disabled: true,
  },
  {
    type: 'text',
    name: 'rate_type_code',
    label: 'Rate type code',
    disabled: true,
  },
  {
    type: 'text',
    name: 'create_date',
    label: 'First Day of FUD',
    renderValue: (item) => moment(item.create_date).format('DD-MM-YYYY'),
  },
  {
    type: 'number',
    name: 'extend_special',
    label: 'Extend Day',
    renderValue: (item) => item?.extend_special || 0,
    disabled: true,
  },
  {
    type: 'text',
    name: 'cycle_end_date',
    label: 'Cycle end Day',
    renderValue: (item) => moment(item.cycle_end_date).format('DD-MM-YYYY'),
    disabled: true,
  },
  {
    type: 'number',
    name: 'countdown_day',
    label: 'Countdown day',
    renderValue: (item) => item.count_down_day,
    disabled: true,
  },
]

class FudForm extends Component {

  render () {
    return (
      <ResourceForm 
        mode="view"
        fields={fields}
        route="fud"
        onLoadData={(id) => {
          return new Promise((resolve) => {
            request.get(`/api/v1/fud/${id}`).then(res => {
              if (res.data.success) {
                resolve(res.data.data)
              } else {
                resolve(false)
              }
            }, err => {
              resolve(false)
            })
          });
        }}
        title="FUD Detail"
        actionBack={() => this.props.navigate('/fud')}
        actions={[
          {
            text: 'Edit',
            variant: 'contained',
            color: 'primary',
            action: () => {},
            visible: false
          }
        ]}
        {...this.props}
      />
    )
  }
}

export default withRouter(FudForm);
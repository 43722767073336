export const mainMarket = [
  {value: 'US', label: 'US'},
  {value: 'EU', label: 'EU'},
  {value: 'VN', label: 'VN'},
  {value: 'WW', label: 'WW'}
]

export const timeZoneAcc = [
  {value: 'GMT+7', label: 'GMT+7'},
  {value: 'GMT-7', label: 'GMT-7'},
]

export const infoChannel = [
  {value: 'fb_friend', label: 'FB friend advise'},
  {value: 'fanpage_post', label: 'Promotional post on Fanpage'},
  {value: 'group_post', label: 'Posts on group'},
  {value: 'other', label: 'Other'}
]

export const supportChannel = [
  {value: 'messenger', label: 'Messenger'}
]
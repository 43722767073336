import { Box, TextField } from '@mui/material';
import moment from 'moment';
import { request } from '../../../_services_';
import ExtraFields from './ExtraField';
import { renderExtraField } from '../../../utils';
import NumberFormat from 'react-number-format';

const getObjectByKey = (field, key) => {
  if (!field) {
    return ''
  }
  const obj = field.keys?.find(e => e.key == key)
  if (obj.value) {
    return obj.value
  }
  if (field.value && field.value.length > 0) {
    return field.value[0][key]
  }
  return null
}

export const fields = [
  {
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item.create_date ? moment(item.create_date).format('DD/MM/YYYY HH:mm:ss') : '',
    sort: true,
    width: '12rem'
  },
  {
    name: 'service_id',
    label: 'Service ID',
    renderValue: (item) => `Service ${item.service_id}`,
    width: '8rem',
    sort: true,
  },
  {
    name: 'request_id',
    label: 'Request ID',
    renderValue: (item) => `Request ${item.request_id}`,
    sort: true,
    width: '9rem'
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => `Team ${item.team_id?.team_id || ''}`,
    sort: true,
    width: '6rem'
  },
  // {
  //   name: 'team_username',
  //   label: 'Team_Seller Username',
  //   renderValue: (item) => item.create_uid ? `Team_${item?.create_uid.team_id?.team_id || ''} ${item?.create_uid?.shortname || ''}` : '',
  //   sort: true,
  //   sortName: "teams.users.shortname",
  //   width: '15rem'
  // },
  {
    name: 'request_type_id',
    label: 'Request type',
    renderValue: (item) => <span className="line-clamp-1">{item?.request_type_id?.name || ""}</span>,
    sort: true,
    sortName: "request_types.name",
    width: '12rem'
  },
  {
    name: 'money_topup_to_acc',
    label: 'Money Topup to Acc',
    width: '8rem',
    align: 'right',
    renderValue: (item) => item.request_type_id.code == 'A1' && item.extra_fields?.length > 0 ? <NumberFormat value={getObjectByKey(item.extra_fields[0], 'money')} displayType="text" thousandSeparator={','} prefix="$" /> : ''
  },
  {
    name: 'status',
    label: 'Status',
    sort: true,
    width: '8rem'
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'create_date',
    label: 'Timestamp',
    renderValue: (item) => item?.create_date ? moment(item?.create_date).format('DD/MM/YYYY HH:mm:ss') : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'request_id',
    label: 'Request ID',
    renderValue: (item) => item.name || `Request ${item.request_id}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => `Team ${item.team_id?.team_id || ''}`,
    disabled: true
  },
  {
    type: 'text',
    name: 'team_username',
    label: 'Team_Seller Username',
    renderValue: (item) => item.create_uid ? `Team_${item?.create_uid.team_id?.team_id || ''} ${item?.create_uid?.shortname || ''}` : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'status',
    label: 'Status',
    disabled: true
  },
  {
    type: 'select',
    name: 'request_type_id',
    label: 'Request type',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/request_types', { name: inputValue }).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items.map(e => ({id: e.request_type_id, name: e.name})))
          } else {
            resolve([])
          }
        }, error => {
          resolve([])
        })
      })
    },
    getOptionValue: (item) => item.request_type_id,
    getOptionLabel: (item) => item.name,
  },
  {
    type: 'number',
    name: 'fee',
    label: 'Fee',
  },
  {
    type: 'custom',
    name: 'extra_fields',
    // label: 'Extra fields',
    renderValue: (item, ctx) => <ExtraFields submitData={item} handleChange={ctx.handleChange} disabled={ctx.props.mode == 'view'} />
  },
  {
    type: 'text',
    name: 'note',
    label: 'Note',
  }
]
import { localeConstants } from '../_constants_'

export function locale(state = { lang: 'en' }, action = {}) {
  switch (action.type) {
    case localeConstants.LOCALE_SET:
      return { lang: action.lang };
    default:
      return state;
  }
}

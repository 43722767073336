import React from 'react';
import { withRouter } from '../../../_helpers_';
import { connect } from 'react-redux';
import { ResourceForm } from '../../../components';
import { request } from '../../../_services_';

const fields = [
  {
    type: 'text',
    name: 'fud_id',
    label: 'FUD ID',
    renderValue: (item) => item.fud_id ? `FUD ${item.fud_id.fud_id}` : ''
  },
  {
    type: 'text',
    name: 'topup_id',
    label: 'Topup ID',
    renderValue: (item) => `Topup ${item.topup_id}`
  },
  {
    type: 'date',
    name: 'transaction_time',
    label: 'Transaction Time',
    renderValue: (item) => item.transaction_time ? new Date(item.transaction_time) : null
  },
  {
    type: 'select',
    name: 'payment_method_id',
    label: 'Payment Method',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/payment_methods', {name: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          }
        })
      })
    },
    valueKey: 'payment_method_id',
    labelKey: 'name',
  },
  {
    type: 'text',
    name: 'email_from',
    label: 'Send from email'
  },
  {
    type: 'custom',
    name: 'email_to',
    label: 'Send to',
    renderValue: (item) => 
      <div style={{ background: '#F8F9FA', padding: '16px 25px', border: '1px solid #C2C7CD', borderRadius: 8, maxHeight: 150, overflow: 'auto' }}>
        <div dangerouslySetInnerHTML={{ __html: item.email_to }} />
      </div>
  },
  {
    type: 'number',
    name: 'money_to_wallet',
    label: 'A - Money to Wallet',
  },
  {
    type: 'number',
    name: 'actual_fee',
    label: 'B - Actual fee',
  },
  {
    type: 'number',
    name: 'ad_acc_per_issuance_fee',
    label: 'C - Ad Acc creation/ issuance fee',
  },
  {
    type: 'number',
    name: 'acc_deposit',
    label: 'D - Acc Deposite',
  },
  {
    type: 'number',
    name: 'total_money',
    label: 'Total money USD',
  },
  {
    type: 'number',
    name: 'rate_value',
    label: 'Apply Rate',
  },
  {
    type: 'number',
    name: 'total_money_actual',
    label: 'Total Money',
  },
  {
    type: 'number',
    name: 'total_topup_to_now',
    label: 'A - Total Topup -> Now',
    renderValue: (item) => item.fud?.total_topup_to_now
  },
  {
    type: 'number',
    name: 'lack_of_fee',
    label: 'Lack of fee FUD (Số tiền phí còn thiếu)',
    renderValue: (item) => item.fud?.lack_of_fee
  },
  {
    type: 'number',
    name: 'remaining_topup',
    label: 'Remaining Topup (Số tiền được nạp miễn phí còn lại)',
  },
  {
    type: 'date',
    name: 'package_expiration_date',
    label: 'Package expiration date (Ngày hết hạn của gói)',
  },
  {
    type: 'checkbox',
    name: 'drebest_received',
    checked: (item) => item.drebest_received === 1 ? true : false,
    label: 'Drebest received'
  },
  {
    type: 'text',
    name: 'payone_transection_id',
    label: 'Payoneer transection ID'
  },
  {
    type: 'custom',
    name: 'screenshot_link',
    label: 'Screenshot link',
    renderValue: (item) => 
      item.screenshot_transaction_type == 'upload' ? 
      (item.extra && item.extra.length > 0 && <img style={{ height: 200, width: 'auto' }} src={`${process.env.REACT_APP_API_URL}/${item.extra[0].url}`} />) : 
      <img style={{ height: 200, width: 'auto' }} src={item.screenshot_transaction_link} />
  },
  {
    type: 'date',
    name: 'cycle_end_date',
    label: 'Cycle end date',
    renderValue: (item) => item.fud?.cycle_end_date
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email Address',
    renderValue: (item) => item.fud?.email
  },
]

const TopupWalletForm = (props) => {
  return (
    <ResourceForm 
      mode='view'
      route="topup"
      fields={fields}
      onLoadData={(id) => {
        return new Promise((resolve) => {
          request.get(`/api/v1/topup/${id}`).then(res => {
            if (res.data.success) {
              resolve(res.data.data)
            } else {
              resolve(false)
            }
          }, err => {
            resolve(false)
          })
        });
      }}
      title="Topup Detail"
      actionBack={() => props.navigate('/topup_wallet')}
      {...props}
    />
  )
}

export default connect(null)(withRouter(TopupWalletForm))
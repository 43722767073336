import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import { useSelector } from 'react-redux';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Reglement from './Reglement'
// import { LoadingBar } from 'react-redux-loading-bar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  height: '100vh',
  paddingTop: APP_BAR_MOBILE + 6,
  width: '100%',
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // height: `calc(100vh - ${APP_BAR_DESKTOP}px)`,
    overflow: 'auto',
    // paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout(props) {
  const [open, setOpen] = useState(false);
  const loading = useSelector(state => state.loading)
  // console.log(props,123)
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} {...props} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} {...props}/>
      <MainStyle>
        <Outlet {...props}/>
      </MainStyle>
      <div id="cws_page_loader" className={loading ? 'cws_loader_container' : ''}>
        <div className="loadingspinner"></div>
        <div className="lock">
          <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" strokeWidth="1" stroke="#ccc" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <rect x="5" y="11" width="14" height="10" rx="2"></rect>
            <circle cx="12" cy="16" r="1"></circle>
            <path d="M8 11v-4a4 4 0 0 1 8 0v4"></path>
          </svg>
        </div>
      </div>
      { props.user?.read_term == 0 &&
        <Reglement {...props}/>
      }
      
    </RootStyle>
  );
}

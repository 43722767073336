import React, { Component } from 'react'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { 
  TextField, Box,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange, errorStyles } from '../../../utils';
import { CustomNumberFormat, ModalForm, ResourceList } from '../../../components';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import moment from 'moment';
import NumberFormat from 'react-number-format';

const PAYMENT_METHODS = [
  {value: 'paypal', label: 'Paypal'},
  {value: 'po', label: 'PO'},
  {value: 'bank_vnd', label: 'Bank VND'},
]

const convert_payment_method = (code) => {
  if (!code) {
    return ''
  }

  let label = ''
  label = PAYMENT_METHODS.find(e => e.value == code)?.label

  if (!label) {
    let words = code.split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    label = words.join(' ');
  }
  
  return label
}

const fields = [
  {
    name: 'topup_id',
    label: 'Topup ID',
    renderValue: (item) => `Topup ${item.id}`,
    width: '6rem'
  },
  {
    name: 'payment_date',
    label: 'Transaction Time',
    renderValue: (item) => moment(item.payment_date).format('DD/MM/YYYY HH:mm:ss'),
    sort: true,
    width: '10rem'
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_search,
    sort: true,
    width: '8rem'
  },
  {
    name: 'payment_method',
    label: 'Payment Method',
    renderValue: (item) => convert_payment_method(item.payment_method),
    width: '8rem'
  },
  {
    name: 'sent_to',
    label: 'Send to',
    renderValue: (item) => item.sent_to,
    width: '10rem'
  },
  {
    name: 'rate',
    label: 'Apply Rate',
    renderValue: (item) => <NumberFormat displayType='text' thousandSeparator decimalSeparator=',' value={item.rate} />,
    width: '6rem',
    align: 'right'
  },
  {
    name: 'total_vnd',
    label: 'Total money VND',
    renderValue: (item) => ((item.rate || 1) * item.new_balance).toLocaleString('vi-VN', { style: 'currency', currency: 'VND', maximumFractionDigits: 2 }),
    width: '8rem',
    align: 'right'
  },
  // {
  //   name: 'new_balance',
  //   label: 'Amount',
  //   renderValue: (item) => item.new_balance?.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }),
  //   width: '8rem',
  //   align: 'right'
  // },
]

class TotalFundList extends Component {

  render () {
    const { user } = this.props;
    return (
      <ResourceList 
        route="/api/v1/wallet_histories"
        titlePage="Drebest | Total Fund"
        actions={[]}
        title="Total Fund"
        fields={fields}
        valueKey="id"
        defaultFilters={user ? {team_id: user.team_id?.team_id, kind: 'income'} : {}}
        // onClickRow={(item) => navigate(`/fud/${item.fud_id}`)}
      />
    )
  }

}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(TotalFundList)
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import { createLogger } from 'redux-logger'
import rootReducer from '../_reducers_'
const loggerMiddleware = createLogger()


export const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
    loadingBarMiddleware({
      promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
    })
  )
)